<template>
  <main
    class="flex-1 overflow-x-hidden"
    @wheel="handleWheel"
  >
    <section
      class="max-sm:hidden grow m-auto w-full h-[75vh]"
      :class="`bg-[linear-gradient(to_bottom,rgba(229,241,255,1.0),rgba(255,255,255,0.9)),url('/public/img/bg_login_reduce.jpg')]`"
    >
      <div
        id="marketing-banner"
        class="flex h-full justify-center items-center gap-16 bg-transparent pb-32"
      >
        <Button
          class="text-xl"
          @click="() => scrollToId('marketing-phrases')"
          variant="outline"
        >
          Conhecer o Converza
        </Button>
        <Button class="rounded-full text-lg">
          <router-link
            to="/login"
            class="text-xl"
          >Quero Assinar</router-link>
        </Button>
      </div>
    </section>

    <section
      id="marketing-phrases"
      class="h-[100vh] w-full bg-white flex flex-col justify-center items-center py-12 gap-6 max-sm:gap-12 max-md:p-[12.5vw] overflow-y-hidden overflow-x-hidden"
    >
      <div>
        <strong class="text-[4rem]">Converza é Conversão</strong>
      </div>
      <div>
        <p class="max-sm:hidden text-[1.5rem]">Você configura uma vez, seus clientes conectam, a gente converte.</p>
        <p class="sm:hidden text-[1.5rem]">Configure uma vez, a gente converte.</p>
      </div>
      <div class="p-6 flex sm:gap-16 max-sm:gap-2">
        <Button
          class="text-xl"
          @click="() => scrollToId('marketing-how-it-works')"
          variant="outline"
        >
          Como funciona?
        </Button>
        <Button class="rounded-full text-lg">
          <router-link
            to="/login"
            class="text-xl"
          >Quero Assinar</router-link>
        </Button>
      </div>
    </section>

    <section
      id="marketing-how-it-works"
      class="h-[100vh] w-full bg-blue-200 flex flex-col justify-center items-center py-12 gap-6 max-sm:gap-12 max-md:px-[12.5vw] overflow-y-hidden overflow-x-hidden"
    >
      <div class="flex-1 max-h-[50%] box-border">
        <strong class="max-sm:text-[3rem] sm:text-[4rem] box-border">Automatize seu atendimento</strong>
      </div>
      <div class="text-[1.5rem] max-h-[25%] box-border flex-1 line-clamp-3 flex flex-col">
        <p class="max-md:hidden flex-1 box-border">A conversão de leads começa com um bom suporte.</p>
        <p class="max-md:text-[1rem] flex-1 box-border">Minimize o trabalho difícil, deixe o Converza responder!</p>
        <p class="max-md:hidden flex-1 box-border">e só responda quem realmente <strong>converte.</strong></p>
      </div>
      <div class="flex-1 p-6 flex gap-16 max-h-[25%]">
        <Button class="rounded-full text-lg">
          <router-link
            to="/login"
            class="text-xl"
          >Quero Assinar</router-link>
        </Button>
      </div>
    </section>
  </main>
</template>

<script setup lang="ts">
import Button from '@/components/ui/button/Button.vue';
import { get, set } from '@vueuse/core';
import { ref } from 'vue';

const scrollToId = (id: string) => {
  document.getElementById(id)?.scrollIntoView({ behavior: 'smooth' });
};

const sections = [
  'big-main',
  'marketing-phrases',
  'marketing-how-it-works'
];

let currentIndexRef = ref(0);
let blockWheel = ref(false);

const handleWheel = (event: WheelEvent) => {
  event.preventDefault();

  if (get(blockWheel)) {
    return;
  }
  set(blockWheel, true);

  const isScrollBelow = event.deltaY > 0;

  let currentIndex = get(currentIndexRef);
  currentIndex = isScrollBelow ? Math.min(sections.length - 1, currentIndex + 1) : Math.max(0, currentIndex - 1);

  scrollToId(sections[currentIndex]);
  set(currentIndexRef, currentIndex);

  setTimeout(() => {
    set(blockWheel, false);
  }, 500);
};
</script>