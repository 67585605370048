<template>
    <Dialog v-model:open="openDialog">
        <DialogTrigger as-child>
            <Button
                variant="ghost"
                size="icon"
            >
                <Settings />
            </Button>
        </DialogTrigger>
        <DialogContent v-if="customer === undefined" />
        <DialogContent
            class="p-4 sm:max-w-[700px] flex flex-col"
            v-else
        >
            <DialogHeader class="flex flex-col flex-grow">
                <DialogTitle class="text-gray-700 max-sm:text-left">
                    Editar Cliente
                </DialogTitle>
                <DialogClose>
                    <Button
                        variant="ghost"
                        class="absolute right-2 top-2"
                    >
                        <CircleX class="text-gray-300" />
                    </Button>
                </DialogClose>
                <hr class="my-3">
            </DialogHeader>
            <div
                v-if="customer === undefined"
                class="animate-spin"
            >
            </div>
            <form
                v-else
                @submit.prevent="saveCustomer"
            >
                <Label
                    for="name"
                    class="pt-1 pl-1"
                >Nome</Label>
                <span
                    class="text-red-500 text-[10px]"
                    :class="{ 'invisible': !showNameError }"
                >
                    *
                </span>
                <div class="w-full">
                    <Input
                        :class="{ 'border-red-500 placeholder:text-red-500': showNameError }"
                        class='my-1 ml-0.5'
                        name="name"
                        v-model="customerAttributes[NAME_ATTRIBUTE_KEY]"
                        @update:model-value="() => {
                            showNameError = isEmpty(customerAttributes[NAME_ATTRIBUTE_KEY]);
                        }"
                        type="text"
                        placeholder="Nome"
                    />
                </div>
                <h2 class="mt-3 pl-1">Informações Adicionais</h2>
                <div class="overflow-y-auto h-60 pr-0.5 w-full">
                    <div
                        v-for="(attributeTuple, index) of customerAttributes.attributes"
                        :key="index"
                        class="ml-0.5 grid grid-cols-7 grid-rows-4 gap-2 items-center w-[99%] gap-x-3"
                        :class="{
                            'hidden': attributeTuple.deleted === true,
                        }"
                    >
                        <span
                            class="max-h-2 col-span-2 text-red-500 text-[10px]"
                            :class="attributeTuple.hasNameError ? 'visible' : 'invisible'"
                        >
                            O atributo deve ser válido
                        </span>
                        <span
                            class="max-h-2 col-span-4 text-red-500 text-[10px]"
                            :class="attributeTuple.hasValueError ? 'visible' : 'invisible'"
                        >
                            Você precisa inserir o valor do atributo
                        </span>
                        <Label :for="String(index)"></Label>
                        <Input
                            type="text"
                            :name="`KEY_${index}`"
                            placeholder="Propriedade"
                            v-model="customerAttributes.attributes[index].key"
                            v-on:update:model-value="() => validateNameOfIndex(index)"
                            class="col-span-2 row-span-3 self-end placeholder:text-gray-500"
                            :readonly="attributeTuple.isEditing !== true"
                            :class="{
                                'bg-gray-200 border-gray-200': attributeTuple.isEditing !== true,
                                'border-red-500 placeholder:text-red-500 ring-red-500': attributeTuple.hasNameError,
                            }"
                        >
                        </Input>
                        <Input
                            type="text"
                            :name="`VAL_${index}`"
                            placeholder="Valor"
                            v-model="customerAttributes.attributes[index].value"
                            v-on:update:model-value="() => validateValueOfIndex(index)"
                            class="col-span-4 max-sm:col-span-3 row-span-3 self-end placeholder:text-gray-500"
                            :readonly="attributeTuple.isEditing !== true"
                            :class="{
                                'bg-gray-200 border-gray-200': attributeTuple.isEditing !== true,
                                'border-red-500 placeholder:text-red-500 ring-red-500': attributeTuple.hasValueError,
                            }"
                        />
                        <div
                            class="col-span-1 max-sm:col-span-2 flex max-sm:mr-4 max-sm:justify-center sm:justify-end space-x-2 row-span-3">
                            <Dialog v-if="!attributeTuple.isEditing">
                                <DialogTrigger as-child>
                                    <Button
                                        type="button"
                                        variant="ghost"
                                        size="icon"
                                    >
                                        <Trash2 class="text-slate-400" />
                                    </Button>
                                </DialogTrigger>
                                <DialogContent class="sm:max-w-[425px]">
                                    <DialogHeader>
                                        <div class="flex flex-row justify-between items-center">
                                            <DialogTitle>Deletar Informações Adicionais</DialogTitle>
                                            <DialogClose>
                                                <Button variant="ghost">
                                                    <CircleX class="text-slate-400" />
                                                </Button>
                                            </DialogClose>
                                        </div>
                                        <DialogDescription>
                                            <div class="text-left">
                                                <p class="mt-2">
                                                    Você tem certeza de que deseja deletar este <br>Atributo:
                                                    <strong>{{ attributeTuple.originalKey }}</strong> com valor:
                                                    <strong>{{ attributeTuple.originalValue }}</strong>?
                                                </p>
                                                <p class="mt-2">
                                                    Essa é uma ação <strong>irreversível</strong>.
                                                </p>
                                            </div>
                                        </DialogDescription>
                                    </DialogHeader>
                                    <DialogFooter>
                                        <DialogClose>
                                            <Button
                                                type="button"
                                                variant="ghost"
                                            >
                                                Cancelar
                                            </Button>
                                        </DialogClose>
                                        <DialogClose>
                                            <Button
                                                type="button"
                                                variant="destructive"
                                                @click="async () => await deleteAttribute(attributeTuple.originalKey, index)"
                                            >
                                                Deletar
                                            </Button>
                                        </DialogClose>
                                    </DialogFooter>
                                </DialogContent>
                            </Dialog>
                            <Button
                                v-else
                                variant="ghost"
                                size="icon"
                                @click="() => {
                                    attributeTuple.isEditing = false;
                                    if (attributeTuple.originalKey === undefined) {
                                        attributeTuple.deleted = true;
                                        return;
                                    }

                                    attributeTuple.key = attributeTuple.originalKey;
                                    attributeTuple.value = attributeTuple.originalValue;
                                    attributeTuple.hasNameError = false;
                                    attributeTuple.hasValueError = false;
                                }"
                            >
                                <Redo2 class="text-slate-400" />
                            </Button>
                            <Button
                                type="button"
                                v-if="!attributeTuple.isEditing"
                                :class="{
                                    'bg-success': attributeTuple.showSuccessIndicator
                                }"
                                variant="default"
                                size="icon"
                                :disabled="attributeTuple.showProgressIndicator || attributeTuple.showSuccessIndicator"
                                @click="() => { attributeTuple.isEditing = true; }"
                            >
                                <LoaderCircle
                                    class="animate-spin text-white"
                                    v-if="attributeTuple.showProgressIndicator"
                                />
                                <Check
                                    v-else-if="attributeTuple.showSuccessIndicator"
                                    class="animate-pulse text-white"
                                />
                                <Pencil
                                    v-else
                                    class="text-white"
                                />
                            </Button>
                            <Button
                                type="button"
                                v-else
                                variant="default"
                                size="icon"
                                @click="async () => await saveAttribute(index)"
                                :disabled="attributeTuple.showProgressIndicator"
                            >
                                <CircleCheck class="text-white" />
                            </Button>
                        </div>
                    </div>
                    <div class="flex justify-end py-2">
                        <Button
                            type="button"
                            variant="outline"
                            class="col-span-3 max-sm:mr-7"
                            @click="() => {
                                customerAttributes.attributes.push({
                                    deleted: false,
                                    isEditing: true,
                                    hasNameError: false,
                                    hasValueError: false,
                                    showProgressIndicator: false,
                                    showSuccessIndicator: false,
                                });
                            }"
                        >
                            Adicionar Mais Informações
                        </Button>
                    </div>
                </div>

                <DialogFooter class="flex justify-end space-x-2 border-t-2 border-gray-200 max-sm:flex-row">
                    <DialogClose>
                        <Button
                            type="button"
                            variant="outline"
                            size="large"
                            @click="() => openDialog = false"
                            class="mt-2"
                        >
                            Cancelar
                        </Button>
                    </DialogClose>
                    <Button
                        type="submit"
                        variant="default"
                        size="large"
                        class="rounded-full text-white mt-2"
                        :class="{
                            'animate-in animate-out bg-success': showCustomerSuccessIndicator
                        }"
                    >
                        <LoaderCircle
                            class="animate-spin text-white"
                            v-if="showCustomerProgressIndicator"
                        />
                        <Check
                            v-else-if="showCustomerSuccessIndicator"
                            class="animate-pulse text-white"
                        />
                        <span v-else>Salvar</span>
                    </Button>
                </DialogFooter>
            </form>
        </DialogContent>
    </Dialog>
</template>

<script setup lang="ts">
import {
    Dialog,
    DialogContent,
    DialogDescription,
    DialogFooter,
    DialogHeader,
    DialogTitle,
    DialogTrigger,
    DialogClose
} from '@/components/ui/dialog';
import { Button } from '@/components/ui/button';
import { Input } from '@/components/ui/input';
import { Label } from '@/components/ui/label';
import { Customer } from '@/http/customerSession';
import { Settings, CircleCheck, CircleX, Pencil, Redo2, Trash2, LoaderCircle, Check } from 'lucide-vue-next';
import { defineEmits, ref, defineProps, watch, onMounted } from 'vue';
import { DELETE_CUSTOMER_ATTRIBUTES, PATCH_CUSTOMER_FIELD, STORAGE_CREDENTIALS } from '@/store';
import { useStore } from 'vuex';
import { getCostumerById } from '@/http/customer';
import { get, set } from '@vueuse/core';

const openDialog = ref(false);
const emit = defineEmits(['updated-customer-name']);
const { getters, dispatch } = useStore();
const props = defineProps({
    customerId: {
        type: String,
        required: true
    },
});

const NAME_ATTRIBUTE_KEY = 'name';
type AttributeTuple = {
    key?: string,
    originalKey?: string,
    value?: string,
    originalValue?: string,
    isEditing: boolean,
    hasValueError: boolean,
    hasNameError: boolean,
    showProgressIndicator: boolean,
    showSuccessIndicator: boolean,
    deleted: boolean
};
const parseAttributesToListOfTuplesWithoutName = (attributes: Record<string, string> | undefined): AttributeTuple[] => {
    return Object.entries(attributes ?? [])
        .map(([key, value]): AttributeTuple => ({
            key, value, originalKey: key, originalValue: value, isEditing: false, hasNameError: false, hasValueError: false, deleted: false, showProgressIndicator: false, showSuccessIndicator: false
        }))
        .filter((attr: AttributeTuple) => attr.key !== NAME_ATTRIBUTE_KEY);
};
const customer = ref<Customer | undefined>();
type CustomerAttributeForm = {
    [NAME_ATTRIBUTE_KEY]?: string,
    attributes: AttributeTuple[]
};
const customerAttributes = ref<CustomerAttributeForm>({
    [NAME_ATTRIBUTE_KEY]: customer.value?.partnerAttributes?.name,
    attributes: parseAttributesToListOfTuplesWithoutName(customer.value?.partnerAttributes),
});
const showNameError = ref(false);

const isEmpty = (str?: string) => { return (str ?? '').trim() === ''; };

const isKeyValid = (key?: string) => {
    if (isEmpty(key)) return false;
    if (key === NAME_ATTRIBUTE_KEY) return false;
    if (key !== undefined && !(/^[a-zA-Z0-9 _-]+$/).test(key)) {
        return false;
    }
    const allTuplesWithCreatedOnes = get(customerAttributes).attributes.filter(anAttribute => anAttribute.key === key);
    return allTuplesWithCreatedOnes.length === 1;
};

const validateNameOfIndex = (index: number) => {
    const attributeTuple = get(customerAttributes).attributes[index];
    attributeTuple.hasNameError = !isKeyValid(attributeTuple.key);
};
const validateValueOfIndex = (index: number) => {
    const attributeTuple = get(customerAttributes).attributes[index];
    attributeTuple.hasValueError = isEmpty(attributeTuple.value) && (attributeTuple.value?.length ?? 0) <= 2000;
};

const saveAttribute = async (index: number) => {
    const customerRef = get(customer);
    if (customerRef === undefined) return;

    const attributeTuple = get(customerAttributes).attributes[index];

    validateNameOfIndex(index);
    validateValueOfIndex(index);
    if (attributeTuple.hasNameError || attributeTuple.hasValueError) {
        return;
    }
    if (attributeTuple.key === undefined) return;

    if (attributeTuple.originalKey !== undefined && attributeTuple.originalKey !== attributeTuple.key) {
        await deleteAttribute(attributeTuple.originalKey);
    }

    attributeTuple.showProgressIndicator = true;
    try {
        await dispatch(PATCH_CUSTOMER_FIELD, {
            partnerId: customerRef.associatedPartnerId,
            customerKey: customerRef.key,
            partnerAttributes: {
                [attributeTuple.key]: attributeTuple.value
            }
        });
    } catch (err) {
        attributeTuple.hasNameError = true;
        attributeTuple.hasValueError = true;
        attributeTuple.showProgressIndicator = false;
        return;
    }
    setTimeout(() => {
        attributeTuple.showProgressIndicator = false;
        attributeTuple.showSuccessIndicator = true;
        setTimeout(() => {
            attributeTuple.showSuccessIndicator = false;
        }, 1000);
    }, 1000);

    attributeTuple.originalKey = attributeTuple.key;
    attributeTuple.originalValue = attributeTuple.value;
    attributeTuple.isEditing = false;
    attributeTuple.deleted = false;
};

const showCustomerProgressIndicator = ref(false);
const showCustomerSuccessIndicator = ref(false);
const saveCustomer = async () => {
    if (customer.value === undefined) return;

    set(showNameError, isEmpty(customerAttributes.value[NAME_ATTRIBUTE_KEY]));
    let allValid = !get(showNameError);
    if (allValid === false) return;
    customerAttributes.value.attributes.forEach((attribute, index) => {
        const hasNotEdited = attribute.originalKey === attribute.key
            && attribute.originalValue === attribute.originalValue;
        if (attribute.deleted === true || attribute.isEditing !== true || hasNotEdited) return;
        if (attribute.originalKey !== NAME_ATTRIBUTE_KEY)
            validateNameOfIndex(index);
        validateValueOfIndex(index);
        allValid = allValid && !(attribute.hasNameError || attribute.hasValueError);
    });
    if (!allValid) return;

    const customerRef = get(customer);
    if (customerRef === undefined) return;

    try {
        set(showCustomerProgressIndicator, true);
        await dispatch(PATCH_CUSTOMER_FIELD, {
            partnerId: customerRef.associatedPartnerId,
            customerKey: customerRef.key,
            partnerAttributes: {
                [NAME_ATTRIBUTE_KEY]: customerAttributes.value[NAME_ATTRIBUTE_KEY]
            }
        });
    } catch (err) {
        set(showNameError, true);
        return;
    }

    setTimeout(() => {
        set(showCustomerProgressIndicator, false);
        set(showCustomerSuccessIndicator, true);
        setTimeout(() => {
            set(showCustomerSuccessIndicator, false);
        }, 1000);
    }, 500);

    const attributes = get(customerAttributes).attributes;

    for (let index = 0; index < attributes.length; index++) {
        const attribute = attributes[index];
        const hasNotEdited = attribute.originalKey === attribute.key
            && attribute.value === attribute.originalValue;
        if (attribute.deleted === true || attribute.isEditing !== true || hasNotEdited) {
            continue;
        }
        await saveAttribute(index);
    }

    await queryAndSetCustomer(customer.value.id);

    emit('updated-customer-name', customerAttributes.value[NAME_ATTRIBUTE_KEY]);
};

const deleteAttribute = async (attributeKey?: string, index?: number) => {
    if (customer.value?.id === undefined) return;
    if (attributeKey === undefined) return;
    await dispatch(DELETE_CUSTOMER_ATTRIBUTES, {
        customerId: customer.value?.id,
        customerAttribute: attributeKey
    });
    if (index === undefined) return;
    customerAttributes.value.attributes[index].deleted = true;
};

const queryAndSetCustomer = async (customerId?: string) => {
    let credentials = getters[STORAGE_CREDENTIALS];
    if (credentials === undefined || customerId === undefined) {
        throw new Error("Credentials are not present");
    }
    set(customer, await getCostumerById(customerId, credentials));
    set(customerAttributes, {
        name: customer.value?.partnerAttributes?.[NAME_ATTRIBUTE_KEY],
        attributes: parseAttributesToListOfTuplesWithoutName(customer.value?.partnerAttributes)
    });
};

onMounted(async () => {
    await queryAndSetCustomer(props.customerId);
});

watch(
    () => props.customerId,
    async () => {
        await queryAndSetCustomer(props.customerId);
    }
);
</script>