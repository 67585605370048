<template>
    <div>
        <div class="flex justify-start py-2 px-4 items-center max-w-full flex-0">
            <h2 class="text-left font-semibold">{{ props.subtitle ?? '' }}</h2>
        </div>
        <slot />
    </div>
</template>

<script setup lang="ts">
import { defineProps } from 'vue';

const props = defineProps({
    subtitle: {
        type: String,
        required: false
    }
});
</script>