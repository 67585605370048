<!-- eslint-disable no-fallthrough -->
<script setup lang="ts">
import { onBeforeMount, onMounted, onUnmounted, ref, watch } from 'vue';
import { useStore } from 'vuex';
import ChatConversation from '@/components/converza-sessions/chat/ChatConversation.vue';
import ConverzaSessionsHeader from '@/components/converza-sessions/chat/ConverzaSessionsHeader.vue';
import KeyboardComponent from '@/components/converza-sessions/chat/KeyboardComponent.vue';
import { computed, defineProps, defineEmits } from 'vue';
import { GET_SESSIONS, RETRIEVE_SESSION, MARK_READ, GET_WHO_AM_I } from '@/store';
import ChatCustomerSidebar from '@/components/converza-sessions/chat/ChatCustomerSidebar.vue';
import { CustomerSession, isSessionExpired } from '@/http/customerSession';
import { useRouter } from 'vue-router';
import { ArrowDown, TriangleAlert } from 'lucide-vue-next';
import { set } from '@vueuse/core';
import { Button } from '@/components/ui/button';

function murmurhash3_32_gc(keyString: string, seed = 0): string {
    var key = btoa(keyString);
    var remainder, bytes, h1, h1b, c1, c2, k1, i;

    remainder = key.length & 3; // key.length % 4
    bytes = key.length - remainder;
    h1 = seed;
    c1 = 0xcc9e2d51;
    c2 = 0x1b873593;
    i = 0;

    while (i < bytes) {
        k1 =
            ((key.charCodeAt(i) & 0xff)) |
            ((key.charCodeAt(++i) & 0xff) << 8) |
            ((key.charCodeAt(++i) & 0xff) << 16) |
            ((key.charCodeAt(++i) & 0xff) << 24);
        ++i;

        k1 = ((((k1 & 0xffff) * c1) + ((((k1 >>> 16) * c1) & 0xffff) << 16))) & 0xffffffff;
        k1 = (k1 << 15) | (k1 >>> 17);
        k1 = ((((k1 & 0xffff) * c2) + ((((k1 >>> 16) * c2) & 0xffff) << 16))) & 0xffffffff;

        h1 ^= k1;
        h1 = (h1 << 13) | (h1 >>> 19);
        h1b = ((((h1 & 0xffff) * 5) + ((((h1 >>> 16) * 5) & 0xffff) << 16))) & 0xffffffff;
        h1 = (((h1b & 0xffff) + 0x6b64) + ((((h1b >>> 16) + 0xe654) & 0xffff) << 16));
    }

    k1 = 0;

    switch (remainder) {
        case 3: k1 ^= (key.charCodeAt(i + 2) & 0xff) << 16;
        case 2: k1 ^= (key.charCodeAt(i + 1) & 0xff) << 8;
        case 1: k1 ^= (key.charCodeAt(i) & 0xff);
            k1 = (((k1 & 0xffff) * c1) + ((((k1 >>> 16) * c1) & 0xffff) << 16)) & 0xffffffff;
            k1 = (k1 << 15) | (k1 >>> 17);
            k1 = (((k1 & 0xffff) * c2) + ((((k1 >>> 16) * c2) & 0xffff) << 16)) & 0xffffffff;
            h1 ^= k1;
    }

    h1 ^= key.length;

    h1 ^= h1 >>> 16;
    h1 = (((h1 & 0xffff) * 0x85ebca6b) + ((((h1 >>> 16) * 0x85ebca6b) & 0xffff) << 16)) & 0xffffffff;
    h1 ^= h1 >>> 13;
    h1 = ((((h1 & 0xffff) * 0xc2b2ae35) + ((((h1 >>> 16) * 0xc2b2ae35) & 0xffff) << 16))) & 0xffffffff;
    h1 ^= h1 >>> 16;

    return (h1 >>> 0).toString();
}

defineEmits(['updated-customer-name']);

const props = defineProps<{ customerSessionId: string }>();
const router = useRouter();

const { state, getters, dispatch } = useStore();

const activeCustomerDisplayName = computed(() => customerSession.value.customer.partnerAttributes?.name ?? customerSession.value?.customerKey ?? 'Cliente');

const showClientCustomer = ref(false);

const customerSession = computed<CustomerSession>(() => getters[GET_SESSIONS][props.customerSessionId]);

const currentPath = computed(() => router.currentRoute.value.path);

const amIChatting = computed(() => customerSession.value?.whoIsChatting != undefined && customerSession.value?.whoIsChatting === getters[GET_WHO_AM_I]);

const updatedCustomerName = ref<string | undefined>(undefined);
const updateCustomerNameToChildren = (newValue: string) => {
    set(updatedCustomerName, newValue);
};

watch(currentPath, async (newRoute, oldRoute) => {
    let newRouteParts = newRoute.split('/');
    let newSessionId = newRouteParts.at(-1);
    if (newRouteParts.length >= 3) {
        await dispatch(RETRIEVE_SESSION, newSessionId);
        scrollToTheEnd();
    }
    if (newRoute != oldRoute) {
        let oldRouteParts = oldRoute.split('/');
        await updateLastRead(newSessionId);
        if (oldRouteParts?.length > 2) {
            await updateLastRead(oldRouteParts.pop());
            scrollToTheEnd();
        }
    }
});

const POLLING_INTERVAL_IN_SECONDS = 10;

const pollMessages = async () => {
    if (customerSession?.value?.id === undefined) { return; }
    if (isSessionExpired(state.sessions[customerSession?.value?.id])) {
        return;
    }
    await dispatch(RETRIEVE_SESSION, customerSession?.value?.id);
};

const updateLastRead = async (sessionId: string | undefined) => {
    if (isSessionExpired(state.sessions[sessionId ?? ''])) {
        return;
    }
    const isPageHidden = document.hidden;
    const whoIsChatting = state.sessions[sessionId ?? '']?.whoIsChatting ?? null;
    if (whoIsChatting === null || isPageHidden || sessionId === undefined) {
        return;
    }
    await dispatch(MARK_READ, sessionId);
};

const intervalForPollMessages = setInterval(async () => await pollMessages(), POLLING_INTERVAL_IN_SECONDS * 1000);

const intervalForSendLastRead = setInterval(async () => await updateLastRead(customerSession?.value?.id), (POLLING_INTERVAL_IN_SECONDS / 2) * 1000);

const scrollToTheEnd = () => {
    const scrollableChatSection = document.getElementById("chat-section");
    scrollableChatSection?.scrollTo(0, scrollableChatSection.scrollHeight);
};

onBeforeMount(async () => {
    let routeParts = router.currentRoute.value.path.split('/');
    if (routeParts.length >= 3) {
        await dispatch(RETRIEVE_SESSION, routeParts.pop());
    }
});

onMounted(async () => {
    setTimeout(async () => await updateLastRead(customerSession?.value?.id), 500);
    setTimeout(scrollToTheEnd, 100);
    changeShowScrollDown();
});

onUnmounted(() => {
    updateLastRead(customerSession?.value?.id);
    clearInterval(intervalForPollMessages);
    clearInterval(intervalForSendLastRead);
});

const isExpired = computed(() => {
    if (customerSession.value === undefined) { return true; }
    return isSessionExpired(customerSession.value);
});

const showScrollDown = ref(false);

const changeShowScrollDown = () => {
    const scrollableChatSection = document.getElementById("chat-section");
    if (scrollableChatSection?.scrollTop === undefined) {
        set(showScrollDown, false);
        return;
    }
    const hasScrollbar = scrollableChatSection.scrollHeight > scrollableChatSection.clientHeight;
    const distanceToEnd = scrollableChatSection.scrollHeight - scrollableChatSection.clientHeight;
    if (!hasScrollbar) {
        set(showScrollDown, false);
        return;
    }
    set(showScrollDown, (scrollableChatSection.scrollTop / distanceToEnd) <= 0.75);
};
</script>

<template>
    <div class="min-w-[680px] flex flex-row items-stretch">
        <div
            class="relative flex-auto flex-grow-[9] overflow-hidden border-l-2 border-gray-200 grid max-lg:grid-rows-9 lg:grid-rows-12 grid-cols-1">
            <ConverzaSessionsHeader
                class="row-span-1"
                @TOGGLE_CUSTOMER_SIDEBAR="() => showClientCustomer = !showClientCustomer"
                :customerSession="customerSession"
                :updatedCustomerName="updatedCustomerName"
            />

            <div
                id="chat-section"
                class="flex flex-col flex-grow max-lg:row-span-7 lg:row-span-10 min-w-96 bg-gray-100 overflow-y-auto"
                @scroll="changeShowScrollDown"
            >
                <ChatConversation
                    v-for="(aConversation, index) in (customerSession?.conversation ?? [])"
                    :key="aConversation.whatsappId ?? murmurhash3_32_gc((aConversation?.dateTime?.toString() ?? index.toString()) + aConversation.sender)"
                    :input="{
                        displayName: activeCustomerDisplayName,
                        conversation: aConversation
                    }"
                />
                <div
                    v-if="isExpired"
                    class="flex items-center justify-center m-auto p-3 w-fit mb-4 flex-row rounded-xl text-black-800 bg-yellow-100"
                >
                    <span class="flex flex-row items-center justify-center gap-2">
                        <TriangleAlert />
                        <p class="text-xs font-bold">
                            Esta sessão foi encerrada.
                        </p>
                    </span>
                </div>
            </div>

            <Button
                v-if="showScrollDown"
                class="absolute rounded-full px-2 bg-opacity-75 max-xl:bottom-16 xl:bottom-20  left-[calc(50%-20px)] bounce-animation"
                type="button"
                @click="scrollToTheEnd"
            >
                <ArrowDown />
            </Button>

            <KeyboardComponent
                class="row-span-1"
                v-if="customerSession?.customerKey !== undefined"
                :disabled="isExpired || !amIChatting"
                :session-id="customerSessionId"
                :customer-key="customerSession?.customerKey"
                @update-read="updateLastRead"
                @scroll-to-the-end="scrollToTheEnd"
            />
        </div>

        <ChatCustomerSidebar
            class="max-w-[240px] flex-initial flex bg-white border-l border-gray-300 flex-col"
            :class="{ hidden: !showClientCustomer }"
            :customer-session-id="customerSessionId"
            @updated-customer-name="updateCustomerNameToChildren"
        />
    </div>
</template>

<style lang="scss" scoped>
::-webkit-scrollbar-thumb {
    border: 10px;
    border-radius: 20px;
    background: #979797a0;
}

.bounce-animation {
    animation: bounce 1.5s infinite;
}

@keyframes bounce {

    0%,
    100% {
        transform: translateY(-10%);
        animation-timing-function: cubic-bezier(0.8, 0, 1, 1);
    }

    50% {
        transform: translateY(0);
        animation-timing-function: cubic-bezier(0, 0, 0.2, 1);
    }
}
</style>