<template>
    <div class="flex items-center justify-between bg-white w-full border-b-2 border-gray-200">
        <div class="flex flex-initial flex-nowrap items-center space-x-2">
            <ChevronLeft
                class="mr-2"
                @click="() => router.push({ path: '/dashboard/atendimento' })"
            />
            <Avatar
                shape="circle"
                size="icon-larger"
                class="flex-initial"
            >
                <AvatarImage
                    src="https://github.com/radix-vue.png"
                    alt="@radix-vue"
                />
                <AvatarFallback>CN</AvatarFallback>
            </Avatar>
            <div class="flex-1 flex flex-col flex-nowrap justify-between min-h-full mr-2">
                <span class="font-semibold text-left text-[75%] inline-block min-w-min naming-span">
                    {{ updatedCustomerName ?? customer?.partnerAttributes.name ?? customer?.key ?? '' }}
                </span>
                <div
                    :class="{ hidden: !customerSession?.whoIsChatting }"
                    class="flex flex-row items-center min-w-min"
                >
                    <span class="text-left font-light text-gray-500 grow-0 flex-1 text-clip text-[60%]">Atribuído:
                    </span>
                    <span class="text-left flex-1 text-clip text-[70%]">&nbsp;{{
                        customerSession?.nameOfWhoIsChatting ?? customerSession?.whoIsChatting }}</span>
                </div>
            </div>
        </div>
        <div class="flex items-center mr-2">
            <Dialog v-if="customerSession?.status !== 'FINISHED'">
                <DialogTrigger>
                    <Button
                        variant="ghost"
                        size="icon"
                        class="px-0"
                    >
                        <CircleCheck />
                    </Button>
                </DialogTrigger>
                <DialogContent>
                    <DialogHeader>
                        <DialogTitle>Encerrar a Sessão?</DialogTitle>
                        <DialogDescription>
                            Esta sessão será encerrada. Você ainda poderá ler, mas o usuário precisará iniciar outra
                            sessão para manter contato.
                        </DialogDescription>
                    </DialogHeader>

                    <DialogFooter>
                        <Button
                            variant="destructive"
                            @click="requestSessionFinishment"
                        >
                            Encerrar
                        </Button>
                    </DialogFooter>
                </DialogContent>
            </Dialog>
            <Button
                variant="ghost"
                size="icon"
                @click="emit('TOGGLE_CUSTOMER_SIDEBAR')"
            >
                <CircleUserRound />
            </Button>
        </div>
    </div>
</template>

<script setup lang="ts">
import { Avatar, AvatarFallback, AvatarImage } from '@/components/ui/avatar';
import { CircleCheck, CircleUserRound, ChevronLeft } from 'lucide-vue-next';
import { defineEmits, PropType, defineProps, computed } from 'vue';
import { useRouter } from 'vue-router';
import { Customer, CustomerSession } from '@/http/customerSession';
import { Button } from '@/components/ui/button';
import { finishSession } from '@/http/customerSession';
import { useStore } from 'vuex';
import Dialog from '@/components/ui/dialog/Dialog.vue';
import DialogTrigger from '@/components/ui/dialog/DialogTrigger.vue';
import DialogContent from '@/components/ui/dialog/DialogContent.vue';
import DialogHeader from '@/components/ui/dialog/DialogHeader.vue';
import DialogTitle from '@/components/ui/dialog/DialogTitle.vue';
import DialogDescription from '@/components/ui/dialog/DialogDescription.vue';
import DialogFooter from '@/components/ui/dialog/DialogFooter.vue';

const emit = defineEmits(['TOGGLE_CUSTOMER_SIDEBAR']);

const props = defineProps({
    customerSession: {
        type: Object as PropType<CustomerSession | undefined>,
        required: false,
    },
    updatedCustomerName: {
        type: String as PropType<string | undefined>,
        required: false,
    }
});

const customer = computed<Customer | undefined>(() => {
    return props.customerSession?.customer;
});

const store = useStore();
const requestSessionFinishment = async () => {
    if (props?.customerSession?.id === undefined) { return; }
    await finishSession(props.customerSession.id, store.state.credentials);
    return;
};

const router = useRouter();
</script>
