<template>
    <div class="flex flex-col flex-nowrap items-stretch h-screen">
        <HeaderComponent
            class="flex-1 flex-grow-0"
            title="Configurações"
        />
        <main class="flex-auto overflow-x-auto">
            <ConfigSubsection subtitle="Conversas">
                <ChatConfig />
            </ConfigSubsection>
            <hr class="opacity-45">
            <ConfigSubsection
                class="min-w-[900px] grid grid-cols-1"
                subtitle="Provedor"
            >
                <PartnerProviderCard
                    :partnerProvider="partnerProvider"
                    v-for="partnerProvider of partnerProviders"
                    :key="partnerProvider.id"
                    :display-toast="showToastMessage"
                />
                <div
                    class="flex justify-end py-2 px-4"
                    v-if="!showAddPartnerProvider"
                >
                    <Button
                        @click="() => showAddPartnerProvider = true"
                        class="bg-blue-700 text-white rounded-full"
                    >
                        Adicionar mais
                    </Button>
                </div>
                <PartnerProviderCreationForm
                    v-if="showAddPartnerProvider"
                    @cancel="disableCreationForm"
                    @creation-finished="disableCreationForm"
                    :display-toast="showToastMessage"
                />
            </ConfigSubsection>
        </main>
    </div>
    <Toaster />
</template>

<script setup lang="ts">
import HeaderComponent from '@/components/HeaderComponent.vue';
import ConfigSubsection from '@/components/config-screen/ConfigSubsection.vue';
import PartnerProviderCard from '@/components/config-screen/PartnerProviderCard.vue';
import { computed, ref } from 'vue';
import { Button } from '@/components/ui/button';
import { PartnerProvider } from '@/http/partnerProvider';
import { GET_PARTNER_PROVIDERS, RETRIEVE_PARTNER_PROVIDERS } from '@/store';
import { useStore } from 'vuex';
import PartnerProviderCreationForm from '@/components/config-screen/PartnerProviderCreationForm.vue';
import ChatConfig from '@/components/config-screen/ChatConfig.vue';
import { toast } from '@/components/ui/toast';

const store = useStore();

store.dispatch(RETRIEVE_PARTNER_PROVIDERS);
const partnerProviders = computed<PartnerProvider[]>(() => Object.values(store.getters[GET_PARTNER_PROVIDERS]));

const showAddPartnerProvider = ref(false);

const disableCreationForm = () => {
    showAddPartnerProvider.value = false;
};

const showToastMessage = (title: string, variant: "default" | "destructive", description?: string) => {
    toast({
       title: title,
       description: description,
       variant: variant 
    });
};
</script>
