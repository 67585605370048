import { BASE_URL, throwCorrespondentApiErrorIfNecessary } from "@/http/api";
import { Customer } from "@/http/customerSession";
import { getCredentials, APICredentials } from "@/http/login";

export interface PatchPartnerAttributes {
  partnerId: string;
  customerKey: string;
  notes?: string;
  partnerAttributes?: Record<string, string>;
}

export const deletePartnerAttributes = async (customerId: string, customerAttribute: string, credentials: APICredentials) => {
  const url = new URL(`${BASE_URL}/customers/${customerId}/partner-attributes/${customerAttribute}`);
  const response = await fetch(url, {
    method: 'DELETE',
    headers: {
      'Authorization': getCredentials(credentials),
    }
  });

  await throwCorrespondentApiErrorIfNecessary(response);
};

export interface CustomerPatchPartnerFieldRequest {
  partnerId: string;
  customerKey: string;
  notes?: string;
  partnerAttributes?: Record<string, string>;
}

export const customerPatchPartnerFieldRequest = async (payload: CustomerPatchPartnerFieldRequest, credentials: APICredentials) => {
  const url = new URL(`${BASE_URL}/customers`);
  const response = await fetch(url, {
    method: 'PATCH',
    body: JSON.stringify(payload),
    headers: {
      'Authorization': getCredentials(credentials),
      'Content-Type': 'application/json',
    }
  });

  await throwCorrespondentApiErrorIfNecessary(response);
};

export const getCostumerById = async (id: string, credentials: APICredentials) => {
  const url = new URL(`${BASE_URL}/customers/${id}`);
  const response = await fetch(url, {
    method: 'GET',
    headers: {
      'Authorization': getCredentials(credentials),
      'Content-Type': 'application/json'
    }
  });

  await throwCorrespondentApiErrorIfNecessary(response);

  const getCostumerById: Customer = await response.json();
  return getCostumerById;
};