<template>
  <div>
    <Input :model-value="props.data.title" :readonly="true" class="w-[12.5rem]"/>
  </div>
  <Handle :id="`${props.data.id}_R`" type="source" :position="Position.Right" />
</template>

<script setup lang="ts">
import { defineProps } from 'vue';
import { SelectionReply } from '@/http/workflowTask';
import { Handle, NodeProps, Position } from '@vue-flow/core';
import Input from '@/components/ui/input/Input.vue';

const props = defineProps<NodeProps<SelectionReply>>();

</script>