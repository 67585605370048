<template>
  <Card
    @click="() => router.push({ path: `/dashboard/atendimento` })"
    class="cursor-pointer p-6 min-h-40 w-48 space-y-4 flex flex-col flex-nowrap items-stretch border-0"
  >
    <div class="flex-1 flex flex-row items-center gap-4 max-w-full">
      <ProviderIcon :provider="partnerProvider.partnerProviderType" />
      <h3
        class="flex-1 text-left text-sm font-semibold text-wrap break-words overflow-hidden text-ellipsis whitespace-nowrap max-h-full"
      >{{
        partnerProvider.presentationNameInProvider }}</h3>
    </div>
    <div class="flex-1 block space-y-1">
      <StatQuery
        :stat="'Em atendimento'"
        :value="waiting"
        :did-query="queried"
      />
      <StatQuery
        :stat="'Expiradas'"
        :value="expired"
        :did-query="queried"
      />
      <StatQuery
        :stat="'Finalizadas'"
        :value="finished"
        :did-query="queried"
      />
      <StatQuery
        :stat="'Total'"
        :value="total"
        :did-query="queried"
      />
    </div>
  </Card>
</template>

<script setup lang="ts">
import Card from '@/components/ui/card/Card.vue';
import { PartnerProvider } from '@/http/partnerProvider';
import { set } from '@vueuse/core';
import { PropType, computed, defineProps, onMounted, ref } from 'vue';
import { useRouter } from 'vue-router';
import ProviderIcon from '@/components/ProviderIcon.vue';
import { CustomerSession, getSessions } from '@/http/customerSession';
import { useStore } from 'vuex';
import { GET_CREDENTIALS, PARTNER_ID } from '@/store';
import StatQuery from '@/components/dashboard/StatQuery.vue';

const props = defineProps({
  partnerProvider: {
    type: Object as PropType<PartnerProvider>,
    required: true,
  },
});

const { getters } = useStore();

const router = useRouter();

const waiting = ref(0);
const expired = ref(0);
const finished = ref(0);
const total = computed(() => waiting.value + finished.value + expired.value);

const queried = ref(false);

const filterExpired = (sessions: CustomerSession[]) => {
  return sessions.filter((session: CustomerSession) => session.expirationDate < new Date());
};
onMounted(async () => {
  const finishedSessions = await getSessions({
    size: 10000000,
    page: 0,
    partnerProviderId: props.partnerProvider.id,
    considerTimeout: false,
    partnerId: getters[PARTNER_ID],
    status: 'FINISHED',
  }, getters[GET_CREDENTIALS]);
  set(finished, finishedSessions.content.length);

  const waitingSessions = await getSessions({
    size: 10000000,
    page: 0,
    partnerProviderId: props.partnerProvider.id,
    considerTimeout: false,
    partnerId: getters[PARTNER_ID],
    status: 'OPEN_SUPPORT',
  }, getters[GET_CREDENTIALS]);
  set(waiting, waitingSessions.content.length);

  const expiredOpenSupport = await getSessions({
    size: 10000000,
    page: 0,
    partnerProviderId: props.partnerProvider.id,
    considerTimeout: false,
    partnerId: getters[PARTNER_ID],
    status: 'OPEN_SUPPORT',
  }, getters[GET_CREDENTIALS]);
  const expiredOpenBot = await getSessions({
    size: 10000000,
    page: 0,
    partnerProviderId: props.partnerProvider.id,
    considerTimeout: false,
    partnerId: getters[PARTNER_ID],
    status: 'OPEN_BOT',
  }, getters[GET_CREDENTIALS]);
  set(expired, filterExpired(expiredOpenSupport.content).length + filterExpired(expiredOpenBot.content).length);

  set(queried, true);
});
</script>