<template>
  <div class="flex flex-row h-screen w-screen fixed">
    <div
      class="sidebar flex flex-col border-r-2 justify-between items-center h-full overflow-x-hidden shrink-0"
      :class="{
        'w-16': isCollapsed,
        'w-52': !isCollapsed,
      }"
    >
      <div class="w-full">
        <div class="w-full">
          <span v-if="isCollapsed">
            <img
              src="../assets/converza_icon_only.svg"
              class="h-[73px] w-[73px] mc:pt-4 mc:px-4 p-2 grow-from-margin"
              alt=""
            />
          </span>
          <span v-else>
            <img
              src="../assets/logo-converza.png"
              class="w-52 h-[73px] p-4 duration-1000 reduce-from-margin"
              alt=""
            />
          </span>
        </div>

        <div class="pt-5 flex flex-col justify-center mb-3 gap-4 text-base">
          <SidebarLink
            v-for="dashboardSubroutes in routes.find(
              (route) => route.name === DEFAULT_BASE_DASHBOARD_NAME
            )?.children?.filter((route: CustomRouteRecordRaw) => route?.ignoreOnNavbar !== true)"
            :key="dashboardSubroutes.path"
            :to="dashboardSubroutes.name === 'Dashboard'
              ? '/dashboard'
              : `/dashboard/${dashboardSubroutes.path}`
              "
            :icon="(dashboardSubroutes as CustomRouteRecordRaw)?.icon ?? ''"
            :name="dashboardSubroutes.name?.toString() ?? ''"
            :collapsed="isCollapsed"
          />
        </div>
      </div>
      <div class="flex flex-col gap-4 w-full pb-4">
        <SidebarLink
          to="/dashboard/ajuda"
          icon="fa-regular fa-circle-question"
          name="Ajuda"
          :collapsed="isCollapsed"
        />

        <SidebarLink
          to="/dashboard/configuracoes"
          icon="fas fa-gear"
          name="Configurações"
          :collapsed="isCollapsed"
        />
        <SidebarLink
          :collapsed="isCollapsed"
          variant="button"
          icon="fas fa-angle-double-left"
          name="Fechar Menu"
          @click="toggleSidebar"
          :rotate="true"
        />
      </div>
    </div>
    <div class="overflow-y-auto w-full h-full">
      <router-view />
    </div>
  </div>
</template>

<style>
:root {
  --sidebar-bg-color: #ffffff;
  --sidebar-item-active: #e5f1ff;
  --sidebar-left-border: #2e54dc;
  --primary-blue: #2e54dc;
  --sidebar--icon-color: #d6d6d6;
}
</style>

<style scoped>
.sidebar {
  background-color: var(--sidebar-bg-color);
  transition: 0.3s ease;
}

.reduce-from-margin {
  transition: margin-right 0.2s ease;
}

.grow-from-margin {
  transition: margin-left 0.2s ease;
}
</style>

<script setup lang="ts">
import SidebarLink from '../components/sidebar/SideBarLink.vue';

import { ref, onMounted } from 'vue';

import {
  CustomRouteRecordRaw,
  DEFAULT_BASE_DASHBOARD_NAME,
  routes,
} from '@/router';
import { useStore } from 'vuex';
import { CLEAR_AUTH, RETRIEVE_PARTNER } from '@/store';
import { AuthenticationError } from '@/http/api';

const isMobile = () => {
  const userAgent = navigator.userAgent;
  const mobileDevices = [
    /Android/i,
    /webOS/i,
    /iPhone/i,
    /iPad/i,
    /iPod/i,
    /BlackBerry/i,
    /Windows Phone/i,
  ];
  return mobileDevices.some((pattern) => pattern.test(userAgent));
};

const isCollapsed = ref(false);
const toggleSidebar = () => (isCollapsed.value = !isCollapsed.value);

const { dispatch, commit } = useStore();

onMounted(async () => {
  isCollapsed.value = isMobile();
  try {
    await dispatch(RETRIEVE_PARTNER);
  } catch (err) {
    if (err instanceof AuthenticationError) {
      commit(CLEAR_AUTH);
      return;
    }
  }
});
</script>
