<template>
    <div
        :class="[
            'hover:bg-gray-100 flex items-center py-4 border-b-2 border-gray-200 pl-1',
            { 'bg-gray-200': isSelected }
        ]"
        @click="() => router.push({ path: `/dashboard/atendimento/${props.session.id}` })"
    >
        <div class="relative flex-shrink-0 mr-4 ml-2">
            <Avatar>
                <AvatarImage
                    src="https://github.com/radix-vue.png"
                    alt="@radix-vue"
                />
                <AvatarFallback>CN</AvatarFallback>
            </Avatar>
            <div class="w-5 h-5 absolute bottom-0 right-0 rounded-full flex items-center justify-center">
                <Avatar class="w-5 h-5">
                    <ProviderIcon
                        :provider="props.session.providerType"
                        :providerOrder="providersAmmount[props.session.providerType] > 1
                            ? String(orders[props.session.partnerProviderId])
                            : undefined"
                    />
                </Avatar>
            </div>
        </div>

        <div class="w-40 max-w-44 mr-5">
            <h2 class="font-semibold text-left text-wrap text-base mt-1">
                {{ session.customer.partnerAttributes?.name ?? session.customer.key }}</h2>
            <div class="flex flex-row flex-nowrap items-center gap-1 w-full">
                <Check
                    class="h-4 w-4 min-w-3 mt-1 opacity-45"
                    v-if="props.session.conversation.at(-1)?.sender == 'SUPPORT' && props.session.conversation.at(-1)?.target === 'CHAT'"
                />
                <NotebookPen
                    class="h-4 w-4 min-w-3 mt-1 opacity-45"
                    v-if="props.session.conversation.at(-1)?.target === 'INTERNAL'"
                />
                <Bot
                    class="h-4 w-4 min-w-3 mt-1 opacity-45"
                    v-if="props.session.conversation.at(-1)?.sender == 'BOT'"
                />
                <p
                    v-show="props.session.conversation.at(-1)?.messageType !== 'image'"
                    class="font-normal text-left text-xs truncate mt-1"
                >
                    {{ props.session.conversation.at(-1)?.message?.text ??
                        props.session.conversation.at(-1)?.workflowTask?.content ?? '' }}
                </p>
                <p
                    v-show="props.session.conversation.at(-1)?.messageType === 'image'"
                    class="font-normal text-left text-xs truncate mt-1"
                >
                    <span class="flex flex-row items-center gap-1">
                        <Image
                            :size="16"
                            :stroke-width="1.25"
                        /> Imagem
                    </span>
                </p>
            </div>
        </div>

        <div class="flex grow justify-center items-center">
            <span
                v-if="props.session.whoIsChatting !== null && howManyBeforeLastSupportMessage > 0 && !isSelected"
                class="bg-blue-700 font-mono text-white px-3 py-1 rounded-full text-sm leading-6 font-semibold"
            >
                {{ howManyBeforeLastSupportMessage }}
            </span>
            <Button
                v-else-if="props.session.whoIsChatting === null"
                variant="default"
                class="bg-blue-700 text-white px-3 py-2 rounded-full text-sm leading-6 font-semibold w-[76px] h-8"
                @click="() => {
                    clickOnCard();
                    emit('TOGGLE_TO_ANSWER_CHAT');
                }"
            >
                Atender
            </Button>
        </div>
    </div>
</template>

<script setup lang="ts">
import ProviderIcon from '@/components/ProviderIcon.vue';
import { Avatar, AvatarFallback, AvatarImage } from '@/components/ui/avatar';
import { CustomerSession, PartnerProviderType } from '@/http/customerSession';
import { ANSWER_CALL, GET_WHO_AM_I, RETRIEVE_SESSION } from '@/store';
import { computed, defineProps, defineEmits, } from 'vue';
import type { PropType } from 'vue';
import { useStore } from 'vuex';
import Button from '@/components/ui/button/Button.vue';
import { useRoute, useRouter } from 'vue-router';
import { PartnerProvider } from '@/http/partnerProvider';
import { Bot, Check, Image, NotebookPen } from 'lucide-vue-next';

const props = defineProps({
    session: {
        type: Object as PropType<CustomerSession>,
        required: true
    },
});

const emit = defineEmits(['TOGGLE_TO_ANSWER_CHAT']);

const { state, getters, dispatch } = useStore();
const router = useRouter();
const route = useRoute();
const providersAmmount = computed(() => {
    const providersQuantity: Record<PartnerProviderType, number> = {
        'WHATSAPP': 0,
        'INSTAGRAM': 0
    };

    for (let provider of Object.values(state.partnerProviders) as Array<PartnerProvider>) {
        if (provider.partnerProviderType != undefined) {
            providersQuantity[provider.partnerProviderType as PartnerProviderType] += 1;
        }
    }

    return providersQuantity;
});

const dateIsBefore = (firstDate: Date, secondDate: Date) => {
    return firstDate < secondDate;
};

const howManyBeforeLastSupportMessage = computed<number>(() => {
    let count = 0;
    for (let i = props.session.conversation.length - 1; i >= 0; i--) {
        const messageDate = props.session.conversation[i].dateTime;
        const lastRead = props.session.lastRead;
        if (lastRead === undefined || dateIsBefore(messageDate, lastRead)) {
            break;
        }
        count += props.session.conversation[i].sender === 'CUSTOMER' ? 1 : 0;
    }
    return count;
});

const selectedSessionId = computed<string | undefined>(() => {
    const pathSegments = route.path.split('/');
    return pathSegments[pathSegments.length - 1];
});

const clickOnCard = async () => {
    if (props.session.whoIsChatting === getters[GET_WHO_AM_I]) {
        router.push({ path: `/dashboard/atendimento/${props.session.id}` });
        return;
    }

    if (props.session.whoIsChatting === null) {
        await dispatch(ANSWER_CALL, props.session.id);
        await dispatch(RETRIEVE_SESSION, props.session.id);
        router.push({ path: `/dashboard/atendimento/${props.session.id}` });
        return;
    }
};

const isSelected = computed(() => props.session.id === selectedSessionId.value);

const orders = computed(() => state.partnerProvidersOrder);
</script>
