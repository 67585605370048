<template>
  <span v-if="props.workflowTaskType === 'text'">
    <MessageCircleMore
      color="#3EA761"
      :stroke-width="1.5"
    />
  </span>
  <span v-else-if="props.workflowTaskType === 'interactive' && props.workflowTaskSubtype === 'LIST'">
    <List
      color="#3EA761"
      :stroke-width="1.5"
    />
  </span>
  <span v-else-if="props.workflowTaskType === 'interactive' && props.workflowTaskSubtype === 'BUTTON'">
    <MessageSquareReply
      color="#3EA761"
      :stroke-width="1.5"
    />
  </span>
  <span v-else>
    <TriangleAlert
      color="#EDA900"
      :stroke-width="1.5"
    />
  </span>
</template>

<script setup lang="ts">
import { List, MessageCircleMore, MessageSquareReply, TriangleAlert } from 'lucide-vue-next';
import { defineProps } from 'vue';

const props = defineProps({
  workflowTaskType: String,
  workflowTaskSubtype: {
    type: String,
    required: false,
    default: undefined
  }
});

</script>