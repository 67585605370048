<template>
  <Dialog v-model:open="isModalCreationOpen">
    <DialogTrigger as-child>
      <slot></slot>
    </DialogTrigger>
    <DialogContent class="sm:max-w-[425px] z-40">
      <DialogHeader>
        <DialogTitle>Criação de Fluxo</DialogTitle>
      </DialogHeader>
      <div class="grid gap-4 py-4">
        <div class="grid grid-cols-4 items-center gap-4">
          <Label
            for="presentationName"
            class="text-right"
          >
            <span class="text-red-500">*</span> Nome:
          </Label>
          <div class="col-span-3">
            <span
              class="text-red-500"
              :class="{ 'invisible': isWorkflowDataValid }"
            >{{ validationErrorMessage }}</span>
            <Input
              id="presentationName"
              :class="{
                'border-gray-300': isModalCreationOpen,
                'border-red-500': !isWorkflowDataValid
              }"
              v-model="name"
              @update:model-value="() => isWorkflowDataValid = true"
            />
          </div>
        </div>
        <div class="grid grid-cols-4 items-center gap-4">
          <Label
            for="notes"
            class="text-right"
          >
            Descrição:
          </Label>
          <Input
            id="notes"
            class="col-span-3"
            v-model="notes"
          />
        </div>
      </div>
      <DialogFooter>
        <Button
          @click="tryToSaveWorkflow"
          type="submit"
        >
          Salvar Fluxo
        </Button>
      </DialogFooter>
    </DialogContent>
  </Dialog>
</template>

<script setup lang="ts">
import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from '@/components/ui/dialog';

import { Input } from '@/components/ui/input';
import { Label } from '@/components/ui/label';
import { Button } from '@/components/ui/button';
import { defineEmits, defineModel, ref } from 'vue';
import { set } from '@vueuse/core';
import { parsePresentationNameToWorkflowCode } from '@/models/workflow';

const isModalCreationOpen = defineModel('isModalCreationOpen', {
  type: Boolean,
  default: false
});

const isWorkflowDataValid = defineModel('isWorkflowDataValid', {
  type: Boolean,
  default: true
});

const validationErrorMessage = defineModel('validationErrorMessage', {
  type: String,
  default: undefined
});

const emit = defineEmits(['try-to-save-workflow']);
const name = ref<string | undefined>(undefined);
const notes = ref<string | undefined>(undefined);

const tryToSaveWorkflow = () => {
  if (name.value === undefined) {
    isWorkflowDataValid.value = false;
    validationErrorMessage.value = "O nome não pode ser vazio!";
    return;
  }

  const workflowCode = parsePresentationNameToWorkflowCode(name.value);
  const creationRequest = {
    "workflowCode": workflowCode,
    "presentationName": name.value.trim(),
    "notes": notes.value?.trim()
  };

  emit('try-to-save-workflow', creationRequest, clearInputs);
};

const clearInputs = () => {
  set(name, undefined);
  set(notes, undefined);
};

</script>