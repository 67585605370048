<template>
  <DropdownMenu v-if="selectedWorkflow !== undefined">
    <DropdownMenuTrigger>
      <Button
        variant="ghost"
        class=" p-1"
      >
        <Info class="text-muted" />
      </Button>
    </DropdownMenuTrigger>
    <DropdownMenuContent class="bg-white mt-4 w-60">
      <DropdownMenuLabel>Descrição</DropdownMenuLabel>
      <DropdownMenuSeparator class="bg-gray-300" />
      <span
        class="flex flex-col"
        :class="{ 'hidden': isAddingNewNote }"
        v-if="selectedWorkflow.notes === null"
      >
        <p class="text-gray-500 p-2">Você não descreveu esse este fluxo.</p>
        <Button @click="() => isAddingNewNote = true">Adicionar</Button>
      </span>
      <span
        class="text-left"
        v-else
      >
        <p class="p-2">{{ selectedWorkflow.notes }}</p>
      </span>
      <div
        class="p-2 text-right"
        :class="{ 'hidden': !isAddingNewNote }"
      >
        <Textarea
          class="min-h-28 mb-2"
          name="new-note"
          id="new-note"
          v-model="newNoteForWorkflow"
        ></Textarea>
        <Button
          variant="ghost"
          @click="() => isAddingNewNote = false"
        >Cancelar</Button>
        <Button @click="tryToSaveNote">Salvar</Button>
      </div>
    </DropdownMenuContent>
  </DropdownMenu>
</template>

<script setup lang="ts">
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu';

import Textarea from '@/components/ui/textarea/Textarea.vue';
import { Info } from 'lucide-vue-next';
import Button from '@/components/ui/button/Button.vue';
import { defineModel, defineEmits, PropType, ref, watch } from 'vue';
import { patchWorkflow, Workflow } from '@/http/workflow';
import { useStore } from 'vuex';
import { GET_CREDENTIALS } from '@/store';

const selectedWorkflow = defineModel('selectedWorkflow', {
  type: Object as PropType<Workflow | undefined>,
  required: true,
  default: undefined
});

watch(selectedWorkflow, () => {
  newNoteForWorkflow.value = undefined;
  isAddingNewNote.value = false;
});

const isAddingNewNote = ref(false);
const newNoteForWorkflow = ref<string | undefined>(undefined);

const store = useStore();

const emit = defineEmits([
  'refresh-workflow-on-list'
]);

const tryToSaveNote = async () => {
  const workflowId = selectedWorkflow.value?.id;
  const credentials = store.getters[GET_CREDENTIALS];
  let noteTrimmed = newNoteForWorkflow.value?.trim();

  if (noteTrimmed === "") noteTrimmed = undefined;
  if (workflowId === undefined) return;

  const payload = { "notes": noteTrimmed };
  await patchWorkflow(workflowId, payload, credentials);
  newNoteForWorkflow.value = undefined;
  isAddingNewNote.value = false;
  emit('refresh-workflow-on-list', workflowId);
};

</script>