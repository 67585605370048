<template>
    <section class="p-4 border-b-2 border-gray-200">
        <div class="flex items-center mb-4">
            <ChevronRight
                @click="() => show = !show"
                :class="{ 'rotate-90': show }"
            />
            <h2 class="font-semibold text-black-700 text-nowrap">{{ title }}</h2>
        </div>
        <div :class="{
            hidden: !show
        }">
            <slot></slot>
            <slot name="circle-plus-reaction">
                <div class="hidden"></div>
            </slot>
        </div>
    </section>
</template>

<script lang="ts" setup>
import { set } from '@vueuse/core';
import { ref, defineEmits, defineProps, defineModel, watch, PropType } from 'vue';
import { ChevronRight } from 'lucide-vue-next';
import { CustomerSession } from '@/http/customerSession';

const props = defineProps({
    title: String,
    hasToShowOnStart: {
        default: true,
        required: false
    },
    actionWhenShow: {
        required: false,
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        default: () => { },
        type: Function
    }
});
const show = ref<boolean>(props.hasToShowOnStart);
const customerSession = defineModel({
    required: false,
    type: Object as PropType<CustomerSession | undefined>,
});

watch(customerSession, (newSession, old) => {
    if (newSession?.id !== old?.id) set(show, props.hasToShowOnStart);
});

defineEmits(['circle-plus-action']);

watch(show, async (newValue) => {
    if (newValue === true) {
        await props.actionWhenShow();
    }
});
</script>