import { BASE_URL, throwCorrespondentApiErrorIfNecessary } from "@/http/api";
import { APICredentials, getCredentials } from "@/http/login";
import { WorkflowTask } from "@/http/workflowTask";
import { Partner } from "@/models/partner";

export const isSessionExpired = (session?: CustomerSession) => {
  if (session === undefined) return true;
  return session.status === 'FINISHED' || new Date(session.expirationDate) < new Date();
};

export type MessageType = 'text' |
  'message' |
  'interactive' |
  'location' |
  'image' |
  'document' |
  'audio' |
  'video' |
  'sticker' |
  'contacts';

export type PartnerProviderType = 'WHATSAPP' | 'INSTAGRAM';

export type CustomerSessionType = 'FINISHED' | 'OPEN_BOT' | 'OPEN_SUPPORT';

export type ConversationActor = 'BOT' | 'SUPPORT' | 'CUSTOMER';

export type ConversationTarget = 'CHAT' | 'INTERNAL';
export type ImageSourceChoice = 'URL' | 'BASE64' | 'BLOB_ID';

export interface ConversationMessage {
  text?: string;
  messageAnnexedImageUrl?: string;
  imageContent?: string;
  imageSourceChoice: ImageSourceChoice;
  imageContentType: string;
}

export interface Conversation {
  dateTime: Date;
  sender: ConversationActor;
  target: ConversationTarget;
  messageType: MessageType;
  message?: ConversationMessage;
  workflowTask?: WorkflowTask;
  whatsappId?: string;
}

export interface Customer {
  id: string;
  associatedPartnerId: string;
  associatedPartnerProviderId: string;
  key: string;
  partnerAttributes: Record<string, string>;
  notes: string;
}

export interface Customer {
  id: string;
  associatedPartnerId: string;
  associatedPartnerProviderId: string;
  key: string;
  partnerAttributes: Record<string, string>;
}

export interface CustomerSession {
  id: string;
  protocol: string;
  partnerId: string;
  customerKey: string;
  customer: Customer;
  partnerProviderId: string;
  providerType: PartnerProviderType;
  currentTaskId?: string;
  status: CustomerSessionType;
  expirationDate: Date;
  recentlyCreatedSession: boolean;
  recentlyCreatedCustomer: boolean;
  unsuccessfulInteractions: number;
  conversation: Conversation[];

  whoIsChatting?: string;
  nameOfWhoIsChatting?: string;
  lastRead?: Date;
}

export interface CustomerSessionsPage {
  totalPages: number;
  totalElements: number;
  size: number;
  content: CustomerSession[];
  number: number;
  sort: {
    empty: boolean;
    sorted: boolean;
    unsorted: boolean;
  };
  first: boolean;
  last: boolean;
  numberOfElements: number;
  pageable: {
    pageNumber: number;
    pageSize: number;
    sort: {
      empty: boolean;
      sorted: boolean;
      unsorted: boolean;
    };
    offset: number;
    paged: boolean;
    unpaged: boolean;
  };
  empty: boolean;
}

export interface CustomerSessionPageableRequest {
  customerKey?: string;
  partnerId?: string;
  partnerProviderId?: string;
  status?: CustomerSessionType;
  protocol?: string;
  providerType?: PartnerProviderType;
  isAlreadyChatting?: boolean;
  whoIsChatting?: string;
  considerTimeout?: boolean;
  page: number;
  size: number;
}

export interface SetWhoIsChattingPayload {
  sessionId: string;
  authUserId: string;
}

export const answerCall = async (customerSessionId: string, whoIsChatting: string, credentials: APICredentials) => {
  const url = new URL(`${BASE_URL}/customer-sessions/${customerSessionId}`);
  const response = await fetch(url, {
    method: 'PATCH',
    body: JSON.stringify({
      whoIsChatting: whoIsChatting,
    }),
    headers: {
      'Authorization': getCredentials(credentials),
      'Content-Type': 'application/json',
    }
  });
  await throwCorrespondentApiErrorIfNecessary(response);
};

export const getSessions = async (requestFilter: CustomerSessionPageableRequest, credentials: APICredentials) => {
  const baseUrl = new URL(`${BASE_URL}/customer-sessions`);
  const params = new URLSearchParams(JSON.parse(JSON.stringify(requestFilter)));
  baseUrl.search = params.toString();
  const url = baseUrl.toString();
  const response = await fetch(url, {
    method: 'GET',
    headers: {
      'Authorization': getCredentials(credentials),
      'Content-Type': 'application/json'
    }
  });
  await throwCorrespondentApiErrorIfNecessary(response);

  const customerSessionsPage: CustomerSessionsPage = await response.json();
  return customerSessionsPage;
};


export const getSessionById = async (id: string, credentials: APICredentials) => {
  const url = new URL(`${BASE_URL}/customer-sessions/${id}`);
  const response = await fetch(url, {
    method: 'GET',
    headers: {
      'Authorization': getCredentials(credentials),
      'Content-Type': 'application/json'
    }
  });

  await throwCorrespondentApiErrorIfNecessary(response);

  const customerSession: CustomerSession = await response.json();
  return customerSession;
};


export const queryForSessionsOfCustomerKey = async (customerKey: string, credentials: APICredentials) => {
  const url = `${BASE_URL}/customer-sessions?customerKey=${encodeURIComponent(customerKey)}`;
  const response = await fetch(url, {
    method: 'GET',
    headers: {
      'Authorization': getCredentials(credentials),
      'Content-Type': 'application/json'
    }
  });

  await throwCorrespondentApiErrorIfNecessary(response);

  const oldSessionsPage: CustomerSessionsPage = await response.json();
  return oldSessionsPage;
};

export type SendMessageType = 'text' | 'image';
export interface SendTextMessageRequest {
  "type": SendMessageType;
  "target": ConversationTarget;
  "message": Message;
}

export interface Message {
  text?: string;
  imageContent?: string;
  imageSourceChoice?: ImageSourceChoice;
  imageContentType?: string;
}

export interface ImageUpload {
  text?: string;
  imageContent?: string;
  imageSourceChoice?: ImageSourceChoice;
  imageContentType?: string;
}

export const sendMessage = async (sessionId: string, textMessageRequest: SendTextMessageRequest, credentials: APICredentials) => {
  const url = `${BASE_URL}/orchestrator/${sessionId}`;

  const response = await fetch(url, {
    method: 'POST',
    headers: {
      'Authorization': getCredentials(credentials),
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(textMessageRequest)
  });

  await throwCorrespondentApiErrorIfNecessary(response);
};

export const setLastRead = async (sessionId: string, credentials: APICredentials) => {
  const url = `${BASE_URL}/customer-sessions/${sessionId}/last-read`;

  const response = await fetch(url, {
    method: 'POST',
    headers: { 'authorization': getCredentials(credentials) }
  });

  await throwCorrespondentApiErrorIfNecessary(response);

  return;
};

export const finishSession = async (customerSessionId: string, credentials: APICredentials) => {
  const url = new URL(`${BASE_URL}/customer-sessions/${customerSessionId}`);
  const response = await fetch(url, {
    method: 'PATCH',
    body: JSON.stringify({
      status: 'FINISHED' as CustomerSessionType,
    }),
    headers: {
      'Authorization': getCredentials(credentials),
      'Content-Type': 'application/json',
    }
  });
  await throwCorrespondentApiErrorIfNecessary(response);
};

export const getSessionTimeoutLimit = async (partnerId: string, credentials: APICredentials): Promise<number> => {
  const url = new URL(`${BASE_URL}/partners/${partnerId}`);

  const response = await fetch(url, {
    method: 'GET',
    headers: {
      'Authorization': getCredentials(credentials),
      'Accept': 'application/json'
    }
  });

  await throwCorrespondentApiErrorIfNecessary(response);

  return (await response.json()).sessionLimitTimeout;
};

export const getPartner = async (partnerId: string, credentials: APICredentials): Promise<Partner> => {
  const url = new URL(`${BASE_URL}/partners/${partnerId}`);

  const response = await fetch(url, {
    method: 'GET',
    headers: {
      'Authorization': getCredentials(credentials),
      'Accept': 'application/json'
    }
  });

  await throwCorrespondentApiErrorIfNecessary(response);

  return await response.json();
};