import { BASE_URL, throwCorrespondentApiErrorIfNecessary } from "@/http/api";
import { getCredentials, APICredentials } from "@/http/login";

interface ApiBlob {
  id: string;
  relatedWorkflowTaskId?: string;
  partnerId?: string;
  contentType?: string;
  base64Encoded?: string;
  metaId?: string;
  associatedCustomerSessionId?: string;
  associatedCustomerKey?: string;
  associatedPartnerProviderId?: string;
}

export const getBlobById = async (blobId: string, credentials: APICredentials): Promise<ApiBlob> => {
  const url = new URL(`${BASE_URL}/blobs/${blobId}`);
  const response = await fetch(url, {
    method: 'GET',
    headers: {
      'Authorization': getCredentials(credentials),
    }
  });

  await throwCorrespondentApiErrorIfNecessary(response);
  return await response.json();
};