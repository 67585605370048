<template>
  <header class="min-h-14 h-[10vh] flex flex-row justify-between items-center border-blue-100 border-b-2 py-2 px-4">
    <div class="text-black flex items-center">
      <router-link :to="getBaseRoute">
        <h1 class="font-semibold max-sm:text-xl text-xl">{{ title }}</h1>
      </router-link>
    </div>
    <div class="flex flex-row gap-4 items-center">
      <slot></slot>
      <Button
        variant="outline"
        size="sm"
        class="flex items-center justify-center max-sm:text-sm font-semibold"
      >
        <span>Ativar<span class="max-md:hidden"> seu plano</span></span>
      </Button>
      <i class="fa-regular fa-envelope text-2xl text-gray-500 opacity-70 sm:text-xl"></i>
      <HeaderAvatarMenu @LOGOUT="async () => {
        commit(CLEAR_AUTH);
        await router.push('/login');
      }" />
    </div>
  </header>
</template>

<script setup lang="ts">
import { Button } from '@/components/ui/button';

import { computed, defineProps } from 'vue';
import HeaderAvatarMenu from '@/components/HeaderAvatarMenu.vue';
import { useStore } from 'vuex';
import { useRoute, useRouter } from 'vue-router';
import { CLEAR_AUTH } from '@/store';

defineProps({
  title: String,
});

const getBaseRoute = computed(() => {
  let routePath = route.fullPath.split('/').slice(1);
  let baseRoute = [];
  while (baseRoute.length < 2 && routePath.length >= 0) {
    baseRoute.push(routePath.at(0));
    routePath = routePath.slice(1);
  }
  return `/${baseRoute.join('/')}`;
});

const { commit } = useStore();
const router = useRouter();
const route = useRoute();
</script>
