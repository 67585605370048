<template>
    <div class="flex items-center justify-between w-full p-4">
        <div
            class="flex items-center space-x-2 w-full"
            :class="{
                'flex-row': input.conversation.sender === 'CUSTOMER',
                'flex-row-reverse': input.conversation.sender !== 'CUSTOMER',
            }"
        >
            <div :class="{
                'flex flex-col ml-2 rounded-xl text-black-700 p-3 max-w-xl': true,
                'bg-bot-message opacity-60 rounded-tr-none': input.conversation.sender === 'BOT',
                'bg-support-message rounded-tr-none': input.conversation.sender === 'SUPPORT' && input.conversation.target === 'CHAT',
                'bg-internal-message rounded-tr-none': input.conversation.target === 'INTERNAL',
                'bg-customer-message rounded-tl-none': input.conversation.sender === 'CUSTOMER',
            }">
                <h2 class="text-left text-sm font-semibold ">{{ sender }}:</h2>
                <div
                    class="w-full mb-2"
                    :class="{ hidden: imageSrc === undefined }"
                >
                    <img
                        :src="imageSrc"
                        class="bg-cover max-w-md h-auto rounded-xl mix-blend-normal"
                        alt="Imagem enviada na conversa"
                    />
                </div>
                <p class="text-left text-xs font-normal text-wrap break-words">{{ text }}</p>
                <p class="text-right text-xs font-normal pt-2 text-gray-500">{{ getFormattedDateTime() }}</p>
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
import { computed, defineProps, onMounted, ref } from 'vue';
import { Conversation, ImageSourceChoice } from '@/http/customerSession';
import { set } from '@vueuse/core';
import _ from 'lodash';
import { getBlobById } from '@/http/blob';
import { useStore } from 'vuex';
import { GET_CREDENTIALS } from '@/store';
import { validateBase64 } from '@/lib/formValidation';

export interface ChatSessionInput {
    displayName: string;
    conversation: Conversation;
}

const text = ref<string | undefined>();

const setText = (input: ChatSessionInput) => {
    if (input.conversation.sender === 'BOT') {
        if (input.conversation.workflowTask == undefined) return;
        set(text, input.conversation.workflowTask.type === 'image' ?
            input.conversation.workflowTask.caption ?? ''
            : input.conversation.workflowTask.content ?? 'Esta mensagem não está disponível'
        );
        return;
    }
    if (input.conversation.message?.text == undefined) return;
    set(text, input.conversation.message?.text);
};

const props = defineProps<{ input: ChatSessionInput }>();

const sender = computed(() => {
    if (props.input.conversation.sender === 'BOT') {
        return 'Chatbot';
    }
    if (props.input.conversation.sender === 'CUSTOMER') {
        return props.input.displayName;
    }
    return 'Atendente';
});

const isDateOlderThan24h = (date: Date) => {
    const now = new Date();
    const dateToCompare = new Date(date);
    const difference = now.getTime() - dateToCompare.getTime();
    const _24_HOURS_IN_MS = 24 * 60 * 60 * 1000;
    return difference > _24_HOURS_IN_MS;
};

const getFormattedTime = (date: Date) => {
    return `${_.padStart(date.getHours().toString(), 2, '0')}:${_.padStart(date.getMinutes().toString(), 2, '0')}`;
};

const getFormattedDateTime = () => {
    let date = new Date(props.input.conversation.dateTime);
    if (isDateOlderThan24h(date)) {
        return `${date.toLocaleDateString()} às ${getFormattedTime(date)}`;
    }
    return getFormattedTime(date);
};

const imageSrc = ref<string | undefined>();
const imageContentType = ref<string | undefined>();
const imageSource = ref<ImageSourceChoice | undefined>();

const { getters } = useStore();

const setImage = async (conversation: Conversation) => {
    if (conversation.message?.imageContent == undefined) return;
    let src: string | undefined;
    let source: ImageSourceChoice | undefined;
    if (conversation.sender === 'CUSTOMER') {
        src = conversation.message?.imageContent;
    } else if (conversation.sender === 'BOT') {
        if (conversation.workflowTask?.type !== 'image') return;
        source = conversation.workflowTask?.imageSource;
        src = conversation.workflowTask?.content;
    } else {
        src = conversation.message.imageContent;
        source = conversation.message.imageSourceChoice;
    }
    if (source !== 'URL') {
        const blob = await getBlobById(src, getters[GET_CREDENTIALS]);
        if (!validateBase64(blob.base64Encoded)) return;
        src = `data:${blob.contentType?.toLowerCase()};charset=utf-8;base64, ${blob.base64Encoded}`;
        set(imageContentType, blob.contentType);
        set(imageSource, 'BASE64');
    }
    set(imageSrc, src);
};

onMounted(async () => {
    setText(props.input);
    setImage(props.input.conversation);
});
</script>
