<template>
  <Dialog v-model:open="open">
    <DialogTrigger>
      <slot />
    </DialogTrigger>
    <DialogContent>
      <DialogHeader class="flex flex-row justify-between items-center">
        <DialogTitle>
          Configurações da Tarefa
        </DialogTitle>
        <DialogClose>
          <Button
            variant="ghost"
            class="px-1.5"
            @click="open = false"
          >
            <CircleX class="text-gray-600" />
          </Button>
        </DialogClose>
      </DialogHeader>
      <Separator
        class="bg-gray-200"
        orientation="horizontal"
      />
      <Label
        class="font-semibold"
        for="content"
      >Conteúdo:</Label>
      <Textarea
        id="content"
        class="resize-none bg-white"
        v-model="workflowTask.content"
      />
      <div class="items-center flex space-x-2">
        <Label
          for="footer"
          class="font-semibold"
        >Rodapé:</Label>
        <Input
          id="footer"
          v-model="workflowTask.footer"
        />
      </div>
      <div class="items-center flex space-x-2">
        <Label
          for="buttonText"
          class="font-semibold w-36"
        >Texto do botão:</Label>
        <Input
          id="buttonText"
          v-model="workflowTask.buttonText"
          maxlength="20"
        />
      </div>
      <Separator
        class="bg-gray-200"
        orientation="horizontal"
      />
      <div class="items-center flex space-x-2">
        <Checkbox
          id="shouldWaitReply"
          v-model:checked="workflowTask.shouldWaitReply"
        />
        <Label
          for="shouldWaitReply"
          class="font-semibold"
        >Salvar Resposta</Label>
        <Separator
          class="bg-gray-200"
          orientation="vertical"
        />
        <Label
          for="userAttribute"
          class="w-32 font-semibold"
          :class="{
            'text-muted': !workflowTask.shouldWaitReply
          }"
        >Salvar em:</Label>
        <Input
          id="userAttribute"
          v-model="workflowTask.userAttribute"
          :disabled="!workflowTask.shouldWaitReply"
        />
      </div>
      <DialogFooter>
        <Button
          type="button"
          @click="() => open = false"
          variant="ghost"
          class="py-0.5"
        >
          Cancelar
        </Button>
        <Button
          variant="default"
          @click="() => open = false"
          class="px-4"
          disabled
        >Salvar</Button>
      </DialogFooter>
    </DialogContent>
  </Dialog>
</template>

<script setup lang="ts">
import { defineModel, PropType } from 'vue';
import Dialog from '../ui/dialog/Dialog.vue';
import DialogContent from '../ui/dialog/DialogContent.vue';
import DialogHeader from '../ui/dialog/DialogHeader.vue';
import DialogTitle from '../ui/dialog/DialogTitle.vue';
import DialogTrigger from '../ui/dialog/DialogTrigger.vue';
import { WorkflowTask } from '@/http/workflowTask';
import Input from '../ui/input/Input.vue';
import Label from '../ui/label/Label.vue';
import Checkbox from '../ui/checkbox/Checkbox.vue';
import Separator from '../ui/separator/Separator.vue';
import Textarea from '../ui/textarea/Textarea.vue';
import DialogFooter from '../ui/dialog/DialogFooter.vue';
import Button from '../ui/button/Button.vue';
import DialogClose from '../ui/dialog/DialogClose.vue';
import { CircleX } from 'lucide-vue-next';

const workflowTask = defineModel('workflowTask', {
  type: Object as PropType<WorkflowTask>,
  required: true,
});

const open = defineModel({
  required: false,
  default: false
});
</script>