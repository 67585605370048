export const validateEmail = (email?: string) => {
  return String(email ?? '')
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
};


export const validatePassword = (value?: string): boolean => (value?.length ?? 0) >= 6;

export const validateBase64 = (value?: unknown): boolean => {
  try {
    window.atob(value as string);
    return true;
  } catch (e) {
    return false;
  }
};