export const BASE_URL = `${process.env.VUE_APP_API_URL}/api/v1`;

export interface SpringError {
  timestamp: Date;
  status: number;
  error: string;
  message: string;
  path: string;
}

export const CODE_TO_MESSAGE: Record<string, string> = {
};

export class ApiError extends Error {
  springError?: SpringError;
  statusCode: number;
  constructor(statusCode: number, message?: string, springError?: SpringError) {
    super(message);
    this.statusCode = statusCode;
    this.springError = springError;
    this.name = this.constructor.name;
  }

  getPtBrErrorMessage(): string | undefined {
    return CODE_TO_MESSAGE[this.springError?.message ?? ''];
  }
}

export class AuthenticationError extends ApiError {
  constructor(message?: string) {
    super(401, message);
  }
}

export const throwCorrespondentApiErrorIfNecessary = async (response: Response) => {
  const responseStatus = Number(response.status);
  if (responseStatus == 401) {
    throw new AuthenticationError();
  } else if (responseStatus >= 400) {
    throw new ApiError(responseStatus, await response.json());
  }
};
