<template>
  <div
    class="flex flex-col w-60 min-h-36 rounded-2xl bg-white shadow-lg shadow-gray-500 pt-4 pb-7 px-4 select-none"
  >
    <div class="flex flex-row justify-start pl-2 pr-2 pb-3 gap-2">
      <WorkflowTaskIcon
        :workflowTaskType="props.data.type"
        :workflowTaskSubtype="props.data.interactiveSubtype"
      />
      <p class="capitalize">
        {{ props.data.type.toLocaleLowerCase() }}
      </p>
    </div>
    <Textarea
      class="rounded-xl bg-white resize-none"
      placeholder="Digite sua mensagem..."
      name="mensagem"
      rows="4"
      v-model="messageTextContent"
      :readonly="true"
    ></Textarea>
  </div>
  <Handle
    :id="`${props.data.id}_R`"
    type="target"
    :position="Position.Right"
  />
  <Handle
    :id="`${props.data.id}_L`"
    type="source"
    :position="Position.Left"
  />
  <Handle
    :id="`${props.data.id}_T`"
    type="target"
    :position="Position.Top"
  />
  <Handle
    :id="`${props.data.id}_B`"
    type="source"
    :position="Position.Bottom"
  />
</template>

<script setup lang="ts">
import { defineProps, ref } from 'vue';
import { WorkflowTask } from '@/http/workflowTask';
import { Handle, NodeProps, Position } from '@vue-flow/core';
import { Textarea } from '../ui/textarea';
import WorkflowTaskIcon from './WorkflowTaskIcon.vue';

const props = defineProps<NodeProps<WorkflowTask>>();
const messageTextContent = ref(props.data.content);

</script>