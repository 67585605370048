/* eslint-disable @typescript-eslint/no-unused-vars */
import { AuthenticationError } from '@/http/api';
import { CustomerPatchPartnerFieldRequest, customerPatchPartnerFieldRequest, deletePartnerAttributes } from '@/http/customer';
import { answerCall, CustomerSession, CustomerSessionPageableRequest, getSessionById, getSessions, PartnerProviderType, sendMessage, setLastRead, SendTextMessageRequest, getPartner } from '@/http/customerSession';
import { APICredentials, BasicCredentials, loginForJwtWithBasicCredentials } from '@/http/login';
import { deletePartnerProvider, getPartnerProvider, getPartnerProviders, PartnerProvider, PartnerProviderPatchRequest, patchPartnerProvider, postPartnerProvider, PostPartnerProviderPayload } from '@/http/partnerProvider';
import { Partner } from "@/models/partner";
import { createStore } from 'vuex';

export const SET_CREDENTIALS = 'SET_CREDENTIALS';
export const SET_WHO_AM_I = 'SET_WHO_AM_I';
export const CLEAR_AUTH = 'CLEAR_AUTH';

export const SET_SESSION_TO_MYSELF = 'SET_SESSION_TO_MYSELF';
export const AUTHENTICATE = 'AUTHENTICATE';
export const ANSWER_CALL = 'ANSWER_CALL';
export const GET_PARTNER_PROVIDERS = 'GET_PARTNER_PROVIDERS';

export const APPEND_SESSIONS = 'APPEND_SESSIONS';
export const APPEND_PARTNER_PROVIDERS = 'APPEND_PARTNER_PROVIDERS';
export const MARK_READ = 'MARK_READ';
export const SEND_MESSAGE = 'SEND_MESSAGE';
export interface SendMessageStorePayload {
  sessionId: string;
  request: SendTextMessageRequest;
}
export const RETRIEVE_SESSION = 'RETRIEVE_SESSION';
export const RETRIEVE_SESSIONS = 'RETRIEVE_SESSIONS';
export interface RetrieveSessionParameters {
  page?: number;
  itemsPerPage?: number;
  mine?: boolean;
}
export const SAVE_CUSTOMER_FIELDS = 'SAVE_CUSTOMER_FIELDS';
export const PATCH_CUSTOMER_FIELD = 'PATCH_CUSTOMER_FIELD';
export const DELETE_CUSTOMER_ATTRIBUTES = 'DELETE_CUSTOMER_ATTRIBUTES';
export interface DeletePartnerAttributes {
  customerId: string;
  customerAttribute: string;
}
export const RETRIEVE_UNTIL_LAST_PAGE = 'RETRIEVE_UNTIL_LAST_PAGE';
export const DEFAULT_ITEMS_PER_PAGE = 10;
export const RETRIEVE_PARTNER_PROVIDERS = 'RETRIEVE_PARTNER_PROVIDERS';
export const RETRIEVE_PARTNER_PROVIDER = 'RETRIEVE_PARTNER_PROVIDER';
export const POST_PARTNER_PROVIDERS = 'POST_PARTNER_PROVIDERS';
export const DELETE_PARTNER_PROVIDERS = 'DELETE_PARTNER_PROVIDERS';

export const SORT_PARTNER_PROVIDERS = 'SORT_PARTNER_PROVIDERS';

export const GET_SESSIONS = 'GET_SESSIONS';
export const GET_CURRENT_WAITING_SESSIONS_PAGE = 'GET_CURRENT_WAITING_SESSIONS_PAGE';
export const GET_WHO_AM_I = 'GET_WHO_AM_I';
export const GET_CREDENTIALS = 'GET_CREDENTIALS';
export const GET_PARTNER_ID = 'GET_PARTNER_ID';

export const QUERY_FOR_OLD_SESSIONS = "QUERY_FOR_OLD_SESSIONS";

export const STORAGE_CREDENTIALS = 'CREDENTIALS';
export const AUTH_USER_ID = 'AUTH_USER_ID';
export const PARTNER_ID = 'PARTNER_ID';

export const PATCH_PARTNER_PROVIDERS = 'PATCH_PARTNER_PROVIDERS';

export const HAS_ALL_AUTH = 'HAS_ALL_AUTH';

export const RETRIEVE_PARTNER = 'RETRIEVE_PARTNER';
export const PARTNER = 'PARTNER';
export const PARTNER_SESSION_TIMEOUT = 'PARTNER_SESSION_TIMEOUT';

const createBaseStoreState = (): ConverzaStore => ({
  credentials: localStorage.getItem(STORAGE_CREDENTIALS) ?? undefined,
  sessions: {},
  partnerId: localStorage.getItem(PARTNER_ID) ?? undefined,
  partnerProviders: {},
  partnerProvidersOrder: {},
  currentMySessionsPage: 0,
  currentWaitingSessionsPage: 0,
  whoAmI: localStorage.getItem(AUTH_USER_ID) ?? undefined,
  partner: localStorage.getItem(PARTNER) !== null ? JSON.parse(localStorage.getItem(PARTNER) ?? '') : undefined,
});

export interface ConverzaStore {
  credentials?: APICredentials;
  partnerId?: string;
  whoAmI?: string;
  sessions: { [key: string]: CustomerSession };
  partnerProviders: { [key: string]: PartnerProvider };
  partnerProvidersOrder: Record<string, number>;
  currentMySessionsPage: number;
  currentWaitingSessionsPage: number;
  partner?: Partner,
}
export default createStore<ConverzaStore>({
  state: createBaseStoreState(),
  getters: {
    [HAS_ALL_AUTH]: (_, getters): boolean => {
      return getters[GET_PARTNER_ID] !== undefined && getters[GET_CREDENTIALS] !== undefined && getters[GET_WHO_AM_I] !== undefined;
    },
    [GET_SESSIONS]: (state): { [key: string]: CustomerSession } => {
      return state.sessions;
    },
    [GET_PARTNER_PROVIDERS]: (state): { [key: string]: PartnerProvider } => {
      return state.partnerProviders;
    },
    [GET_WHO_AM_I]: (state): string | undefined => {
      return state.whoAmI;
    },
    [GET_CREDENTIALS]: (state) => {
      return state.credentials ?? localStorage.getItem(STORAGE_CREDENTIALS) ?? undefined;
    },
    [GET_PARTNER_ID]: (state) => {
      return state.partnerId ?? localStorage.getItem(PARTNER_ID) ?? undefined;
    },
    [PARTNER_ID]: (state): string | undefined => {
      let partnerId = state.partnerId;
      if (partnerId === undefined) {
        partnerId = state.partnerId = localStorage.getItem(PARTNER_ID) ?? undefined;
      }
      return partnerId;
    },
    [STORAGE_CREDENTIALS]: (state): APICredentials | undefined => {
      let credentials = state.credentials;
      if (credentials === undefined) {
        credentials = state.credentials = localStorage.getItem(STORAGE_CREDENTIALS) ?? undefined;
      }
      return credentials;
    },
    [AUTH_USER_ID]: (state): string | undefined => {
      let authUserId = state.whoAmI;
      if (authUserId === undefined) {
        authUserId = state.whoAmI = localStorage.getItem(AUTH_USER_ID) ?? undefined;
      }
      return authUserId;
    },
    [GET_CURRENT_WAITING_SESSIONS_PAGE]: (state): number => {
      return state.currentWaitingSessionsPage;
    },
    [PARTNER_SESSION_TIMEOUT]: (state): number | undefined => {
      let partner = state.partner;
      if (partner === undefined) {
        partner = state.partner = localStorage.getItem(PARTNER) !== null ? JSON.parse(localStorage.getItem(PARTNER) ?? '') : undefined;
      }
      return state.partner?.sessionLimitTimeout;
    }
  },
  mutations: {
    [CLEAR_AUTH]: (state) => {
      localStorage.removeItem(AUTH_USER_ID);
      localStorage.removeItem(STORAGE_CREDENTIALS);
      localStorage.removeItem(PARTNER_ID);
      localStorage.removeItem(PARTNER);

      Object.assign(state, createBaseStoreState());
    },
    [SET_CREDENTIALS]: (state, payload: APICredentials) => {
      state.credentials = payload;
      if (typeof payload === 'string') localStorage.setItem(STORAGE_CREDENTIALS, payload);
    },
    [SET_WHO_AM_I]: (state, payload: string) => {
      state.whoAmI = payload;
      localStorage.setItem(AUTH_USER_ID, payload);
    },
    [APPEND_SESSIONS]: (state, payload: CustomerSession[]) => {
      payload.forEach(newSession => {
        state.sessions[newSession.id] = newSession;
      });
    },
    [APPEND_PARTNER_PROVIDERS]: (state, payload: PartnerProvider[]) => {
      payload.forEach(newPartnerProvider => {
        state.partnerProviders[newPartnerProvider.id] = newPartnerProvider;
      });
    },
    [SET_SESSION_TO_MYSELF]: (state, payload: string) => {
      state.sessions[payload].whoIsChatting = state.whoAmI;
    },
    [SORT_PARTNER_PROVIDERS]: (state) => {
      const entries = Object.entries(state.partnerProviders);
      if (entries.length <= 1) return;

      state.partnerProvidersOrder = {};
      const order: Record<PartnerProviderType, number> = {
        INSTAGRAM: 1,
        WHATSAPP: 1
      };

      entries
        .sort(([_, partnerProvider], [__, partnerProvider2]) => {
          if (partnerProvider.createdDate == undefined || partnerProvider2.createdDate == undefined) { return 0; }
          return (new Date(partnerProvider.createdDate)).getTime() - (new Date(partnerProvider2.createdDate)).getTime();
        })
        .forEach(([_, partnerProvider]) => {
          if (partnerProvider.partnerProviderType == undefined) { return; }
          state.partnerProvidersOrder[partnerProvider.id] = order[partnerProvider.partnerProviderType];
          order[partnerProvider.partnerProviderType] += 1;
        });
    },
    [PARTNER_SESSION_TIMEOUT]: (state, sessionLimitTimeout: number) => {
      if (state.partner === undefined) { return; }
      state.partner.sessionLimitTimeout = sessionLimitTimeout;
      localStorage.setItem(PARTNER, JSON.stringify(state.partner));
    },
    [PARTNER_ID]: (state, partnerId) => {
      state.partnerId = partnerId;
      localStorage.setItem(PARTNER_ID, partnerId);
    },
    [PARTNER]: (state, partner: Partner) => {
      state.partner = partner;
      localStorage.setItem(PARTNER, JSON.stringify(partner));
    },
    [SAVE_CUSTOMER_FIELDS]: (state, payload: CustomerPatchPartnerFieldRequest) => {
      Object.keys(state.sessions).forEach(sessionId => {
        if (state.sessions[sessionId]?.customer == undefined) { return; }

        if (state.sessions[sessionId].customerKey === payload.customerKey) {
          if (payload.notes !== undefined) {
            state.sessions[sessionId].customer.notes = payload.notes;
          }
          Object.keys(payload.partnerAttributes ?? {}).forEach(key => {
            if (payload?.partnerAttributes?.[key] === undefined) { return; }
            state.sessions[sessionId].customer.partnerAttributes[key] = payload.partnerAttributes[key];
          });
        }
      });
    },
  },
  actions: {
    [AUTHENTICATE]: async ({ commit }, payload: BasicCredentials) => {
      const authUserLoginResponse = await loginForJwtWithBasicCredentials(payload);
      const { token, authUserId, partnerId } = authUserLoginResponse;

      commit(SET_CREDENTIALS, token);
      commit(SET_WHO_AM_I, authUserId);

      if (partnerId != null) {
        commit(PARTNER_ID, partnerId);
      }
    },
    [ANSWER_CALL]: async ({ state, commit, dispatch }, payload: string) => {
      if (state.credentials === undefined || state.whoAmI === undefined) {
        throw new Error('No credentials provided');
      }
      await answerCall(payload, state.whoAmI, state.credentials);
      commit(SET_SESSION_TO_MYSELF, payload);
      await dispatch(RETRIEVE_UNTIL_LAST_PAGE);
    },
    [RETRIEVE_UNTIL_LAST_PAGE]: async ({ state, dispatch }) => {
      await dispatch(RETRIEVE_SESSIONS, {
        itemsPerPage: (state.currentWaitingSessionsPage + 1) * DEFAULT_ITEMS_PER_PAGE,
        page: 0,
      });
    },
    [RETRIEVE_SESSIONS]: async ({ commit, state }, payload?: RetrieveSessionParameters) => {
      const pagesKey = payload?.mine === true ? 'currentMySessionsPage' : 'currentWaitingSessionsPage';

      const filter: CustomerSessionPageableRequest = {
        page: payload?.page !== undefined ? payload?.page : state[pagesKey],
        size: payload?.itemsPerPage ?? DEFAULT_ITEMS_PER_PAGE,
        status: 'OPEN_SUPPORT',
        isAlreadyChatting: payload?.mine ?? false,
        whoIsChatting: payload?.mine === true ? state.whoAmI : undefined,
        considerTimeout: true
      };

      const credentials = (state.credentials);
      if (credentials === undefined) {
        throw new Error("No stored credentials");
      }

      const sessionsPage = await getSessions(filter, credentials);
      const sessions = sessionsPage.content;
      if (sessionsPage.last === false && payload?.page === undefined) {
        state[pagesKey] += 1;
      }
      commit(APPEND_SESSIONS, sessions);
      return sessionsPage.last;
    },
    [RETRIEVE_SESSION]: async ({ commit, state }, sessionId: string) => {
      const credentials = (state.credentials);
      if (credentials === undefined) {
        throw new Error("No stored credentials");
      }
      const customerSession = await getSessionById(sessionId, credentials);
      commit(APPEND_SESSIONS, [customerSession]);
      return customerSession;
    },
    [MARK_READ]: async ({ state }, sessionId: string) => {
      const credentials = state.credentials;

      if (credentials == undefined) {
        throw new Error("Invalid Credentials");
      }

      await setLastRead(sessionId, credentials);
    },
    [SEND_MESSAGE]: async ({ getters, dispatch }, payload: SendMessageStorePayload) => {
      if (getters[STORAGE_CREDENTIALS] === undefined) { throw new Error("No stored credentials"); }
      await sendMessage(payload.sessionId, payload.request, getters[STORAGE_CREDENTIALS]);
      await dispatch(RETRIEVE_SESSION, payload.sessionId);
    },
    [PATCH_CUSTOMER_FIELD]: async ({ state, commit }, payload: CustomerPatchPartnerFieldRequest) => {
      const credentials = state.credentials;
      if (credentials === undefined) throw new Error("No stored credentials");
      await customerPatchPartnerFieldRequest(payload, credentials);
      if (payload.notes !== undefined) {
        commit(SAVE_CUSTOMER_FIELDS, payload);
      }
    },
    [DELETE_CUSTOMER_ATTRIBUTES]: async ({ state }, payload: DeletePartnerAttributes) => {
      const credentials = state.credentials;
      if (credentials === undefined) throw new Error("No stored credentials");
      await deletePartnerAttributes(payload.customerId, payload.customerAttribute, credentials);
    },
    [RETRIEVE_PARTNER_PROVIDER]: async ({ commit, state }, partnerProviderId: string) => {
      const credentials = state.credentials;
      if (credentials === undefined) throw new Error("No stored credentials");
      const partnerProvider = await getPartnerProvider(partnerProviderId, credentials);
      commit(APPEND_PARTNER_PROVIDERS, [partnerProvider]);
      commit(SORT_PARTNER_PROVIDERS);
    },
    [RETRIEVE_PARTNER_PROVIDERS]: async ({ commit, state }) => {
      const partnerId = localStorage.getItem(PARTNER_ID);
      const credentials = state.credentials;
      if (credentials === undefined || partnerId === null) throw new Error("No stored credentials");
      let partnerProviders: PartnerProvider[];
      try {
        partnerProviders = await getPartnerProviders(partnerId, credentials);
      } catch (err) {
        if (err instanceof AuthenticationError) {
          commit(CLEAR_AUTH);
          return;
        }
        throw err;
      }
      commit(APPEND_PARTNER_PROVIDERS, partnerProviders);
      commit(SORT_PARTNER_PROVIDERS);
    },
    [PATCH_PARTNER_PROVIDERS]: async ({ state }, payload: PartnerProviderPatchRequest) => {
      const credentials = state.credentials;
      if (credentials === undefined) throw new Error("No stored credentials");
      await patchPartnerProvider(payload, credentials);
    },
    [POST_PARTNER_PROVIDERS]: async ({ commit, state }, payload: PostPartnerProviderPayload) => {
      const credentials = state.credentials;
      if (credentials === undefined) throw new Error("No stored credentials");
      const savedPartnerProvider = await postPartnerProvider(payload, credentials);
      commit(APPEND_PARTNER_PROVIDERS, [savedPartnerProvider]);
    },
    [DELETE_PARTNER_PROVIDERS]: async ({ state }, partnerProviderId: string) => {
      const credentials = state.credentials;
      if (credentials === undefined) throw new Error("No stored credentials");
      await deletePartnerProvider(partnerProviderId, credentials);
      delete state.partnerProviders[partnerProviderId];
    },
    [RETRIEVE_PARTNER]: async ({ state, commit }, partnerId?: string) => {
      const credentials = state.credentials;
      if (credentials === undefined) throw new AuthenticationError("No stored credentials");
      const pathPartnerId = (partnerId ?? state.partnerId);
      if (pathPartnerId == undefined) throw new AuthenticationError("No referenced partnerId");
      const partnerResponse = await getPartner(pathPartnerId, credentials);
      commit(PARTNER, partnerResponse);
      return partnerResponse;
    }
  },
}); 
