<template>
  <Tabs
    v-model="currentTab"
    class="min-w-[360px] grid max-lg:grid-rows-9 lg:grid-rows-12 grid-cols-1"
  >
    <div class="row-span-1 col-span-1 flex flex-row w-full items-center border-b-2 border-gray-200 pl-4">
      <div class="relative h-full w-full max-w-sm flex items-center justify-center">
        <Input
          class="pl-4 max-h-[85%]"
          id="search"
          type="text"
          placeholder="Pesquisar"
        />
        <span class="absolute right-0 inset-y-0 flex items-center justify-center px-2">
          <Search class="size-6 text-muted-foreground" />
        </span>
      </div>
      <div class="ml-4 flex flex-row items-center justify-center gap-4">
        <Filter />
        <Switch class="mr-3" />
      </div>
    </div>
    <TabsList class="row-span-1 col-span-1 flex bg-transparent text-nowrap max-w-full">
      <TabsTrigger
        value="aguardando"
        class="flex-1 text-center text-black-700 font-semibold text-sm leading-6"
        @click="() => toggleCurrentTab('aguardando')"
      >
        Aguardando
      </TabsTrigger>
      <TabsTrigger
        value="atendendo"
        class="flex-1 text-center text-black-700 border-gray-2 font-semibold text-sm leading-6"
        @click="() => toggleCurrentTab('atendendo')"
      >
        Atendendo
      </TabsTrigger>
    </TabsList>
    <TabsContent
      class="max-lg:row-span-7 lg:row-span-10 col-span-1"
      value="aguardando"
    >
      <ScrollableSessions
        :sessions="waitingSessions"
        @TOGGLE_TAB="() => toggleCurrentTab('atendendo')"
        :currentTab="currentTab"
      />
    </TabsContent>
    <TabsContent
      class="row-span-7 col-span-1"
      value="atendendo"
    >
      <ScrollableSessions
        :sessions="mySessions"
        :mine="true"
        :currentTab="currentTab"
      />
    </TabsContent>
  </Tabs>
</template>

<script setup lang="ts">
import { Switch } from '@/components/ui/switch';
import { Tabs, TabsContent, TabsList, TabsTrigger } from '@/components/ui/tabs';
import { Filter, Search } from 'lucide-vue-next';
import { Input } from '@/components/ui/input';
import { useStore } from 'vuex';
import { computed, ref } from 'vue';
import { GET_SESSIONS, GET_WHO_AM_I } from '@/store';
import { CustomerSession, isSessionExpired } from '@/http/customerSession';
import ScrollableSessions from '@/components/converza-sessions/tabs/ScrollableSessions.vue';

type TabsNames = "aguardando" | "atendendo";
const currentTab = ref<TabsNames>("aguardando");

const store = useStore();

const sortSessions = (sessions: CustomerSession[]) => {
  return sessions.sort((a: CustomerSession, b: CustomerSession) =>
    (new Date(b.conversation.at(-1)?.dateTime ?? Date.now()).getTime()) - (new Date(a.conversation.at(-1)?.dateTime ?? Date.now()).getTime())
  );
};

const sessions = computed<CustomerSession[]>(() => Object.values(store.getters[GET_SESSIONS]));
const waitingSessions = computed<CustomerSession[]>(() => sortSessions(
  sessions.value.filter((session: CustomerSession) => session.whoIsChatting === null && session.status === 'OPEN_SUPPORT' && !isSessionExpired(session)))
);
const mySessions = computed<CustomerSession[]>(() => sortSessions(sessions.value
  .filter((session: CustomerSession) => session.whoIsChatting === store.getters[GET_WHO_AM_I] && session.status !== 'FINISHED' && !isSessionExpired(session))
));

const toggleCurrentTab = (value: TabsNames) => {
  currentTab.value = value;
};
</script>
