<template>
  <div class="col-span-2 flex flex-col max-w-1/6 h-full items-start justify-end gap-1.5 p-4">
    <Label :class="{ 'text-red-500 border-red-500': showSessionLimitTimeoutError }">
      Tempo de Expiração da Sessão (em horas)</Label>
    <div class="flex flex-row gap-2">
      <Input
        type="number"
        :class="{
          'w-16': true,
          'ring-red-500 border-red-500': showSessionLimitTimeoutError
        }"
        v-model="sessionLimitTimeout"
      />
      <Button
        variant="default"
        :disabled="showSessionLimitTimeoutError"
        @click="updateTimeLimit"
        class="px-2"
        :class="{
          'bg-success': showSessionLimitTimeoutSuccess
        }"
      >
        <LoaderCircle
          class="animate-spin"
          v-if="showSessionLimitTimeoutProgress"
        />
        <CircleCheck
          :class="{ 'animate-pulse': showSessionLimitTimeoutSuccess }"
          v-else
        />
      </Button>
    </div>
    <p
      class="text-red-500 text-xs"
      :class="{ invisible: !showSessionLimitTimeoutError }"
    >
      A sessão deve ser de pelo menos 1h no máximo 24h.
    </p>
  </div>
</template>

<script setup lang="ts">
import { onBeforeMount, ref, watch } from 'vue';
import Input from '@/components/ui/input/Input.vue';
import Label from '@/components/ui/label/Label.vue';
import { patchPartnerSessionInactivityTimeout } from '@/http/partner';
import { useStore } from 'vuex';
import { STORAGE_CREDENTIALS, PARTNER_ID, PARTNER_SESSION_TIMEOUT } from '@/store';
import Button from '@/components/ui/button/Button.vue';
import { CircleCheck, LoaderCircle } from 'lucide-vue-next';
import { getSessionTimeoutLimit } from '@/http/customerSession';
import { set } from '@vueuse/core';

const DEFAULT_TIMEOUT = 24;
const sessionLimitTimeout = ref();
const isSessionTimeoutLimitValid = () => sessionLimitTimeout.value > 0 && sessionLimitTimeout.value <= 24 && Math.round(sessionLimitTimeout.value) === sessionLimitTimeout.value;
const showSessionLimitTimeoutError = ref(false);
watch(isSessionTimeoutLimitValid, () => {
  showSessionLimitTimeoutError.value = !isSessionTimeoutLimitValid();
});
const showSessionLimitTimeoutProgress = ref(false);
const showSessionLimitTimeoutSuccess = ref(false);

const store = useStore();

const updateTimeLimit = async () => {
  if (!isSessionTimeoutLimitValid()) {
    return;
  }
  set(showSessionLimitTimeoutProgress, true);
  try {
    await patchPartnerSessionInactivityTimeout(store.getters[PARTNER_ID], sessionLimitTimeout.value, store.getters[STORAGE_CREDENTIALS]);
    store.commit(PARTNER_SESSION_TIMEOUT, sessionLimitTimeout.value);

    setTimeout(() => {
      set(showSessionLimitTimeoutProgress, false);
      set(showSessionLimitTimeoutSuccess, true);

      setTimeout(() => {
        set(showSessionLimitTimeoutSuccess, false);
      }, 500);
    }, 500);
  } catch (err) {
    set(showSessionLimitTimeoutSuccess, false);
    set(showSessionLimitTimeoutError, true);
  }
};

onBeforeMount(async () => {
  sessionLimitTimeout.value = store.getters[PARTNER_SESSION_TIMEOUT]
    ?? await getSessionTimeoutLimit(store.getters[PARTNER_ID], store.getters[STORAGE_CREDENTIALS])
    ?? DEFAULT_TIMEOUT;
});

</script>