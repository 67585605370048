<script setup lang="ts">
import HeaderComponent from '@/components/HeaderComponent.vue';
import ConverzaSessionsTabsComponent from '@/components/converza-sessions/tabs/ConverzaSessionsTabs.vue';
import { RETRIEVE_PARTNER_PROVIDERS, RETRIEVE_SESSIONS } from '@/store';
import { onMounted } from 'vue';
import { useStore } from 'vuex';

const store = useStore();

onMounted(async () => {
  await store.dispatch(RETRIEVE_PARTNER_PROVIDERS);
  await store.dispatch(RETRIEVE_SESSIONS);
  await store.dispatch(RETRIEVE_SESSIONS, { mine: true });
});
</script>

<template>
  <div class="flex flex-col flex-nowrap items-stretch h-screen max-w-full">
    <HeaderComponent
      class="flex-1 flex-grow-0"
      title="Atendimento"
    />
    <div class="flex-auto flex flex-row items-stretch max-w-full overflow-x-auto">
      <ConverzaSessionsTabsComponent class="flex-1 flex-grow-[1]" />
      <router-view class="flex-auto flex-grow-[2]"></router-view>
    </div>
  </div>
</template>
