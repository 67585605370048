<template>
  <div>
    <Input
      type="text"
      placeholder="Nome da Empresa"
      v-model="partnerName"
    />
    <ErrorForm :class="{ invisible: showPartnerNameError }">
      Escreva um nome de empresa válido
    </ErrorForm>

    <Input
      type="text"
      placeholder="CNPJ"
      v-model="cnpj"
    />
    <ErrorForm :class="{ invisible: showCnpjError }">
      Por favor, insira um CNPJ válido.
    </ErrorForm>

    <Select v-model="partnerField">
      <SelectTrigger>
        <SelectValue
          :class="{
            'text-gray-500': partnerField === undefined,
          }"
          placeholder="Área de Atuação"
        />
      </SelectTrigger>
      <SelectContent>
        <SelectGroup>
          <SelectItem
            v-for="availablePartnerField in Object.keys(AVAILABLE_PARTNER_FIELDS)"
            :key="availablePartnerField"
            :value="AVAILABLE_PARTNER_FIELDS[availablePartnerField as PARTNER_FIELD]"
          >
            {{ availablePartnerField }}
          </SelectItem>
        </SelectGroup>
      </SelectContent>
    </Select>
    <ErrorForm :class="{ invisible: showPartnerFieldError }">
      Selecione uma área de atuação.
    </ErrorForm>

    <Select v-model="partnerSize">
      <SelectTrigger>
        <SelectValue
          :class="{ 'text-gray-500': partnerSize === undefined }"
          placeholder="Porte da Empresa"
        />
      </SelectTrigger>
      <SelectContent>
        <SelectGroup>
          <SelectItem
            v-for="availablePartnerSize in Object.keys(AVAILABLE_PARTNER_SIZES)"
            :key="availablePartnerSize"
            :value="AVAILABLE_PARTNER_SIZES[availablePartnerSize as PARTNER_SIZE]"
          >
            {{ availablePartnerSize }}
          </SelectItem>
        </SelectGroup>
      </SelectContent>
    </Select>
    <ErrorForm :class="{ invisible: showPartnerSizeError }">
      Selecione um porte da empresa.
    </ErrorForm>

    <div class="flex items-center justify-center mt-1 w-full">
      <Button
        class="w-full text-white rounded-full"
        variant="default"
        type="submit"
        size="sm"
        @click="onSubmit"
      >
        Cadastrar
      </Button>
    </div>
    <p class="mt-4 text-xs text-gray-500">
      Já tinha uma conta?<a
        class="text-blue-500 cursor-pointer"
        @click="() => {
          store.commit(CLEAR_AUTH);

          $emit('TOGGLE_REGISTRATION');
        }"
      > Logar</a>
    </p>
  </div>
</template>

<script setup lang="ts">
import { computed, defineEmits, ref } from 'vue';
import Button from '@/components/ui/button/Button.vue';
import Input from '@/components/ui/input/Input.vue';
import SelectContent from '@/components/ui/select/SelectContent.vue';
import SelectGroup from '@/components/ui/select/SelectGroup.vue';
import SelectItem from '@/components/ui/select/SelectItem.vue';
import Select from '@/components/ui/select/Select.vue';
import SelectTrigger from '@/components/ui/select/SelectTrigger.vue';
import SelectValue from '@/components/ui/select/SelectValue.vue';
import ErrorForm from '@/components/auth/ErrorForm.vue';
import { submitPartnerCreationRequest } from '@/http/registration';
import { useStore } from 'vuex';
import { AuthenticationError } from '@/http/api';
import { CLEAR_AUTH, PARTNER_ID } from '@/store';
import { get } from '@vueuse/core';
import { useToast } from '@/components/ui/toast';
import { PartnerField, PartnerSize } from '@/models/partner';

const store = useStore();
const { toast } = useToast();

type PARTNER_SIZE = '1-5 Funcionários' | 'Acima de 11 Funcionários' | '6-10 Funcionários';
const AVAILABLE_PARTNER_SIZES: Record<PARTNER_SIZE, PartnerSize> = {
  '1-5 Funcionários': 'LARGE',
  '6-10 Funcionários': 'MEDIUM',
  'Acima de 11 Funcionários': 'SMALL',
};
type PARTNER_FIELD =
  | 'TI'
  | 'Finanças'
  | 'Moda'
  | 'Restaurante'
  | 'Logística'
  | 'Entrega de comida'
  | 'Agro';
const AVAILABLE_PARTNER_FIELDS: Record<PARTNER_FIELD, PartnerField> = {
  TI: 'IT',
  Finanças: 'FINANCES',
  Restaurante: 'RESTAURANTS',
  Moda: 'BEAUTY',
  Agro: 'AGRO',
  'Entrega de comida': 'FOOD_SUPPLY',
  Logística: 'LOGISTICS',
};

const hasEverSubmitted = ref(false);

const partnerName = ref<string | undefined>(undefined);
const validatePartnerName = (value?: string): boolean => {
  if (value === undefined) return false;
  return /.*/.test(value ?? '');
};
const showPartnerNameError = computed(() => validatePartnerName(partnerName.value) || (!hasEverSubmitted.value && partnerName.value === undefined));

const cnpj = ref<string | undefined>(undefined);
function validateCnpj(cnpj: string | undefined): boolean {
  if (!cnpj) return false;

  cnpj = cnpj.trim();
  if (/[^0-9.\-/]/.test(cnpj)) return false;

  cnpj = cnpj.replace(/[^\d]+/g, '');

  if (cnpj.length !== 14) return false;

  if (/^(\d)\1{13}$/.test(cnpj)) return false;

  const firstDigits = cnpj.substring(0, 12);
  let sum = 0;
  let pos = firstDigits.length - 7;

  for (let i = 0; i < 12; i++) {
    sum += Number(firstDigits[i]) * pos--;
    if (pos < 2) pos = 9;
  }

  let result = sum % 11 < 2 ? 0 : 11 - (sum % 11);
  if (result !== Number(cnpj[12])) return false;

  const firstTwelveDigits = cnpj.substring(0, 13);
  sum = 0;
  pos = firstTwelveDigits.length - 7;

  for (let i = 0; i < 13; i++) {
    sum += Number(firstTwelveDigits[i]) * pos--;
    if (pos < 2) pos = 9;
  }

  result = sum % 11 < 2 ? 0 : 11 - (sum % 11);
  return result === Number(cnpj[13]);
}
const showCnpjError = computed(() => validateCnpj(cnpj.value) || (!hasEverSubmitted.value && cnpj.value === undefined));

const partnerField = ref<PartnerField | undefined>(undefined);
const validatePartnerField = (value?: PartnerField): boolean => value !== undefined;
const showPartnerFieldError = computed(() => validatePartnerField(partnerField.value) || (!hasEverSubmitted.value && partnerField.value === undefined));

const partnerSize = ref<PartnerSize | undefined>(undefined);
const validatePartnerSize = (value?: PartnerSize): boolean => value !== undefined;
const showPartnerSizeError = computed(() => validatePartnerSize(partnerSize.value) || (!hasEverSubmitted.value && partnerSize.value === undefined));

const emit = defineEmits(['nextStep', 'TOGGLE_REGISTRATION']);
const onSubmit = async () => {
  validatePartnerName(partnerName.value);
  validateCnpj(cnpj.value);
  validatePartnerField(partnerField.value);
  validatePartnerSize(partnerSize.value);

  hasEverSubmitted.value = true;

  if (!(get(showPartnerNameError) && get(showCnpjError) && get(showPartnerFieldError) && get(showPartnerSizeError)) || partnerField.value === undefined || partnerSize.value === undefined || cnpj.value === undefined || partnerName.value === undefined) {
    return;
  }

  try {
    const partnerCreationResponse = await submitPartnerCreationRequest(
      {
        partnerName: partnerName.value,
        cnpj: cnpj.value,
        partnerField: partnerField.value,
        partnerSize: partnerSize.value,
      },
      store.state.credentials
    );
    store.commit(PARTNER_ID, partnerCreationResponse.id);
  } catch (err) {
    if (err instanceof AuthenticationError) {
      store.commit(CLEAR_AUTH);
      emit('TOGGLE_REGISTRATION');

      return;
    }
    toast({
      variant: 'destructive',
      title: 'Não foi possível criar sua empresa',
      description: 'Tente novamente com os dados corretos',
    });
    return;
  }
  emit('nextStep');
};
</script>
