<template>
  <div class="bg-primary w-52 h-16 rounded-3xl flex justify-center items-center">
    <CornerRightDown :size="20" color="#FFFFFF" /> 
    {{ props.data.content }}
  </div>
  <Handle
    :id="`${props.data.id}_B`"
    type="target"
    :position="Position.Bottom"
  />
</template>

<script setup lang="ts">
import { Handle, NodeProps, Position } from '@vue-flow/core';
import { CornerRightDown } from 'lucide-vue-next';
import { defineProps } from 'vue';

interface LinkIncomingProps {
  id: string;
  content: string;
}

const props = defineProps<NodeProps<LinkIncomingProps>>();
</script>