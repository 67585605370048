<template>
    <div
        class="overflow-y-auto max-h-full"
        @scroll="onInfiniteTabScroll"
    >
        <div id="conversations-wrapper">
            <ConverzaSessionResume
                class=""
                v-for="session of sessions"
                :key="session.id"
                :session="session"
                @TOGGLE_TO_ANSWER_CHAT="() => $emit('TOGGLE_TAB')"
            />
        </div>

        <EmptySessions :class="{
            hidden: isEmpty === false
        }" />
        <span
            :class="{
                invisible: isLoadingMore === false
            }"
            class="flex items-center justify-center px-4 py-2 text-gray-400 font-semibold opacity-50"
        >
            <svg
                class="animate-spin h-5 w-5 mr-2 text-gray-700"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
            >
                <circle
                    class="opacity-25"
                    cx="12"
                    cy="12"
                    r="10"
                    stroke="currentColor"
                    stroke-width="4"
                ></circle>
                <path
                    class="opacity-75"
                    fill="currentColor"
                    d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4z"
                ></path>
            </svg>
            Carregando mais Conversas
        </span>
    </div>
</template>

<script setup lang="ts">
import { CustomerSession } from '@/http/customerSession';
import { computed, defineProps, PropType, ref, defineEmits, onUnmounted } from 'vue';
import ConverzaSessionResume from './ConverzaSessionResume.vue';
import { set } from '@vueuse/core';
import { RETRIEVE_SESSIONS, RetrieveSessionParameters } from '@/store';
import { useStore } from 'vuex';
import EmptySessions from './EmptySessions.vue';


const hasReachedLastSession = ref<boolean | undefined>(undefined);
const canLoadMore = ref(true);
const isLoadingMore = ref(false);
const POLLING_INTERVAL_IN_SECONDS = 5;
const store = useStore();
defineEmits(['TOGGLE_TAB']);

const props = defineProps({
    sessions: {
        type: Object as PropType<CustomerSession[]>,
        required: true,
    },
    mine: {
        type: Boolean,
        required: false,
        default: false,
    },
    currentTab: {
        type: String,
        required: true
    }
});

const loadMoreSessions = async (parameters: RetrieveSessionParameters) => {
    const last = await store.dispatch(RETRIEVE_SESSIONS, parameters);

    set(hasReachedLastSession, last);
};

const tryLoadMore = async () => {
    set(canLoadMore, false);
    set(isLoadingMore, true);

    const filter = {
        mine: props.mine === true ? true : false,
        itemsPerPage: 10
    };

    await loadMoreSessions(filter);

    set(canLoadMore, true);

    setTimeout(() => {
        set(isLoadingMore, false);
    }, 500);
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const onInfiniteTabScroll = async (event: any) => {
    let isInfiniteScrolling = ((event?.target?.clientHeight ?? 0) + (event?.target?.scrollTop ?? 0)) >= (document.getElementById('conversations-wrapper')?.scrollHeight ?? 0);
    if (isInfiniteScrolling && canLoadMore.value === true && hasReachedLastSession.value !== true) {
        await tryLoadMore();
    }
};

const isEmpty = computed(() => {
    return (props.sessions.length ?? 0) === 0;
});

const poll = async () => {
    const filter = {
        page: 0,
        mine: props.mine === true ? true : false,
        itemsPerPage: 10,
    };
    await loadMoreSessions(filter);
};

const interval = setInterval(async () => await poll(), POLLING_INTERVAL_IN_SECONDS * 1000);

onUnmounted(() => clearInterval(interval));

</script>
<style>
@keyframes spin {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}
</style>