<template>
    <div class="flex flex-col border-b-2 border-gray-200 max-h-full overflow-y-auto pl-2">
        <div class="grid grid-rows-1 grid-cols-5 bg-white p-4 relative">
            <div class="col-span-1">
            </div>
            <div class="col-span-3 flex flex-col items-center justify-center text-black-700 mt-2">
                <Avatar size="md">
                    <AvatarImage
                        src="https://github.com/radix-vue.png"
                        alt="@radix-vue"
                    />
                    <AvatarFallback>CN</AvatarFallback>
                </Avatar>
            </div>
            <PartnerAttributes
                class="col-span-1"
                v-if="customerId !== undefined"
                :customerId="customerId"
                @updated-customer-name="(newName) => {
                    if (customer === undefined) return;
                    customer.partnerAttributes ??= {};
                    customer.partnerAttributes.name = newName;
                    $emit('updated-customer-name', newName);
                }"
            />
        </div>
        <div class="flex-1 flex flex-col">
            <div class="border-b-2 border-gray-200 pb-4 text-wrap flex flex-col">
                <h2 class="mt-4 font-semibold"> {{ updatedCustomerName ?? customer?.partnerAttributes.name ??
                    customer?.key ?? '' }}</h2>
                <div
                    class="w-full flex-wrap flex flex-col box-border justify-center items-center px-2 max-sm:text-left max-sm:w-full max-sm:justify-center max-sm:align-middle">
                    <div
                        class="w-full max-sm:ml-7 max-md:align-middle"
                        :class="{ hidden: !customerSession?.whoIsChatting }"
                    >
                        <span class="align-middle font-light text-sm text-gray-500 max-sm:pl-5">
                            Atribuído:
                        </span>
                        <span>
                            {{ customerSession?.nameOfWhoIsChatting ?? customerSession?.whoIsChatting }}
                        </span>
                    </div>
                    <div
                        class="w-full max-sm:mr-7 max-md:pl-11"
                        v-if="providerPresentationName"
                    >
                        <span
                            class="align-middle font-light text-xs text-gray-500 text-wrap break-words max-sm:flex-row max-sm:justify-center max-sm:align-middle"
                        >
                            Vindo de: <strong class="text-black-700">
                                {{ providerPresentationName }}
                            </strong>
                        </span>
                    </div>
                </div>
            </div>
            <div v-if="HAS_HISTORY">
                <CustomerFieldSection
                    v-model="customerSession"
                    title="Histórico Cliente"
                    :action-when-show="async () => {
                        if (!customer?.key)
                            return;
                        queryForOldSessions(customer?.key);
                    }"
                    :hasToShowOnStart="false"
                >
                    <div class="p-4">
                        <div
                            class="text-sm font-semibold cursor-pointer"
                            v-for="[id, session] in customerSessions"
                            :key="id"
                            @click="async () => await routeToOldSession(id)"
                        >
                            Protocolo: <span class="font-normal text-gray-700">{{ session.protocol }}</span>
                        </div>
                    </div>
                </CustomerFieldSection>
            </div>
            <div v-if="HAS_TAG_FEATURE">
                <CustomerFieldSection
                    title="Tags"
                    v-model="customerSession"
                >
                    <TagsInput v-model="tags">
                        <TagsInputItem
                            v-for="item in tags"
                            :key="item"
                            :value="item"
                        >
                            <TagsInputItemText />
                            <TagsInputItemDelete />
                        </TagsInputItem>
                        <TagsInputInput placeholder="Adicione uma tag" />
                    </TagsInput>
                </CustomerFieldSection>
            </div>
            <div>
                <CustomerFieldSection
                    v-model="customerSession"
                    title="Observações"
                >
                    <Textarea
                        name="notes"
                        spellcheck="false"
                        placeholder="Adicione uma observação"
                        v-model="notes"
                    />
                    <div class="flex flex-row ml-auto items-center justify-end mt-3 gap-1">
                        <Button
                            variant="ghost"
                            size="icon"
                            @click="() => notes = customer?.notes"
                        >
                            <Redo2 class="text-slate-400" />
                        </Button>
                        <Button
                            variant="default"
                            size="icon"
                            @click="saveNotes"
                            :class="{
                                'bg-green-500': shouldShowEndedOnNotes
                            }"
                        >
                            <LoaderCircle
                                class="animate-spin"
                                v-show="shouldShowProgressOnNotes"
                            />
                            <CircleCheck v-show="!shouldShowProgressOnNotes" />
                        </Button>
                    </div>
                </CustomerFieldSection>
            </div>
            <div v-if="HAS_ANNEXES_FEATURE">
                <CustomerFieldSection
                    title="Arquivos Anexo"
                    v-model="customerSession"
                >
                    <ScrollArea class="h-35 w-full overflow-y-auto">
                        <div class="flex items-center justify-between">
                            <ClipboardList class="mr-2" />
                            <div class="flex-1 text-left">
                                <h2 class="text-lg">Image.jpg</h2>
                                <div class="flex items-center space-x-2 text-gray-500 text-sm mt-1">
                                    <h2 class="font-normal">20/10/1500</h2>
                                    <p>46.mb</p>
                                </div>
                            </div>
                            <Download class="ml-2" />
                        </div>
                        <div class="flex items-center justify-between">
                            <ClipboardList class="mr-2" />
                            <div class="flex-1 text-left">
                                <h2 class="text-lg">Image.jpg</h2>
                                <div class="flex items-center space-x-2 text-gray-500 text-sm mt-1">
                                    <h2 class="font-normal">20/10/1500</h2>
                                    <p>46.mb</p>
                                </div>
                            </div>
                            <Download class="ml-2" />
                        </div>
                        <div class="flex items-center justify-between">
                            <ClipboardList class="mr-2" />
                            <div class="flex-1 text-left">
                                <h2 class="text-lg">Image.jpg</h2>
                                <div class="flex items-center space-x-2 text-gray-500 text-sm mt-1">
                                    <h2 class="font-normal">20/10/1500</h2>
                                    <p>46.mb</p>
                                </div>
                            </div>
                            <Download class="ml-2" />
                        </div>
                    </ScrollArea>
                </CustomerFieldSection>
            </div>
        </div>
    </div>
</template>
<script setup lang="ts">
const HAS_ANNEXES_FEATURE = false;
const HAS_HISTORY = true;
const HAS_TAG_FEATURE = false;
import { Avatar, AvatarFallback, AvatarImage } from '@/components/ui/avatar';
import { CircleCheck, ClipboardList, Download, LoaderCircle, Redo2 } from 'lucide-vue-next';
import { defineProps, ref, computed, watch, defineEmits, onMounted } from 'vue';
import { TagsInput, TagsInputInput, TagsInputItem, TagsInputItemDelete, TagsInputItemText } from '@/components/ui/tags-input';
import { Textarea } from '@/components/ui/textarea';
import CustomerFieldSection from './CustomerFieldSection.vue';
import { Customer, CustomerSession, queryForSessionsOfCustomerKey } from '@/http/customerSession';
import { ScrollArea } from '@/components/ui/scroll-area';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
import PartnerAttributes from '@/components/converza-sessions/chat/PartnerAttributes.vue';
import store, { GET_SESSIONS, PATCH_CUSTOMER_FIELD } from '@/store';
import { set } from '@vueuse/core';
import Button from '@/components/ui/button/Button.vue';

defineEmits(['updated-customer-name']);

const { state, getters } = useStore();
const updatedCustomerName = ref<string | undefined>(undefined);

const props = defineProps({
    customerSessionId: {
        type: String,
        required: true,
    }
});

const getCustomerSession = (): CustomerSession => getters[GET_SESSIONS][props.customerSessionId];
const customerSession = computed<CustomerSession>(getCustomerSession);
const customer = computed<Customer | undefined>(() => customerSession.value?.customer);
const customerId = computed<string | undefined>(() => customer.value?.id);
const customerSessions = ref<Map<string, CustomerSession>>(new Map<string, CustomerSession>);

const tags = ref([]);

const router = useRouter();
const routeToOldSession = async (oldSessionId: string) => {
    await router.push({ path: `/dashboard/atendimento/${oldSessionId}` });
};

const queryForOldSessions = async (customerKey: string) => {
    const credentials = state.credentials;
    if (credentials === undefined)
        throw new Error("Credentials are not present");
    const oldSesionsPage = await queryForSessionsOfCustomerKey(customerKey, credentials);
    const oldSessions = oldSesionsPage.content.filter(session => session.id !== customerSession.value?.id);
    customerSessions.value = new Map<string, CustomerSession>();
    oldSessions.forEach(session => customerSessions.value.set(session.id, session));
};

const providerPresentationName = ref();

watch(customerSession, async (newCustomerSession, oldCustomerSession) => {
    if (newCustomerSession?.id !== oldCustomerSession?.id) {
        resetNotes();
    }
    const providerPresentationNameInStore = state.partnerProviders[newCustomerSession?.partnerProviderId ?? '']?.presentationNameInProvider;
    if (providerPresentationNameInStore == undefined) { return; }
    set(providerPresentationName, providerPresentationNameInStore);
});

const notes = ref<string | undefined>();
const resetNotes = () => {
    const hisNotes = getCustomerSession()?.customer?.notes;
    set(notes, hisNotes);
};
onMounted(resetNotes);

const shouldShowProgressOnNotes = ref(false);
const shouldShowEndedOnNotes = ref(false);

const saveNotes = async () => {
    if (customer.value === undefined) return;
    if (notes.value === undefined) return;
    if (shouldShowProgressOnNotes.value === true || shouldShowEndedOnNotes.value === true) return;

    const payload = {
        partnerId: customer.value.associatedPartnerId,
        customerKey: customer.value.key,
        notes: notes.value,
    };

    shouldShowProgressOnNotes.value = true;
    await store.dispatch(PATCH_CUSTOMER_FIELD, payload);
    setTimeout(() => {
        shouldShowProgressOnNotes.value = false;
        shouldShowEndedOnNotes.value = true;
        setTimeout(() => {
            shouldShowEndedOnNotes.value = false;
        }, 1000);
    }, 300);
};
</script>
