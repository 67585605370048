import { PartnerProviderType } from "@/http/customerSession";
import { BASE_URL, throwCorrespondentApiErrorIfNecessary } from "@/http/api";
import { getCredentials, APICredentials } from "@/http/login";

export interface PartnerProvider {
  id: string;
  partnerId: string;
  active?: boolean;
  idInProvider?: string;
  tokenInProvider?: string;
  presentationNameInProvider?: string;
  webhookVerificationToken?: string;
  partnerProviderType?: PartnerProviderType;
  deleted?: boolean;
  createdBy?: string;
  createdDate?: Date;
  lastModifiedBy?: string;
  lastModifiedDate?: Date;
}

export const getPartnerProviders = async (partnerId: string, credentials: APICredentials) => {
  const baseUrl = new URL(`${BASE_URL}/partner-providers?partnerId=${partnerId}`);
  const url = baseUrl.toString();
  const response = await fetch(url, {
    method: 'GET',
    headers: {
      'Authorization': getCredentials(credentials),
      'Content-Type': 'application/json'
    }
  });

  await throwCorrespondentApiErrorIfNecessary(response);

  const partnerProviders: PartnerProvider[] = await response.json();
  return partnerProviders;
};

export const getPartnerProvider = async (partnerProviderId: string, credentials: APICredentials): Promise<PartnerProvider> => {
  const url = new URL(`${BASE_URL}/partner-providers/${partnerProviderId}`).toString();
  const response = await fetch(url, {
    method: 'GET',
    headers: {
      'Authorization': getCredentials(credentials),
      'Content-Type': 'application/json'
    }
  });

  await throwCorrespondentApiErrorIfNecessary(response);

  return await response.json();
};

export interface PartnerProviderPatchRequest {
  partnerProviderId: string;
  tokenInProvider?: string;
  presentationNameInProvider?: string;
  webhookVerificationToken?: string;
}
export const patchPartnerProvider = async (payload: PartnerProviderPatchRequest, credentials: APICredentials) => {
  const url = new URL(`${BASE_URL}/partner-providers/${payload.partnerProviderId}`);
  const response = await fetch(url, {
    method: 'PATCH',
    body: JSON.stringify({
      tokenInProvider: payload.tokenInProvider,
      presentationNameInProvider: payload.presentationNameInProvider,
      webhookVerificationToken: payload.webhookVerificationToken,
    }),
    headers: {
      'Authorization': getCredentials(credentials),
      'Content-Type': 'application/json',
    }
  });

  await throwCorrespondentApiErrorIfNecessary(response);
};

export interface PostPartnerProviderPayload {
  partnerId: string;
  idInProvider: string;
  tokenInProvider: string;
  webhookVerificationToken: string;
  partnerProviderType: "WHATSAPP" | "INSTAGRAM";
  presentationNameInProvider: string;
}
export const postPartnerProvider = async (payload: PostPartnerProviderPayload, credentials: APICredentials) => {
  const url = new URL(`${BASE_URL}/partner-providers`);
  const response = await fetch(url, {
    method: 'POST',
    body: JSON.stringify(payload),
    headers: {
      'Authorization': getCredentials(credentials),
      'Content-Type': 'application/json',
    }
  });
  await throwCorrespondentApiErrorIfNecessary(response);
  const data = await response.json();
  return data;
};

export interface DeletePartnerProviderPayload {
  partnerProviderId: string;
}

export const deletePartnerProvider = async (partnerProviderId: string, credentials: APICredentials) => {
  const url = new URL(`${BASE_URL}/partner-providers/${partnerProviderId}`);
  const response = await fetch(url, {
    method: 'DELETE',
    headers: {
      'Authorization': getCredentials(credentials),
    }
  });
  await throwCorrespondentApiErrorIfNecessary(response);
};