<template>
  <div>
    <router-link
      v-if="variant === 'link'"
      :to="to"
      class="link flex gap-2 font-semibold cursor-pointer select-none rounded h-8 py-4 no-underline items-center hover:bg-blue-200 hover:border-l-4"
      :class="{
        active: isActive,
        'justify-center': collapsed,
        'pl-2': !collapsed,
      }"
      @mouseover="isHover = true"
      @mouseleave="isHover = false"
    >
      <i
        class="shrink-0 opacity-30"
        :class="{
          [`${icon}`]: true,
          'w-8': !collapsed,
        }"
      />
      <p
        :class="{
          'hover:text-blue-700': true,
          'text-blue-700': isActive || isHover,
          'text-gray-700': !(isActive || isHover),
        }"
        v-if="!collapsed"
      >
        {{ name }}
      </p>
    </router-link>
    <div
      v-else
      class="flex gap-2 font-semibold cursor-pointer select-none rounded h-8 py-4 no-underline items-center hover:bg-slate-100 border-l-4 border-transparent"
      :class="{
        'justify-center': collapsed,
        'pl-2': !collapsed,
      }"
      @mouseover="isHover = true"
      @mouseleave="isHover = false"
    >
      <i
        class="shrink-0 opacity-30"
        :class="{
          [`${icon}`]: true,
          'w-8': !collapsed,
          'scale-x-[-1]': rotate && collapsed
        }"
      />
      <p
        class="hover:text-slate-600 hover:bg-slate-100 text-muted"
        :class="{
          'text-muted': !isHover,
          'text-slate-600': isHover
        }"
        v-if="!collapsed"
      >
        {{ name }}
      </p>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed, defineProps, PropType, ref } from 'vue';
import { useRoute } from 'vue-router';

type Variant = 'link' | 'button';

const props = defineProps({
  to: { type: String, required: false, default: undefined },
  icon: { type: String, required: true },
  name: { type: String, required: true },
  collapsed: { type: Boolean, default: false },
  rotate: { type: Boolean, default: false },
  variant: { type: String as PropType<Variant>, default: 'link', required: false },
});

const route = useRoute();
const isActive = computed(() => {
  if (props.variant === 'button') return false;
  const isDashboard = props.to === '/dashboard';
  return (!isDashboard && route.fullPath.startsWith(props.to ?? '')) ||
    (isDashboard && route.fullPath === props.to);
});

const isHover = ref(false);
</script>

<style sass scoped>
.link {
  border-left: 4px solid;
  border-color: var(--sidebar-bg-color);
}

.link:hover {
  border: 0px solid transparent;
  border-left: 4px solid;
  color: var(--primary-blue);
}

.link.active {
  background-color: var(--sidebar-item-active);
  color: var(--primary-blue);
  border: 0px solid transparent;
  border-left: 4px solid blue;
}

.link.active p {
  color: var(--primary-blue);
}
</style>
