<template>
    <div class="grid grid-cols-12 justify-between items-center gap-4 py-2 px-4 pt-1 w-full max-w-full flex-grow-0">
        <div class="col-span-2 flex flex-col max-w-1/6 h-full items-start justify-end gap-1.5">
            <Label class="text-left h-1"></Label>
            <div class="flex-grow px-[13px] h-10 flex flex-row items-center mt-5 justify-start gap-2">
                <ProviderIcon
                    class="flex-grow-0"
                    :provider="partnerProvider?.partnerProviderType"
                />
                <span class="flex-grow text-xs font-semibold align-middle"> {{ partnerProvider?.partnerProviderType ===
                    'INSTAGRAM'
                    ?
                    'Instagram' : 'WhatsApp' }}</span>
            </div>
        </div>
        <div class="col-span-2 flex flex-col max-w-1/6 h-full items-start justify-end gap-1.5">
            <Label
                class="text-left"
                :class="{ 'text-red-500': showPresentationNameInProviderInvalidMessage }"
            >Nome de Apresentação</Label>
            <Input
                :class="{
                    'placeholder:text-gray-500': isEditing,
                    'bg-gray-200 border-gray-400': !isEditing,
                    'ring-red-500 border-red-500': showPresentationNameInProviderInvalidMessage
                }"
                :readonly="!isEditing"
                :defaultValue="partnerProvider?.presentationNameInProvider"
                v-model="presentationNameInProvider"
            />
        </div>
        <div class="col-span-2 flex flex-col max-w-1/6 h-full items-start justify-end gap-1.5">
            <Label
                class="text-left"
                :class="{ 'text-red-500': showIdInProviderInvalidMessage }"
            >Identificação no Provedor</Label>
            <Input
                :class="{
                    'placeholder:text-gray-500': isEditing,
                    'bg-gray-200 border-gray-400': !isEditing,
                    'ring-red-500 border-red-500': showIdInProviderInvalidMessage
                }"
                :readonly="!isEditing"
                :defaultValue="partnerProvider?.idInProvider"
                v-model="idInProvider"
            />
        </div>
        <div class="col-span-2 flex flex-col max-w-1/6 h-full items-start justify-end gap-1.5">
            <Label
                class="text-left"
                :class="{ 'text-red-500': showTokenInProviderInvalidMessage }"
            >Token</Label>
            <Input
                :class="{
                    'placeholder:text-gray-500': isEditing,
                    'bg-gray-200 border-gray-400': !isEditing,
                    'ring-red-500 border-red-500': showTokenInProviderInvalidMessage
                }"
                :readonly="!isEditing"
                :defaultValue="partnerProvider?.tokenInProvider"
                v-model="tokenInProvider"
            />
        </div>
        <div class="col-span-3 flex flex-col max-w-1/6 h-full items-start justify-end gap-1.5">
            <Label
                class="text-left"
                :class="{ 'text-red-500': showWebhookVerificationTokenInvalidMessage }"
            >Webhook Verification Token</Label>
            <Input
                :class="{
                    'placeholder:text-gray-500': isEditing,
                    'bg-gray-200 border-gray-400': !isEditing,
                    'ring-red-500 border-red-500': showWebhookVerificationTokenInvalidMessage
                }"
                :readonly="!isEditing"
                :defaultValue="partnerProvider?.webhookVerificationToken"
                v-model="webhookVerificationToken"
            />
        </div>
        <div class="col-span-1 flex flex-row max-w-1/6 h-full items-end justify-end align-center gap-1.5">
            <Dialog
                v-model:open="openDelete"
                v-if="!isEditing"
            >
                <DialogTrigger as-child>
                    <Button
                        variant="ghost"
                        size="icon"
                    >
                        <Trash2 class="text-slate-400" />
                    </Button>
                </DialogTrigger>

                <DialogContent class="pt-4">
                    <DialogHeader class="flex flex-row justify-between items-center">
                        <DialogTitle>Deletar provedor?</DialogTitle>
                        <DialogClose>
                            <Button
                                variant="ghost"
                                class="px-1.5"
                                @click="openDelete = false"
                            >
                                <CircleX class="text-gray-600" />
                            </Button>
                        </DialogClose>
                    </DialogHeader>
                    <div>
                        <p class="mt-2">Tem certeza de que deseja excluir esta configuração do provedor?</p> Nome de
                        exibição: {{ presentationNameInProvider }} <p class="mt-2">Essa ação é
                            <strong>irreversível</strong> e não poderá ser desfeita.
                        </p>
                    </div>
                    <DialogFooter>
                        <Button
                            type="submit"
                            @click="async () => {
                                await store.dispatch(DELETE_PARTNER_PROVIDERS, props.partnerProvider?.id);
                                openDelete = false;
                            }"
                            variant="destructive"
                        >
                            Deletar
                        </Button>
                        <Button
                            variant="ghost"
                            @click="openDelete = false"
                            class="px-1.5"
                        >Cancelar</Button>
                    </DialogFooter>
                </DialogContent>
            </Dialog>
            <Button
                v-else
                variant="ghost"
                size="icon"
                @click="() => {
                    webhookVerificationToken = partnerProvider?.webhookVerificationToken;
                    tokenInProvider = partnerProvider?.tokenInProvider;
                    presentationNameInProvider = partnerProvider?.presentationNameInProvider;
                    idInProvider = partnerProvider?.idInProvider;
                    toggleEdit();
                }"
            >
                <CircleX class="text-slate-400" />
            </Button>
            <Button
                v-if="!isEditing"
                variant="default"
                size="icon"
                @click="toggleEdit"
                class="px-1.5"
            >
                <Pencil class="text-white" />
            </Button>
            <Button
                v-else
                variant="default"
                size="icon"
                class="px-1.5"
                @click="patchPartnerProvider"
            >
                <CircleCheck class="text-white" />
            </Button>
        </div>
    </div>
</template>

<script lang="ts" setup>
import { ref, defineProps, watch } from 'vue';
import { Pencil, Trash2, CircleCheck, CircleX } from 'lucide-vue-next';
import { PropType } from 'vue';
import Label from '@/components/ui/label/Label.vue';
import ProviderIcon from '@/components/ProviderIcon.vue';
import { PartnerProvider } from '@/http/partnerProvider';
import Input from '@/components/ui/input/Input.vue';
import { PartnerProviderPatchRequest } from '@/http/partnerProvider';
import { DELETE_PARTNER_PROVIDERS, PATCH_PARTNER_PROVIDERS } from '@/store';
import { useStore } from 'vuex';
import Button from '@/components/ui/button/Button.vue';
import {
    Dialog,
    DialogClose,
    DialogContent,
    DialogFooter,
    DialogHeader,
    DialogTitle,
    DialogTrigger,
} from '@/components/ui/dialog';

const store = useStore();
const isEditing = ref(false);
const openDelete = ref(false);

const props = defineProps({
    partnerProvider: {
        type: Object as PropType<PartnerProvider>,
        required: false
    },
    displayToast: {
        type: Function,
        required: true
    }
});

const presentationNameInProvider = ref(props.partnerProvider?.presentationNameInProvider);
const webhookVerificationToken = ref(props.partnerProvider?.webhookVerificationToken);
const tokenInProvider = ref(props.partnerProvider?.tokenInProvider);
const idInProvider = ref(props.partnerProvider?.idInProvider);

const validateNotEmptyString = (value?: string): boolean => {
    return value !== undefined && value.trim() !== '';
};

const showPresentationNameInProviderInvalidMessage = ref<boolean>(false);
watch(presentationNameInProvider, (newValue) => {
    showPresentationNameInProviderInvalidMessage.value = !validateNotEmptyString(newValue);
});

const showWebhookVerificationTokenInvalidMessage = ref<boolean>(false);
watch(webhookVerificationToken, (newValue) => {
    showWebhookVerificationTokenInvalidMessage.value = !validateNotEmptyString(newValue);
});

const showTokenInProviderInvalidMessage = ref<boolean>(false);
watch(tokenInProvider, (newValue) => {
    showTokenInProviderInvalidMessage.value = !validateNotEmptyString(newValue);
});

const showIdInProviderInvalidMessage = ref<boolean>(false);
watch(idInProvider, (newValue) => {
    showIdInProviderInvalidMessage.value = !validateNotEmptyString(newValue);
});


const toggleEdit = () => {
    isEditing.value = !isEditing.value;
};


const isAnyFieldEmpty = () => {
    return (presentationNameInProvider.value === "" ||
        webhookVerificationToken.value === "" ||
        tokenInProvider.value === "" ||
        idInProvider.value === ""
    );
};


const getInvalidParametersMessages = () => {
    let errors = `${showPresentationNameInProviderInvalidMessage.value ? "Nome de Apresentação, \n" : ""}` +
        `${showTokenInProviderInvalidMessage.value ? "Token no Provedor, \n" : ""}` +
        `${showIdInProviderInvalidMessage.value ? "Identificação no Provedor, \n" : ""}` +
        `${showWebhookVerificationTokenInvalidMessage.value ? "Webhook Verification Token, \n" : ""}`;
    let messages = 'Você também precisa preencher os seguintes campos: ' + errors.slice(0, errors.length - 3) + '.';

    return messages;
};
const patchPartnerProvider = async () => {
    if (props.partnerProvider?.id === undefined) { return; }
    if (isAnyFieldEmpty()) {
        props.displayToast('Há dados inválidos!', 'destructive', getInvalidParametersMessages());
        return;
    }
    let payload: PartnerProviderPatchRequest = {
        partnerProviderId: props.partnerProvider.id,
        presentationNameInProvider: presentationNameInProvider.value,
        tokenInProvider: tokenInProvider.value,
        webhookVerificationToken: webhookVerificationToken.value,
    };

    await store.dispatch(PATCH_PARTNER_PROVIDERS, payload);
    toggleEdit();
    props.displayToast('As informações do provedor foram atualizadas com sucesso!', 'default');
};
</script>
