import { createRouter, createWebHashHistory, RouteRecordRaw } from 'vue-router';
import AuthView from '@/views/AuthView.vue';
import ConverzaBaseView from '@/views/ConverzaBaseView.vue';
import ConverzaSessionsView from '@/views/ConverzaSessionsView.vue';
import ConverzaChat from '@/components/converza-sessions/chat/ConverzaChat.vue';
import EmptyConverza from '@/components/converza-sessions/chat/EmptyConverza.vue';
import ConfigView from '@/views/ConfigView.vue';
import AutomationsView from '@/views/AutomationsView.vue';
import AutomationArea from '@/components/automations-screen/AutomationArea.vue';
import AutomationMenu from '@/components/automations-screen/AutomationMenu.vue';
import DashboardView from '@/views/DashboardView.vue';
import ContactsView from '@/views/ContactsView.vue';
import AnswersView from '@/views/AnswersView.vue';
import ReportsView from '@/views/ReportsView.vue';
import HelpView from '@/views/HelpView.vue';
import LandingPageView from '@/views/landing-page/LandingPageView.vue';
import LandingPageHomeView from '@/views/landing-page/LandingPageHomeView.vue';
export type CustomRouteRecordRaw = RouteRecordRaw & {
  icon?: string;
  ignoreOnNavbar?: boolean;
};

export const DEFAULT_BASE_DASHBOARD_NAME = 'dashboardView';

export const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'Landing Page',
    component: LandingPageView,
    children: [
      {
        path: '',
        name: 'Landing Page Default',
        component: LandingPageHomeView
      },
    ]
  },
  {
    path: '/login',
    name: 'login',
    component: AuthView,
  },
  {
    path: '/dashboard',
    name: DEFAULT_BASE_DASHBOARD_NAME,
    component: ConverzaBaseView,
    children: [
      {
        path: '',
        name: 'Dashboard',
        component: DashboardView,
        icon: 'fa-solid fa-house',
      },
      {
        path: 'atendimento',
        name: 'Atendimento',
        component: ConverzaSessionsView,
        icon: 'fa-regular fa-comment',
        children: [{
          path: '',
          name: 'Sem conversas',
          component: EmptyConverza,
          props: true,
        },
        {
          path: ':customerSessionId',
          name: 'Conversa',
          component: ConverzaChat,
          props: true,
        }]
      },
      {
        path: 'automacoes',
        name: 'Automações',
        component: AutomationsView,
        icon: 'fa-solid fa-arrows-rotate',
        children: [{
          path: '',
          name: 'Menu de Automação',
          component: AutomationMenu,
        },
        {
          path: ':workflowCode',
          name: 'Automação de Fluxo',
          component: AutomationArea,
          props: true
        }]
      },
      {
        path: 'respostas',
        name: 'Respostas',
        component: AnswersView,
        icon: 'fa-regular fa-message',
      },
      {
        path: 'contatos',
        name: 'Contatos',
        component: ContactsView,
        icon: 'fa-solid fa-user-group',
      },
      {
        path: 'relatorios',
        name: 'Relatórios',
        component: ReportsView,
        icon: 'fa-regular fa-clipboard',
      },
      {
        path: 'ajuda',
        name: 'Ajuda',
        component: HelpView,
        icon: 'fa-regular fa-circle-question',
        ignoreOnNavbar: true
      },
      {
        path: 'configuracoes',
        name: 'Configurações',
        component: ConfigView,
        icon: 'fas fa-gear',
        ignoreOnNavbar: true
      },
      {
        path: 'ajuda',
        name: 'Ajuda',
        component: HelpView,
        icon: 'fa-regular fa-circle-question',
        ignoreOnNavbar: true
      }
    ] as CustomRouteRecordRaw[],
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  scrollBehavior(to) {
    if (to.hash) {
      return {
        el: to.hash,
        behavior: 'smooth',
      };
    }
  },
  routes,
});

export default router;
