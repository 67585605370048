import { BASE_URL, throwCorrespondentApiErrorIfNecessary } from "@/http/api";
import { ImageSourceChoice, MessageType } from "@/http/customerSession";
import { APICredentials, getCredentials } from "@/http/login";

export interface WorkflowTask {
  id: string;
  workflowCode: string;
  partnerId: string;
  content: string;
  type: MessageType;
  interactiveSubtype?: InteractiveSubtype;
  order: number;
  notes: string;
  availableReplies: Record<string, SelectionReply>;
  title: string;
  footer: string;
  buttonText: string;
  caption: string;
  imageSource: ImageSourceChoice;
  shouldWaitReply: boolean;
  nextWorkflowCode: string;
  nextTaskId: string;
  expectedInputType: MessageType;
  userAttribute: string;
  sessionAttribute: string;
  deleted: boolean;
  createdBy: string;
  createdDate: Date;
  lastModifiedBy: string;
  lastModifiedDate: Date;
}

export type InteractiveSubtype = "LIST" | "BUTTON";

export interface SelectionReply {
  id: string;
  title: string;
  description: string;
  associatedWorkflowCode: string;
}

export const getWorkflowTasks = async (partnerId: string, workflowCode: string, credentials: APICredentials) => {
  const url = `${BASE_URL}/workflow-tasks?partnerId=${partnerId}&workflowCode=${encodeURIComponent(workflowCode)}`;
  const response = await fetch(url, {
    method: 'get',
    headers: {
      'Authorization': getCredentials(credentials),
      'Content-Type': 'application/json'
    }
  });

  await throwCorrespondentApiErrorIfNecessary(response);

  const workflowTasks: WorkflowTask[] = await response.json();
  return workflowTasks;
};

export const getWorkflowTaskById = async (workflowTaskId: string, credentials: APICredentials) => {
  const url = `${BASE_URL}/workflow-tasks/${workflowTaskId}`;
  const response = await fetch(url, {
    method: 'get',
    headers: {
      'Authorization': getCredentials(credentials),
      'Content-Type': 'application/json'
    }
  });

  await throwCorrespondentApiErrorIfNecessary(response);

  const workflowTasks: WorkflowTask = await response.json();
  return workflowTasks;
};