<template>
  <div class="relative">
    <EyeOffIcon
      v-if="isPasswordVisible"
      @click="isPasswordVisible = !isPasswordVisible"
      class="absolute right-2 top-3 size-4 text-slate-500"
    />
    <Eye
      v-else
      @click="isPasswordVisible = !isPasswordVisible"
      class="absolute right-2 top-3 size-4 text-slate-500"
    />
    <Input
      v-model="model"
      :type="isPasswordVisible ? 'text' : 'password'"
      :placeholder="placeholder"
    />
  </div>
</template>

<script setup lang="ts">
import { ref, defineProps, defineModel } from 'vue';
import Input from '@/components/ui/input/Input.vue';
import { Eye, EyeOffIcon } from 'lucide-vue-next';

defineProps({
  placeholder: {
    type: String,
    required: false,
    default: undefined
  }
});

const model = defineModel<string | undefined>({
  type: String,
  required: false,
  default: undefined
});


const isPasswordVisible = ref<boolean>(false);
</script>