<template>
  <form
    class="w-full h-full"
    @submit="onSubmit"
  >
    <div class="flex flex-col items-center md:justify-center h-full w-full px-8">
      <div class="my-2 space-y-2 md:w-1/2 max-md:w-full">
        <Input
          v-model="email"
          type="email"
          placeholder="E-mail"
        />
        <PasswordInput
          v-model="password"
          placeholder="Senha"
        />
      </div>
      <Button
        type="submit"
        class="my-2 bg-blue-700 md:w-1/2 max-md:w-full rounded-full text-slate-50 text-xs"
      >
        Logar
      </Button>
      <p class="text-xs text-gray-500">
        Não tem uma conta? <a
          class="text-blue-500 cursor-pointer"
          @click="$emit('TOGGLE_REGISTRATION')"
        >Registre-se</a>
      </p>
    </div>
  </form>
  <Toaster />
</template>

<script setup lang="ts">
import { BasicCredentials } from '@/http/login';
import { onBeforeMount, ref } from 'vue';
import { defineEmits } from 'vue';
import Input from '@/components/ui/input/Input.vue';
import Button from '@/components/ui/button/Button.vue';
import { useToast } from '@/components/ui/toast';
import Toaster from '@/components/ui/toast/Toaster.vue';
import { useStore } from 'vuex';
import { AUTHENTICATE, PARTNER_ID, GET_CREDENTIALS as CREDENTIALS } from '@/store';
import { useRouter } from 'vue-router';
import { AuthenticationError } from '@/http/api';
import PasswordInput from './PasswordInput.vue';
import { validateEmail, validatePassword } from '@/lib/formValidation';

const emit = defineEmits(['TOGGLE_REGISTRATION', 'GO_TO_PARTNER_FORM']);

const email = ref('');
const password = ref('');
const { getters, dispatch } = useStore();

const { toast } = useToast();

const router = useRouter();
const onSubmit = async (event: Event) => {
  event.preventDefault();
  if (!(validateEmail(email.value) && validatePassword(password.value))) {
    toast({
      variant: 'destructive',
      title: 'Preencha os dados de acesso',
      description: 'É necessário um e-mail válido e uma senha de 6 dígitos',
    });
    return;
  }
  const requiredLogin: BasicCredentials = {
    email: email.value,
    password: password.value,
  };

  try {
    await dispatch(AUTHENTICATE, requiredLogin);

    if (getters[PARTNER_ID] != undefined) {
      await router.push({ path: '/dashboard' });
    }

    emit('GO_TO_PARTNER_FORM');
  } catch (err) {
    if (err instanceof AuthenticationError) {
      toast({
        title: 'Não foi possível logar',
        description: 'Verifique sua senha e tente novamente',
        variant: 'destructive',
      });
      return;
    }
    toast({
      title: 'O sistema parece estar indisponível',
      description: 'Tente novamente mais tarde',
      variant: 'destructive',
    });
  }
};

onBeforeMount(() => {
  if (getters[CREDENTIALS] === undefined) return;
});
</script>
