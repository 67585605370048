import { BASE_URL, throwCorrespondentApiErrorIfNecessary } from "@/http/api";
import { APICredentials, getCredentials } from "@/http/login";
import { PartnerField, PartnerSize } from "@/models/partner";

interface LeadCreationRequest {
  email: string;
  password: string;
  phoneNumber: string;
  name: string;
}

export const submitLeadCreationRequest = async (leadCreationRequest: LeadCreationRequest) => {
  const response = await fetch(`${BASE_URL}/leads`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(leadCreationRequest)
  });
  await throwCorrespondentApiErrorIfNecessary(response);
};

interface PartnerCreationRequest {
  partnerName: string;
  cnpj: string;
  partnerSize: PartnerSize;
  partnerField: PartnerField;
}

interface PartnerCreationResponse {
  id: string;
  cnpj: string;
  companyName: string;
  partnerSize: PartnerSize;
  partnerField: PartnerField;
  active: boolean;
  deleted: number;
  sessionLimitTimeout: number;
}

export const submitPartnerCreationRequest = async (partnerCreationRequest: PartnerCreationRequest, credentials: APICredentials): Promise<PartnerCreationResponse> => {
  const response = await fetch(`${BASE_URL}/partners`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': getCredentials(credentials)
    },
    body: JSON.stringify(partnerCreationRequest)
  });
  await throwCorrespondentApiErrorIfNecessary(response);
  return await response.json();
};