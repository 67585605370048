import { BASE_URL, throwCorrespondentApiErrorIfNecessary } from "@/http/api";
import { APICredentials, getCredentials } from "@/http/login";


export const patchPartnerSessionInactivityTimeout = async (partnerId: string, timeout: number, credentials: APICredentials) => {
  const url = new URL(`${BASE_URL}/partners/${partnerId}`);
  const response = await fetch(url, {
    method: 'PATCH',
    body: JSON.stringify({
      sessionLimitTimeout: timeout
    }),
    headers: {
      'Authorization': getCredentials(credentials),
      'Content-Type': 'application/json',
    }
  });
  await throwCorrespondentApiErrorIfNecessary(response);
};

export const getPartnerSessionInactivityTimeout = async (partnerId: string, credentials: APICredentials) => {
  const url = new URL(`${BASE_URL}/partners/${partnerId}`);
  const response = await fetch(url, {
    method: 'GET',
    headers: {
      'Authorization': getCredentials(credentials),
      'Content-Type': 'application/json',
    }
  });
  await throwCorrespondentApiErrorIfNecessary(response);
  return response.json();
};