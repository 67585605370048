import { ApiError, BASE_URL, throwCorrespondentApiErrorIfNecessary } from "@/http/api";
import { APICredentials, getCredentials } from "@/http/login";

export interface Workflow {
  id: string;
  presentationName: string;
  workflowCode: string;
  partnerId: string;
  notes: string;
  deleted: boolean;
  createdBy: string;
  createdDate: Date;
  lastModifiedBy: string;
  lastModifiedDate: Date;
}

export interface WorkflowCreationRequest {
  workflowCode: string;
  presentationName: string;
  partnerId: string;
  notes?: string;
}

export interface WorkflowPatchRequest {
  presentationName?: string;
  notes?: string;
}

export const getWorkflow = async (partnerId: string, workflowCode: string, credentials: APICredentials): Promise<Workflow> => {
  const url = `${BASE_URL}/workflows?partnerId=${partnerId}&workflowCode=${encodeURIComponent(workflowCode)}`;
  const response = await fetch(url, {
    method: 'get',
    headers: {
      'Authorization': getCredentials(credentials),
      'Content-Type': 'application/json'
    }
  });

  await throwCorrespondentApiErrorIfNecessary(response);

  const workflows: Workflow[] = await response.json();
  if (workflows?.length == 0) {
    throw new ApiError(response.status, `not found workflow ${workflowCode}`);
  }
  return workflows.pop() as Workflow;
};

export const getWorkflows = async (partnerId: string, credentials: APICredentials) => {
  const url = `${BASE_URL}/workflows?partnerId=${partnerId}`;
  const response = await fetch(url, {
    method: 'get',
    headers: {
      'Authorization': getCredentials(credentials),
      'Content-Type': 'application/json'
    }
  });

  await throwCorrespondentApiErrorIfNecessary(response);

  const workflows: Workflow[] = await response.json();
  return workflows;
};

export const getWorkflowById = async (workflowId: string, credentials: APICredentials) => {
  const url = `${BASE_URL}/workflows/${workflowId}`;
  const response = await fetch(url, {
    method: 'GET',
    headers: {
      'Authorization': getCredentials(credentials),
      'Content-Type': 'application/json'
    }
  });

  await throwCorrespondentApiErrorIfNecessary(response);

  const workflow: Workflow = await response.json();
  return workflow;
};

export const createNewWorkflow = async (payload: WorkflowCreationRequest, credentials: APICredentials) => {
  const url = `${BASE_URL}/workflows`;
  const response = await fetch(url, {
    method: 'POST',
    headers: {
      'Authorization': getCredentials(credentials),
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(payload)
  });

  await throwCorrespondentApiErrorIfNecessary(response);

  const workflow: Workflow = await response.json();
  return workflow;
};

export const patchWorkflow = async (workflowId: string, payload: WorkflowPatchRequest, credentials: APICredentials) => {
  const url = `${BASE_URL}/workflows/${workflowId}`;
  const response = await fetch(url, {
    method: 'PATCH',
    headers: {
      'Authorization': getCredentials(credentials),
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(payload)
  });

  await throwCorrespondentApiErrorIfNecessary(response);
};

export const resetWorkflows = async (partnerId: string, credentials: APICredentials) => {
  const url = `${BASE_URL}/workflows`;
  
  const response = await fetch(url, {
    method: 'DELETE',
    headers: {
      'Authorization': getCredentials(credentials),
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({ partnerId })
  });

  await throwCorrespondentApiErrorIfNecessary(response);
};
