<template>
    <FontAwesomeIcon
        v-if="props.provider === 'WHATSAPP'"
        :icon="faSquareWhatsapp"
        class="relative text-green-600 bg-white rounded-full h-6 w-6"
    />
    <FontAwesomeIcon
        v-if="props.provider === 'INSTAGRAM'"
        :icon="faSquareInstagram"
        class="relative rounded-full text-[#dd2a7b] h-6 w-6"
    />
    <div
        v-if="providerOrder !== undefined"
        class="absolute top-0 -right-1 flex items-center justify-center w-3 h-3 bg-gray-700 rounded-full"
    >
        <span class="text-gray-100 text-[8px]">{{ providerOrder }}</span>
    </div>
</template>

<script lang="ts" setup>
import { PartnerProviderType } from '@/http/customerSession';
import { faSquareInstagram, faSquareWhatsapp } from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { defineProps, PropType } from 'vue';

const props = defineProps({
    provider: {
        type: String as PropType<PartnerProviderType>,
        default: () => { return 'WHATSAPP'; }
    },
    providerOrder: {
        type: String,
        default: undefined,
        required: false,
    }
});
</script>
