<template>
  <div
    class="flex flex-col justify-center items-center h-full w-full gap-16 max-[960px]:overflow-y-auto max-mc:overflow-y-hidden max-[960px]:justify-start max-[960px] px-8"
  >
    <Stepper
      v-model="stepIndex"
      class="flex w-full items-start gap-2 "
    >
      <StepperItem
        v-for="step in steps"
        :key="step.step"
        v-slot="{ state }"
        class="relative flex w-full flex-col items-center justify-center"
        :step="step.step"
      >
        <StepperSeparator
          v-if="step.step !== steps[steps.length - 1].step"
          class="absolute left-[calc(50%+20px)] right-[calc(-50%+10px)] top-[69px] block h-0.5 shrink-0 rounded-full bg-muted group-data-[state=completed]:bg-blue-700"
        />

        <div class="mt-5 flex flex-col items-center text-center">
          <StepperTitle
            :class="[state === 'active' && 'text-primary']"
            class="text-sm font-semibold transition lg:text-base"
          >
            {{ step.title }}
          </StepperTitle>
        </div>

        <StepperTrigger as-child>
          <Button
            variant="outline"
            size="icon"
            class="z-10 rounded-full shrink-0 bg-white group-data-[state=completed]:bg-blue-700 border-2 group-data-[state=inactive]:border-muted group-data-[state=active]:border-blue-700 group-data-[state=completed]:border-white override-opacity-to-100"
            @click="(event) => { event.preventDefault(); }"
            @submit="(event) => { event.preventDefault(); }"
          >
            <div class="rounded-full w-full h-full p-3 flex justify-center items-center">
              <div v-if="state === 'completed'">
                <Check class="size-5 text-white" />
              </div>
              <div
                v-else
                class="text-blue-700 group-data-[state=inactive]:text-muted"
              >
                {{ step.step }}
              </div>
            </div>
          </Button>
        </StepperTrigger>
      </StepperItem>
    </Stepper>

    <div class="flex flex-col gap-4 md:w-2/3 max-md:w-full">
      <LeadForm
        v-if="stepIndex === 1"
        @next-step="goNext"
        @TOGGLE_REGISTRATION="$emit('TOGGLE_REGISTRATION')"
      />

      <PartnerForm
        v-else-if="stepIndex === 2"
        @next-step="goNext"
        @TOGGLE_REGISTRATION="$emit('TOGGLE_REGISTRATION')"
      />

      <div
        class="flex flex-col grow"
        v-else
      >
        <div class="flex flex-col gap-8 justify-around w-full h-full">
          <span class="text-lg font-semibold">Seja bem vindo ao <strong>Converza</strong>!</span>
          <p>Sua jornada está apenas começando, em alguns instantes você será redireciado para a página
            principal.</p>
          <Button
            class="w-full text-white rounded-full"
            variant="default"
            type="submit"
            size="sm"
            @click="async () => await redirectToConverza()"
          >
            Ir para o Converza
          </Button>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { defineEmits, defineModel, watch } from 'vue';
import { Check } from 'lucide-vue-next';
import { set } from '@vueuse/core';
import { Stepper, StepperItem, StepperSeparator, StepperTitle, StepperTrigger } from '@/components/ui/stepper';
import { Button } from '@/components/ui/button';
import LeadForm from './LeadForm.vue';
import PartnerForm from './PartnerForm.vue';
import { useRouter } from 'vue-router';

defineEmits(['TOGGLE_REGISTRATION']);

const stepIndex = defineModel({
  default: 1,
  required: false,
  type: Number
});
const steps = [
  {
    step: 1,
    title: 'Seus dados',
  },
  {
    step: 2,
    title: 'Personalização',
  },
  {
    step: 3,
    title: 'Conclusão',
  },
];

function goNext() {
  set(stepIndex, stepIndex.value + 1);
}

const router = useRouter();

const redirectToConverza = async () => {
  return await router.push({ path: '/dashboard' });
};

watch(stepIndex, async (newValue) => {
  if (newValue === 3) {
    setTimeout(async () => {
      await redirectToConverza();
    }, 4000);
  }
});
</script>

<style>
.override-opacity-to-100 {
  opacity: 100 !important;
}
</style>