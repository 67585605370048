<template>
    <div class="grow-0 flex flex-row gap-2 px-2 py-1 items-center justify-start rounded-lg">
        <div
            v-if="HAS_AUTOMATIC_MESSAGES"
            class="flex-initial h-full flex items-center"
        >
            <FileVideo />
        </div>
        <div class="flex-1 self-strech min-h-0 max-h-full grow relative flex justify-center items-stretch">
            <Textarea
                type="text"
                placeholder="Escreva mensagem"
                name="message"
                class="readonly:bg-gray-100 bg-white min-h-0 max-lg:py-4 max-lg:pr-[36px] lg:pr-[90px] box-border border disabled:bg-gray-100 border-gray-300 rounded-l-2xl text-sm font-semibold resize-none focus-visible:ring-offset-0 focus-visible:ring-1"
                :disabled="props.disabled"
                :readonly="isSending"
                :class="{
                    'cursor-not-allowed': isSending
                }"
                v-model="keyboardMessage"
                @keypress="sendTextMessage"
            />
            <div class="absolute inset-y-0 space-y-1 right-0 flex items-center pr-2">
                <Select
                    name="chatSelected"
                    v-model="selectedValue"
                    :default-value="'CHAT'"
                    :disabled="props.disabled"
                >
                    <SelectTrigger
                        :class="selectedValue === 'INTERNAL' ? 'bg-blue-200 text-primary' : 'bg-gray-100'"
                        class="w-[90px] max-lg:h-[36px] lg:h-[24px] xl:h-[36px]"
                    >
                        <SelectValue />
                    </SelectTrigger>
                    <SelectContent>
                        <SelectGroup>
                            <SelectItem value="CHAT">Cliente</SelectItem>
                            <SelectItem value="INTERNAL">Oculto</SelectItem>
                        </SelectGroup>
                    </SelectContent>
                </Select>
            </div>
        </div>
        <div class="flex-initial flex items-center space-x-2">
            <Smile v-if="HAS_EMOJI_FEATURE" />
            <ImagePopover v-model="image">
                <Button
                    variant="ghost"
                    size="icon"
                    :disabled="props.disabled || isSending"
                    :class="{
                        'relative': true,
                        'bg-accent': imgSrc !== undefined
                    }"
                >
                    <Image
                        v-if="imgSrc === undefined"
                        class="text-primary opacity-75"
                    />
                    <span v-else>
                        <img
                            class="object-scale-down h-full w-full"
                            :src="imgSrc"
                        >
                        <span
                            class="animate-ping top-0 -right-0 absolute inline-flex h-1.5 w-1.5 rounded-full bg-primary opacity-50"
                        ></span>
                    </span>
                </Button>
            </ImagePopover>
            <Mic v-if="HAS_MIC_FEATURE" />
            <Button
                class="h-auto w-auto max-lg:p-3 lg:p-1 disabled:opacity-80 bg-blue-700 text-white rounded-md flex items-center justify-center disabled:cursor-not-allowed"
                :class="{ 'bg-destructive': showError }"
                variant="default"
                @click="sendTextMessage"
                :disabled="props.disabled || isSending"
            >
                <LoaderCircle
                    class="animate-spin"
                    v-if="isSending"
                />
                <Send v-else />
            </Button>
        </div>
    </div>
</template>
<script setup lang="ts">
import { ref, defineEmits, defineProps, computed } from 'vue';
import { FileVideo, Image, LoaderCircle, Mic, Send, Smile } from 'lucide-vue-next';
import {
    Select,
    SelectContent,
    SelectGroup,
    SelectItem,
    SelectTrigger,
    SelectValue,
} from '@/components/ui/select';
import { useStore } from 'vuex';
import { SEND_MESSAGE, SendMessageStorePayload } from '@/store';
import Button from '@/components/ui/button/Button.vue';
import { ConversationTarget, Message, ImageUpload, SendMessageType } from '@/http/customerSession';
import ImagePopover from '@/components/ImagePopover.vue';
import Textarea from '@/components/ui/textarea/Textarea.vue';
import { get, set } from '@vueuse/core';

const HAS_EMOJI_FEATURE = false;
const HAS_MIC_FEATURE = false;
const HAS_AUTOMATIC_MESSAGES = false;

const selectedValue = ref('CHAT');

const props = defineProps({
    disabled: {
        type: Boolean,
        default: false,
        required: false
    },
    sessionId: {
        type: String,
        required: true
    },
    customerKey: {
        type: String,
        required: true
    }
});

const emit = defineEmits(['update-read', 'scroll-to-the-end']);

const keyboardMessage = ref();
const { dispatch } = useStore();
const isSending = ref(false);
const messageType = ref<SendMessageType>('text');

const image = ref<ImageUpload | undefined>();
const imgSrc = computed<string | undefined>(() => {
    const selectedImage = get(image);
    if (selectedImage === undefined) {
        return undefined;
    }
    return selectedImage?.imageSourceChoice === 'URL'
        ? selectedImage?.imageContent
        : `data:${selectedImage?.imageContentType};charset=utf-8;base64, ${selectedImage?.imageContent}`;
});

const showError = ref(false);

const sendTextMessage = async (event: KeyboardEvent) => {
    if (event.key !== "Enter" && event.key !== undefined) {
        return;
    }
    if (get(isSending) === false && props.disabled === false) {
        const imageToUpload: ImageUpload | undefined = get(image);
        if (imageToUpload !== undefined) {
            set(messageType, 'image');
        }
        let payload: SendMessageStorePayload = {
            sessionId: props.sessionId,
            request: {
                target: selectedValue.value as ConversationTarget,
                type: get(messageType),
                message: {
                    text: (keyboardMessage.value ?? "").trim() !== "" ? keyboardMessage.value : undefined,
                    imageContent: imageToUpload?.imageContent,
                    imageContentType: imageToUpload?.imageContentType,
                    imageSourceChoice: imageToUpload?.imageSourceChoice
                } as Message,
            }
        };
        if (Object.entries(payload.request.message).length === 0) {
            return;
        }
        try {
            set(isSending, true);
            await dispatch(SEND_MESSAGE, payload);
            set(image, undefined);
            set(messageType, 'text');
            emit("update-read", keyboardMessage.value);
            emit("scroll-to-the-end");
            keyboardMessage.value = undefined;
        } catch (err) {
            set(showError, true);
            setTimeout(() => set(showError, false), 2000);
        } finally {
            set(isSending, false);
        }
    }
};
</script>