<template>
  <div>
    <Input
      type="text"
      placeholder="Nome"
      v-model="name"
    />
    <ErrorForm :class="{ invisible: isNameValid }">
      Insira um nome correto
    </ErrorForm>

    <Input
      type="email"
      placeholder="E-mail"
      v-model="email"
    />
    <ErrorForm :class="{ invisible: isEmailValid }">
      O E-mail fornecido não parece estar correto.
    </ErrorForm>

    <PasswordInput
      v-model="password"
      placeholder="Nova Senha"
    />
    <ErrorForm :class="{ invisible: isPasswordValid }">
      A senha deve ter pelo menos 6 caracteres.
    </ErrorForm>

    <PasswordInput
      v-model="confirmPassword"
      placeholder="Repetir Senha"
    />
    <ErrorForm :class="{ invisible: isPasswordConfirmed }">
      As senhas inseridas não correspondem.
    </ErrorForm>

    <div class="flex flex-row">
      <Input
        readonly
        disabled
        class="w-[3rem] text-end p-0 border-box bg-gray-100"
        type="number"
        placeholder="+55"
        value="+55"
      />
      <Input
        type="tel"
        v-model="phoneNumber"
        placeholder="(00) 00000-0000"
        @update:modelValue="() => {
          const phoneNumberValue = getOnlyNumbers(phoneNumber);
          let newPhoneNumberValue = '(';

          for (let i = 0; i < phoneNumberValue?.length && i < 2; i++) {
            newPhoneNumberValue += phoneNumberValue[i];
            if (i === 1) newPhoneNumberValue += ') ';
          }

          for (let i = 2; i < phoneNumberValue?.length && i < 7; i++) {
            newPhoneNumberValue += phoneNumberValue[i];
            if (i === 6) newPhoneNumberValue += '-';
          }

          for (let i = 7; i < phoneNumberValue?.length; i++) {
            newPhoneNumberValue += phoneNumberValue[i];
          }

          if (newPhoneNumberValue == phoneNumberValue) return;
          phoneNumber = newPhoneNumberValue;
        }"
      />
    </div>
    <ErrorForm :class="{ invisible: isPhoneNumberValid }">
      O número de telefone fornecido não parece estar correto.
    </ErrorForm>

    <div class="flex items-center justify-center mt-1 w-full">
      <Button
        class="w-full text-white rounded-full"
        variant="default"
        type="submit"
        size="sm"
        @click="onSubmit"
      >
        Cadastrar
      </Button>
    </div>
    <p class="mt-4 text-xs text-gray-500">
      Já tem uma conta? <a
        class="text-blue-500 cursor-pointer"
        @click="$emit('TOGGLE_REGISTRATION')"
      >Logar</a>
    </p>
  </div>
  <Toaster />
</template>

<script setup lang="ts">
import { computed, defineEmits, ref } from 'vue';
import Button from '@/components/ui/button/Button.vue';
import Input from '@/components/ui/input/Input.vue';
import ErrorForm from '@/components/auth/ErrorForm.vue';
import { submitLeadCreationRequest } from '@/http/registration';
import { useStore } from 'vuex';
import { useToast } from '@/components/ui/toast';
import Toaster from '@/components/ui/toast/Toaster.vue';
import { AUTHENTICATE } from '@/store';
import { AuthenticationError } from '@/http/api';
import PasswordInput from './PasswordInput.vue';
import { validateEmail, validatePassword } from '@/lib/formValidation';

const store = useStore();
const { toast } = useToast();

const name = ref<string | undefined>(undefined);
const validateName = (value?: string): boolean => /^[A-Za-zÀ-ÖØ-öø-ÿ\s][A-Za-zÀ-ÖØ-öø-ÿ\s]+$/u.test(value ?? '');
const isNameValid = computed(() => validateName(name.value) || name.value === undefined);

const email = ref<string | undefined>(undefined);
const isEmailValid = computed(() => validateEmail(email.value) || email.value === undefined);

const password = ref<string | undefined>(undefined);
const isPasswordValid = computed(() => validatePassword(password.value) || password.value === undefined);

const confirmPassword = ref<string | undefined>(undefined);
const validateConfirmPassword = (value?: string): boolean => password.value === value;
const isPasswordConfirmed = computed(() => validateConfirmPassword(confirmPassword.value) || confirmPassword.value === undefined);

const phoneNumber = ref<string | undefined>(undefined);
const validatePhoneNumber = (value?: string): boolean => {
  return (value?.replace(/[^0-9]/g, '')?.length ?? 0) >= 10;
};
const isPhoneNumberValid = computed(() => validatePhoneNumber(phoneNumber.value) || phoneNumber.value === undefined);
const getOnlyNumbers = (value?: string) => value?.replace(/[^0-9]/g, '') ?? '';

const emit = defineEmits(['nextStep', 'TOGGLE_REGISTRATION']);

const onSubmit = async () => {
  name.value = name.value ?? '';
  email.value = email.value ?? '';
  password.value = password.value ?? '';
  confirmPassword.value = confirmPassword.value ?? '';
  phoneNumber.value = (phoneNumber.value ?? '');

  if (!(validateName(name.value) && validateEmail(email.value) && validatePassword(password.value) && validateConfirmPassword(confirmPassword.value) && validatePhoneNumber(phoneNumber.value))) {
    return;
  }

  try {
    await submitLeadCreationRequest({
      name: name.value,
      email: email.value,
      password: password.value,
      phoneNumber: '+55' + getOnlyNumbers(phoneNumber.value),
    });
  } catch (err) {
    toast({
      variant: 'destructive',
      title: 'Não foi possível criar sua conta',
      description: 'Por favor, verifique os dados e tente novamente.',
    });
    return;
  }

  try {
    await store.dispatch(AUTHENTICATE, { email: email.value, password: password.value });
  } catch (err) {
    if (err instanceof AuthenticationError) {
      toast({
        variant: 'destructive',
        title: 'Não foi possível acessar sua conta',
        description: 'Por favor, verifique os dados e tente novamente.',
      });
      return;
    }
    toast({
      variant: 'destructive',
      title: 'Aconteceu algum erro',
      description: 'Tente novamente mais tarde, mas fique tranquilo! Sua conta foi criada!',
    });
    return;
  }

  emit('nextStep');
};
</script>
