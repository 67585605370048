<template>
    <div
        class="flex-1 bg-gray-200 max-md:space-y-4 max-md:py-2 md:flex md:flex-row md:items-center md:justify-around md:gap-4 md:px-4 md:flex-1">
        <Card class="md:flex-1 md:grow md:h-80 md:max-w-2/3 md:m-0 max-md:w-2/3 max-md:m-auto">
            <CardTitle class="flex flex-row gap-2 items-center self-center max-w-1/2 p-5">
                <img
                    src="@/components/../assets/converza_icon_only.svg"
                    class="h-10"
                    alt="Converza Icon"
                />
                <span class="font-semibold text-lg mb-2">
                    Fluxos
                </span>
            </CardTitle>
            <CardContent class="flex-1 flex-grow-[2] flex flex-col gap-6 max-w-2/3 h-3/4 m-auto">
                <section class="max-w-1/2 text-left font-semibold text-xs space-y-4 m-auto">
                    <p>
                        Automatize a resposta de seus clientes antes mesmo de atender.
                    </p>
                    <p>
                        Crie fluxos de conversas e deixe que o Converza atenda seus clientes.
                    </p>
                    <p>
                        Automatize respostas e coleta de dados ao definir fluxos.
                    </p>
                </section>
                <WorkflowCreationDialog
                    v-model:isModalCreationOpen="isModalCreationOpen"
                    v-model:isWorkflowDataValid="isWorkflowDataValid"
                    v-model:validationErrorMessage="validationErrorMessage"
                    @try-to-save-workflow="tryToSaveWorkflow"
                >
                    <Button
                        disabled
                        class="rounded-full max-w-1/2 m-auto"
                        variant="default"
                        @click="() => isModalCreationOpen = true"
                    >
                        Comece a automatizar
                    </Button>
                </WorkflowCreationDialog>
            </CardContent>
        </Card>
        <Card class="md:flex-1 md:grow md:h-80 md:m-0 md:max-w-1/3 max-md:m-auto max-md:w-2/3">
            <CardHeader>
                <CardTitle>Automações</CardTitle>
            </CardHeader>
            <CardContent class="overflow-y-auto h-56 space-y-4">
                <Card
                    v-for="workflow in workflows"
                    :key="workflow.id"
                >
                    <CardContent class="group py-2 pl-4 pr-2 hover:bg-blue-200 overflow-y-auto">
                        <div
                            @click="async () => await router.push('/dashboard/automacoes/' + workflow.workflowCode.replace('#', '%23'))"
                            class="flex flex-row flex-nowrap items-center justify-between gap-3"
                        >
                            <div
                                class="h-12 w-12 bg-blue-200 group-hover:bg-white text-primary rounded-full flex justify-center items-center">
                                <Bot />
                            </div>
                            <div class="flex-1 flex flex-col items-start justify-evenly gap-3 py-2">
                                <CardHeader class="p-0">
                                    {{ workflow.presentationName ?? workflow.workflowCode }}
                                </CardHeader>
                                <p
                                    v-if="workflow.notes != undefined"
                                    class="text-left text-xs p-0"
                                >
                                    {{ workflow.notes }}
                                </p>
                            </div>
                        </div>
                    </CardContent>
                </Card>
            </CardContent>
        </Card>
    </div>
</template>
<script setup lang="ts">
import Button from '@/components/ui/button/Button.vue';
import Card from '@/components/ui/card/Card.vue';
import CardHeader from '@/components/ui/card/CardHeader.vue';
import CardTitle from '@/components/ui/card/CardTitle.vue';
import CardContent from '@/components/ui/card/CardContent.vue';
import { createNewWorkflow, getWorkflows, Workflow, WorkflowCreationRequest } from '@/http/workflow';
import { onMounted, ref } from 'vue';
import { useStore } from 'vuex';
import { GET_CREDENTIALS, GET_PARTNER_ID, PARTNER_ID } from '@/store';
import { get, set } from '@vueuse/core';
import { useRouter } from 'vue-router';
import { Bot } from 'lucide-vue-next';
import WorkflowCreationDialog from '@/components/automations-screen/WorkflowCreationDialog.vue';
import { predefinedWorkflows } from '@/lib/workflow';

const { getters } = useStore();
const router = useRouter();
const workflows = ref<Workflow[]>([]);
const didQuery = ref(false);


const isModalCreationOpen = ref(false);
const isWorkflowDataValid = ref(false);
const validationErrorMessage = ref('');

const validateWorkflowData = (workflowData: WorkflowCreationRequest) => {
    if (workflowData.presentationName === '') {
        validationErrorMessage.value = 'O nome não pode ser vazio!';
        return false;
    }

    if (workflows.value === undefined) return;

    const workflowExists = workflows.value.filter(workflow => workflow.presentationName === workflowData.presentationName).length > 0;
    if (workflowExists) {
        validationErrorMessage.value = 'Este nome já existe!';
        return false;
    }

    return true;
};

const tryToSaveWorkflow = async (workflowData: WorkflowCreationRequest) => {
    if (!validateWorkflowData(workflowData)) {
        isWorkflowDataValid.value = false;
        return;
    }

    isWorkflowDataValid.value = true;

    const partnerId = getters[GET_PARTNER_ID];
    const credentials = getters[GET_CREDENTIALS];

    workflowData.partnerId = partnerId;

    const createdWorkflow = await createNewWorkflow(workflowData, credentials);

    isModalCreationOpen.value = false;

    await router.push({ path: '/dashboard/automacoes/' + createdWorkflow.workflowCode.replace('#', '%23') });
};

onMounted(async () => {
    set(workflows, await getWorkflows(getters[PARTNER_ID], getters[GET_CREDENTIALS]));
    set(workflows, get(workflows)?.filter(w => predefinedWorkflows.includes(w.workflowCode)));
    setTimeout(() => {
        set(didQuery, true);
    }, 500);
});
</script>
