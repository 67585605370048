import { BASE_URL, throwCorrespondentApiErrorIfNecessary } from "@/http/api";

export interface BasicCredentials {
  email: string;
  password: string;
}

export type JwtToken = string;

export type APICredentials = JwtToken | BasicCredentials;

export const getCredentials = (credentials: APICredentials) => {
  if (typeof (credentials) === "string") {
    return `Bearer ${credentials}`;
  }
  credentials = credentials as BasicCredentials;
  return `Basic ${btoa(`${credentials.email}:${credentials.password}`)}`;
};

export const loginWithCredentials = async (credentials: APICredentials) => {
  const response = await fetch(`${BASE_URL}/auth-users/authentication`, {
    headers: {
      'Authorization': getCredentials(credentials),
    },
  });
  await throwCorrespondentApiErrorIfNecessary(response);
};

interface APILoginResponse {
  token: JwtToken;
  authUserId: string;
  partnerId: string;
}
export const loginForJwtWithBasicCredentials = async (credentials: BasicCredentials): Promise<APILoginResponse> => {
  const response = await fetch(`${BASE_URL}/auth-users/login`, {
    method: "post",
    headers: {
      'Authorization': getCredentials(credentials),
    },
  });
  await throwCorrespondentApiErrorIfNecessary(response);
  return (await response.json());
};