<template>
    <div class="grid grid-cols-12 gap-4 py-2 px-4 pt-1 w-full max-w-full flex-grow-0 ">
        <div class="col-span-2 flex flex-col max-w-1/6 h-full items-start justify-end gap-1.5 ">
            <Label :class="{ 'text-red-500 border-red-500': showPartnerProviderTypeInvalidMessage }">Provedor</Label>
            <Select
                v-model="partnerProviderType"
                class="max-w-full rounded-lg"
            >
                <SelectTrigger
                    class="w-full flex-grow-0 shrink-0 text-gray-500"
                    :class="{ 'border-red-500 text-red-500': showPartnerProviderTypeInvalidMessage }"
                >
                    <SelectValue placeholder="Selecione" />
                </SelectTrigger>
                <SelectContent>
                    <SelectGroup>
                        <SelectItem :value="'WHATSAPP'">
                            <div class="flex gap-2 flex-row justify-center items-center text-gray-700">
                                <Avatar
                                    size="icon"
                                    shape="frame"
                                >
                                    <ProviderIcon :provider="'WHATSAPP'" />
                                </Avatar>
                                <span>WhatsApp</span>
                            </div>
                        </SelectItem>
                        <SelectItem :value="'INSTAGRAM'">
                            <div class="flex gap-2 flex-row justify-center items-center text-gray-700">
                                <Avatar
                                    size="icon"
                                    class="w-6 h-6 bg-white rounded-lg"
                                >
                                    <ProviderIcon :provider="'INSTAGRAM'" />
                                </Avatar>
                                Instagram
                            </div>
                        </SelectItem>
                    </SelectGroup>
                </SelectContent>
            </Select>
        </div>
        <div class="col-span-2 flex flex-col max-w-1/6 h-full items-start justify-end gap-1.5">
            <Label :class="{ 'text-red-500 border-red-500': showPresentationNameInProviderInvalidMessage }">
                Nome de Apresentação</Label>
            <Input
                :class="{ 'ring-red-500  border-red-500': showPresentationNameInProviderInvalidMessage }"
                v-model="presentationNameInProvider"
            />
        </div>
        <div class="col-span-2 flex flex-col max-w-1/6 h-full items-start justify-end gap-1.5">
            <Label :class="{ 'text-red-500 border-red-500': showIdInProviderInvalidMessage }">
                Identificação do Provedor</Label>
            <Input
                :class="{ 'ring-red-500 border-red-500': showIdInProviderInvalidMessage }"
                v-model="idInProvider"
            />
        </div>
        <div class="col-span-2 flex flex-col max-w-1/6 h-full items-start justify-end gap-1.5">
            <Label :class="{ 'text-red-500 border-red-500': showTokenInProviderInvalidMessage }">
                Token</Label>
            <Input
                :class="{ 'ring-red-500 border-red-500': showTokenInProviderInvalidMessage }"
                v-model="tokenInProvider"
            />
        </div>
        <div class="col-span-3 flex flex-col max-w-1/6 h-full items-start justify-end gap-1.5">
            <Label :class="{ 'text-red-500': showWebhookVerificationInvalidMessage }">
                Webhook Verification Token</Label>
            <Input
                :class="{ 'ring-red-500 border-red-500': showWebhookVerificationInvalidMessage }"
                v-model="webhookVerificationToken"
            />
        </div>
        <div class="col-span-1 flex flex-shrink flex-row max-w-1/6 h-full items-end justify-end align-center gap-1.5">
            <Button
                variant="ghost"
                size="icon"
                @click="handleCancel"
            >
                <CircleX class="text-slate-400" />
            </Button>
            <Button
                variant="default"
                size="icon"
                @click="postPartnerProvider"
            >
                <CircleCheck class="text-white" />
            </Button>
        </div>
    </div>
</template>

<script lang="ts" setup>
import { ref, computed, defineEmits, defineProps, watch } from 'vue';
import { useStore } from 'vuex';
import Label from '@/components/ui/label/Label.vue';
import ProviderIcon from '@/components/ProviderIcon.vue';
import { PostPartnerProviderPayload } from '@/http/partnerProvider';
import Input from '@/components/ui/input/Input.vue';
import Avatar from '@/components/ui/avatar/Avatar.vue';
import { POST_PARTNER_PROVIDERS, PARTNER_ID } from '@/store';
import {
    Select,
    SelectContent,
    SelectGroup,
    SelectItem,
    SelectTrigger,
    SelectValue,
} from '@/components/ui/select';
import Button from '@/components/ui/button/Button.vue';
import { CircleCheck, CircleX } from 'lucide-vue-next';

const props = defineProps({
    displayToast: {
        type: Function,
        required: true
    }
});

const store = useStore();

const idInProvider = ref<string | undefined>(undefined);
const partnerProviderType = ref<"WHATSAPP" | "INSTAGRAM" | undefined>(undefined);
const presentationNameInProvider = ref<string | undefined>(undefined);
const tokenInProvider = ref<string | undefined>(undefined);
const webhookVerificationToken = ref<string | undefined>(undefined);

const validateNotEmptyString = (value?: string): boolean => {
    return value !== undefined && value.trim() !== '';
};

const validatePartnerProviderType = (value?: string): boolean => {
    return value === 'WHATSAPP' || value === 'INSTAGRAM';
};

const showPartnerProviderTypeInvalidMessage = ref<boolean | undefined>(undefined);
watch(partnerProviderType, (newValue) => {
    showPartnerProviderTypeInvalidMessage.value = !validatePartnerProviderType(newValue);
});

const showIdInProviderInvalidMessage = ref<boolean | undefined>(undefined);
watch(idInProvider, (newValue) => {
    showIdInProviderInvalidMessage.value = !validateNotEmptyString(newValue);
});
const showWebhookVerificationInvalidMessage = ref<boolean | undefined>(undefined);
watch(webhookVerificationToken, (newValue) => {
    showWebhookVerificationInvalidMessage.value = !validateNotEmptyString(newValue);
});

const showTokenInProviderInvalidMessage = ref<boolean | undefined>(undefined);
watch(tokenInProvider, (newValue) => {
    showTokenInProviderInvalidMessage.value = !validateNotEmptyString(newValue);
});

const showPresentationNameInProviderInvalidMessage = ref<boolean | undefined>(undefined);
watch(presentationNameInProvider, (newValue) => {
    showPresentationNameInProviderInvalidMessage.value = !validateNotEmptyString(newValue);
});

const isFormValid = computed(() =>
    validateNotEmptyString(idInProvider.value) &&
    validatePartnerProviderType(partnerProviderType.value) &&
    validateNotEmptyString(presentationNameInProvider.value) &&
    validateNotEmptyString(tokenInProvider.value) &&
    validateNotEmptyString(webhookVerificationToken.value)
);
const getInvalidParametersMessages = () => {
    let errors = `${showPartnerProviderTypeInvalidMessage.value ? "Tipo do Provedor, \n" : ""}` +
        `${showPresentationNameInProviderInvalidMessage.value ? "Nome de Apresentação, \n" : ""}` +
        `${showIdInProviderInvalidMessage.value ? "Identificação no Provedor, \n" : ""}` +
        `${showTokenInProviderInvalidMessage.value ? "Token no Provedor, \n" : ""}` +
        `${showWebhookVerificationInvalidMessage.value ? "Webhook Verification Token, \n" : ""}`;
    let messages = 'Você também precisa preencher os seguintes campos: ' + errors.slice(0, errors.length - 3) + '.';

    return messages;
};

const emit = defineEmits(['cancel', 'creation-finished']);
const postPartnerProvider = async () => {
    const localPartnerId = localStorage.getItem(PARTNER_ID);

    if (localPartnerId === null) return;

    if (!isFormValid.value) {
        showIdInProviderInvalidMessage.value = !(validateNotEmptyString(idInProvider.value));
        showTokenInProviderInvalidMessage.value = !(validateNotEmptyString(tokenInProvider.value));
        showPartnerProviderTypeInvalidMessage.value = !(validateNotEmptyString(partnerProviderType.value));
        showWebhookVerificationInvalidMessage.value = !(validateNotEmptyString(webhookVerificationToken.value));
        showPresentationNameInProviderInvalidMessage.value = !(validateNotEmptyString(presentationNameInProvider.value));

        const invalidFieldsMessages = getInvalidParametersMessages();

        props.displayToast('Há dados inválidos!', 'destructive', invalidFieldsMessages);
        return;
    }
    const payload: PostPartnerProviderPayload = {
        partnerId: localPartnerId,
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        idInProvider: idInProvider.value!,
        partnerProviderType: partnerProviderType.value as "WHATSAPP" | "INSTAGRAM",
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        presentationNameInProvider: presentationNameInProvider.value!,
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        tokenInProvider: tokenInProvider.value!,
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        webhookVerificationToken: webhookVerificationToken.value!,
    };
    await store.dispatch(POST_PARTNER_PROVIDERS, payload);

    props.displayToast('As informações do provedor foram adicionadas com sucesso!', 'default');
    emit('creation-finished');
};

const handleCancel = () => {
    emit('cancel');
};
</script>
