<template>
  <div
    class="flex-none w-60 rounded-2xl bg-white shadow-lg shadow-gray-500 pt-4 pb-1 select-none"
    :style="{
      'height': `${height}rem`
    }"
  >
    <div class="flex flex-row justify-between items-center px-5 pb-3 gap-2 h-9">
      <WorkflowTaskIcon
        :workflowTaskType="props.data.type"
        :workflowTaskSubtype="props.data.interactiveSubtype"
      />
      <p class="capitalize">
        {{ props.data.interactiveSubtype?.toLocaleLowerCase() }}
      </p>
      <WorkflowTaskSettings v-model:workflowTask="workflowTask">
        <Button
          variant="ghost"
          class="p-2"
        >
          <Settings :size="16" />
        </Button>
      </WorkflowTaskSettings>

    </div>
    <div class="pr-5 pl-5">
      <Textarea
        rows="2"
        v-model="workflowTask.content"
        placeholder="Título"
        class="h-10 min-h-10 rounded-xl resize-none font-semibold"
        :readonly="true"
      >
    </Textarea>
    </div>
  </div>
  <Handle
    :id="`${props.data.id}_T`"
    type="target"
    :position="Position.Top"
  />
  <Handle
    :id="`${props.data.id}_L`"
    type="source"
    :position="Position.Left"
  />
</template>

<script setup lang="ts">
import { defineProps, ref } from 'vue';
import { WorkflowTask } from '@/http/workflowTask';
import { NodeProps, Position } from '@vue-flow/core';
import { Handle } from '@vue-flow/core';
import WorkflowTaskIcon from './WorkflowTaskIcon.vue';
import Textarea from '../ui/textarea/Textarea.vue';
import WorkflowTaskSettings from './WorkflowTaskSettings.vue';
import Button from '../ui/button/Button.vue';
import { Settings } from 'lucide-vue-next';

const props = defineProps<NodeProps<WorkflowTask>>();
const workflowTask = ref<WorkflowTask>(props.data);
const height = (3 * Object.keys(props.data.availableReplies).length) + 7;
</script>