<template>
  <div class="flex md:flex-row max-md:flex-col h-screen overflow-hidden max-sm:overflow-y-auto">
    <div class="md:hidden w-full h-full"></div>
    <div class="flex justify-center items-center w-full h-1/3 md:hidden">
      <img
        src="../assets/logo-converza.png"
        alt="Logo"
        class="p-8 aspect-auto w-2/3"
      />
    </div>

    <div class="w-full h-full max-md:hidden">
      <img
        src="../assets/bg_login_reduce.jpg"
        class="block w-full h-full object-cover bg-gradient-to-r from-[#e0e3f5] to-[#e5e9f9]"
      />
    </div>

    <div class="w-full h-full">
      <LoginComponent
        @TOGGLE_REGISTRATION="toggleRegistrationEvent"
        @GO_TO_PARTNER_FORM="goToPartnerRegistration"
        v-if="isLogin"
      />
      <RegisterComponent
        @TOGGLE_REGISTRATION="toggleRegistrationEvent"
        v-model="step"
        v-else
      />
    </div>
    <div class="w-full h-full md:hidden"></div>
  </div>
</template>

<script setup lang="ts">
import LoginComponent from '@/components/auth/LoginComponent.vue';
import RegisterComponent from '@/components/auth/RegisterComponent.vue';
import { CLEAR_AUTH, GET_CREDENTIALS, GET_WHO_AM_I, PARTNER_ID } from '@/store';
import { set } from '@vueuse/core';
import { onMounted, ref } from 'vue';
import { useStore } from 'vuex';

const isLogin = ref(true);
const step = ref<number>(1);
const { commit, getters } = useStore();

const toggleRegistrationEvent = (event: Event | undefined) => {
  event?.preventDefault();

  isLogin.value = !isLogin.value;

  commit(CLEAR_AUTH);
};

const goToPartnerRegistration = (event?: Event) => {
  event?.preventDefault();
  set(isLogin, false);
  set(step, 2);
};

onMounted(() => {
  if (getters[GET_CREDENTIALS] != undefined && getters[GET_WHO_AM_I] != undefined && getters[PARTNER_ID] == undefined) {
    goToPartnerRegistration();
  }
});
</script>
