<template>
  <div class="flex flex-col justify-start items-stretch flex-nowrap h-screen">
    <HeaderComponent
      class="flex-1 flex-grow-0"
      title="Dashboard"
    />
    <div
      class="flex-1 flex md:justify-around md:flex-row max-md:flex-col px-8 gap-8 md:flex-nowrap md:items-stretch bg-gray-100 overflow-y-auto py-10"
    >
      <main class="md:flex-1 md:flex-grow-[2] flex flex-col items-stretch gap-3">
        <img
          src="@/assets/bg_login_reduce.jpg"
          class="w-full h-full object-cover rounded-xl shadow-sm max-h-40"
        >
        <div class="space-y-1">
          <h2 class="text-left font-bold text-black">Atividades</h2>
          <div
            class="flex flex-row flex-wrap justify-start gap-3"
            v-if="didFindPartnerProviders"
          >
            <PartnerProviderStats
              v-for="partnerProvider in partnerProviders"
              :partner-provider="partnerProvider"
              :key="partnerProvider.id"
            />
          </div>
          <div
            v-else
            class="flex flex-row flex-wrap justify-start gap-16 items-center"
          >
            <Card class="cursor-pointer p-6 min-h-40 w-48 space-y-4 flex flex-col flex-nowrap items-stretch border-0">
              <div class="flex-1 flex flex-row items-center gap-4 max-w-full">
                <Skeleton class="w-8 h-8" />
                <Skeleton class="w-full h-8" />
              </div>
              <div class="flex-1 block space-y-1">
                <StatQuery
                  :stat="'Em atendimento'"
                  :value="0"
                />
                <StatQuery
                  :stat="'Expiradas'"
                  :value="0"
                />
                <StatQuery
                  :stat="'Finalizadas'"
                  :value="0"
                />
                <StatQuery
                  :stat="'Total'"
                  :value="0"
                />
              </div>
            </Card>
            <LoaderCircle class="w-8 h-8 animate-spin text-muted" />
          </div>
        </div>
      </main>
      <Card class="md:flex-1 md:flex-grow p-4 flex flex-col">
        <div class="overflow-hidden h-fit">
          <SectionComponent title="Novidades">
            <template v-slot:icon>
              <Mailbox />
            </template>
            <div class="h-[22rem] overflow-y-auto space-y-4">
              <Card
                class="overflow-y-auto"
                v-for="cardContent in [
                  {
                    title: 'Confira seus fluxos',
                    description: 'Agora é possível visualizar seus fluxos de mensagens. Seus leads vão ser atendidos automaticamente e solicitar o atendimento.',
                    action: async () => await router.push({ path: '/dashboard/automacoes' })
                  },
                  {
                    title: 'Atenda seus clientes',
                    description: 'Você consegue ver todos os clientes esperando por atendimento e atendê-los.',
                    action: async () => await router.push({ path: '/dashboard/atendimento' })
                  },
                  {
                    title: 'Cadastre seu WhatsApp',
                    description: 'Você pode informar credenciais de Token do Meta para a gente poder mandar mensagens para seus leads. É automático, você pode atender e fica na sua conta Meta!',
                    action: async () => await router.push({ path: '/dashboard/configuracoes' })
                  },
                ]"
                :key="cardContent.title"
              >
                <CardContent class="group py-2 pl-4 pr-2 hover:bg-blue-200">
                  <div
                    @click="cardContent.action"
                    class="flex flex-row flex-nowrap items-center justify-between gap-3"
                  >
                    <div
                      class="h-12 w-12 bg-blue-200 group-hover:bg-white text-primary rounded-full flex justify-center items-center"
                    >
                      <BookOpen />
                    </div>
                    <div class="flex-1 flex flex-col items-start justify-evenly gap-3 py-2">
                      <CardHeader class="p-0">{{ cardContent.title }}</CardHeader>
                      <p class="text-left text-xs p-0">{{ cardContent.description }}</p>
                    </div>
                  </div>
                </CardContent>
              </Card>
            </div>
          </SectionComponent>
          <SectionComponent
            v-if="false"
            title="Tutoriais"
          >
            <template v-slot:icon>
              <GraduationCap />
            </template>
            <p>mem</p>
          </SectionComponent>
        </div>
      </Card>
    </div>
  </div>
</template>

<script setup lang="ts">
import PartnerProviderStats from '@/components/dashboard/PartnerProviderStats.vue';
import StatQuery from '@/components/dashboard/StatQuery.vue';
import HeaderComponent from '@/components/HeaderComponent.vue';
import SectionComponent from '@/components/SectionComponent.vue';
import { CardHeader } from '@/components/ui/card';
import Card from '@/components/ui/card/Card.vue';
import CardContent from '@/components/ui/card/CardContent.vue';
import Skeleton from '@/components/ui/skeleton/Skeleton.vue';
import { PartnerProvider } from '@/http/partnerProvider';
import { GET_PARTNER_PROVIDERS, RETRIEVE_PARTNER_PROVIDERS } from '@/store';
import { set } from '@vueuse/core';
import { BookOpen, GraduationCap, LoaderCircle, Mailbox } from 'lucide-vue-next';
import { computed, onMounted, ref } from 'vue';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';

const { getters, dispatch } = useStore();

const partnerProviders = computed<PartnerProvider[]>(() => Object.values(getters[GET_PARTNER_PROVIDERS]));

const router = useRouter();

const didFindPartnerProviders = ref(false);
onMounted(async () => {
  set(didFindPartnerProviders, false);
  try {
    await dispatch(RETRIEVE_PARTNER_PROVIDERS);
  } catch (error) {
    set(didFindPartnerProviders, false);
  }
  setTimeout(() => set(didFindPartnerProviders, true), 1000);
});
</script>
