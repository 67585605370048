<template>
  <div class="flex flex-col items-stretch justify-start h-screen w-full min-w-[760px]">
    <HeaderComponent
      title="Automações"
      class="flex-1 flex-grow-0"
    >
      <InfoDropdown
        v-model:selectedWorkflow="selectedWorkflow"
        @refresh-workflow-on-list="refreshWorkflowOnList"
      />
      <div class="col-span-1 flex flex-row max-w-1/6 h-full items-end justify-end align-center gap-1.5">
        <Dialog
          v-model:open="openReset"
          v-if="selectedWorkflow == undefined"
        >
          <DialogTrigger as-child>
            <Button
              variant="ghost"
              size="icon"
            >
              <RefreshCw class="text-slate-400" />
            </Button>
          </DialogTrigger>

          <DialogContent class="pt-4">
            <DialogHeader class="flex flex-row justify-between items-center">
              <DialogTitle>Resetar todos os fluxos e passos?</DialogTitle>
              <DialogClose>
                <Button
                  variant="ghost"
                  class="px-1.5"
                  @click="() => openReset = false"
                >
                  <CircleX class="text-gray-600" />
                </Button>
              </DialogClose>
            </DialogHeader>
            <div>
              <p class="mt-2">Tem certeza de que deseja resetar todos os seus fluxos e passos?</p>
              <p class="mt-2">Todos seus fluxos voltarão ao padrão da aplicação e qualquer mudança será
                <strong>perdida</strong>.
              </p>
            </div>
            <DialogFooter>
              <Button
                type="submit"
                @click="async () => {
                  try {
                    await resetWorkflows(store.getters[PARTNER_ID], store.getters[GET_CREDENTIALS]);
                    await router.push({ path: '/dashboard/automacoes' });
                  } catch (err) {
                    toast({
                      variant: 'destructive',
                      title: 'Erro ao redefinir seus fluxos e passos',
                      description: 'Não foi possível concluir a redefinição de fluxos e passos. Verifique a conexão, atualize a página e tente novamente.',
                      duration: 2000
                    });
                  } finally {
                    openReset = false;
                  }
                  toast({
                    variant: 'default',
                    title: 'Redefinição de passos e fluxos concluída',
                    description: 'Agora seus fluxos e passos voltaram ao estado inicial do Converza',
                    duration: 2000
                  });
                  selectedWorkflowCode = undefined
                  selectedWorkflow = undefined;
                }"
                variant="destructive"
              >
                Resetar
              </Button>
              <Button
                variant="ghost"
                @click="() => openReset = false"
                class="px-1.5"
              >Cancelar</Button>
            </DialogFooter>
          </DialogContent>
        </Dialog>
      </div>
      <Select v-model="selectedWorkflowCode">
        <SelectTrigger class="max-w-52 min-w-52 text-left">
          <SelectValue placeholder="Selecione um fluxo" />
        </SelectTrigger>
        <SelectContent class="max-w-52 z-auto">
          <SelectGroup >
            <SelectLabel class="text-gray-500">Fluxos</SelectLabel>
            <div
              v-for="workflow in workflows"
              :key="workflow.workflowCode"
            >
              <SelectItem
                v-if="workflow.workflowCode === '#DEFAULT'"
                :value="workflow.workflowCode"
                class="max-w-52"
              >
                <span class="line-clamp-1">
                  {{ workflow.presentationName ?? workflow.workflowCode }}
                </span>
              </SelectItem>
            </div>
            <WorkflowCreationDialog
              v-model:isModalCreationOpen="isModalCreationOpen"
              v-model:isWorkflowDataValid="isWorkflowDataValid"
              v-model:validationErrorMessage="validationErrorMessage"
              @try-to-save-workflow="tryToSaveWorkflow"
            >
              <Button
                disabled
                size="sm"
                class="flex items-center w-full mt-1 justify-center max-sm:text-sm font-semibold"
                @click="() => isModalCreationOpen = true"
              >
                Criar Fluxo
              </Button>
            </WorkflowCreationDialog>
          </SelectGroup>
          <SelectGroup >
            <SelectLabel class="text-gray-500">Passos</SelectLabel>
            <div
              v-for="workflow in workflows"
              :key="workflow.workflowCode"
            >
              <SelectItem
                v-if="predefinedWorkflows?.includes(workflow.workflowCode) && workflow.workflowCode !== '#DEFAULT'"
                :value="workflow.workflowCode"
                class="max-w-52"
              >
                <span class="line-clamp-1">
                  {{ workflow.presentationName ?? workflow.workflowCode }}
                </span>
              </SelectItem>
            </div>
          </SelectGroup>
        </SelectContent>
      </Select>
    </HeaderComponent>
    <div class="flex-1 flex max-h-full overflow-hidden">
      <router-view v-model:currentWorkflow="selectedWorkflow"></router-view>
    </div>
  </div>
  <Toaster />
</template>

<script setup lang="ts">
import HeaderComponent from '@/components/HeaderComponent.vue';
import { GET_CREDENTIALS, GET_PARTNER_ID, PARTNER_ID } from '@/store';
import { computed, onMounted, ref, watch } from 'vue';

import {
  Select, SelectContent, SelectGroup,
  SelectItem, SelectTrigger, SelectValue,
} from '@/components/ui/select';

import WorkflowCreationDialog from '@/components/automations-screen/WorkflowCreationDialog.vue';
import Button from '@/components/ui/button/Button.vue';
import { useRouter } from 'vue-router';
import InfoDropdown from '@/components/automations-screen/InfoDropdown.vue';
import { useStore } from 'vuex';
import { createNewWorkflow, getWorkflowById, getWorkflows, resetWorkflows, Workflow, WorkflowCreationRequest } from '@/http/workflow';
import Toaster from '@/components/ui/toast/Toaster.vue';
import { toast } from '@/components/ui/toast';
import { CircleX, RefreshCw } from 'lucide-vue-next';
import DialogTrigger from '@/components/ui/dialog/DialogTrigger.vue';
import DialogContent from '@/components/ui/dialog/DialogContent.vue';
import DialogHeader from '@/components/ui/dialog/DialogHeader.vue';
import DialogTitle from '@/components/ui/dialog/DialogTitle.vue';
import DialogClose from '@/components/ui/dialog/DialogClose.vue';
import DialogFooter from '@/components/ui/dialog/DialogFooter.vue';
import Dialog from '@/components/ui/dialog/Dialog.vue';
import { set } from '@vueuse/core';
import SelectLabel from '@/components/ui/select/SelectLabel.vue';
import { predefinedWorkflows } from '@/lib/workflow';

const store = useStore();
const router = useRouter();
const currentPath = computed(() => router.currentRoute.value.path);
const openReset = ref(false);

const workflows = ref<Workflow[] | undefined>(undefined);

onMounted(async () => {
  const partnerId = localStorage.getItem('PARTNER_ID');
  const credentials = localStorage.getItem('CREDENTIALS');

  if (partnerId === null) {
    throw new Error('PartnerId is null');
  }

  if (credentials === null) {
    throw new Error('Credentials is null');
  }

  workflows.value = await getWorkflows(partnerId, credentials);
  tryToRedirectToExistingWorkflow(currentPath.value);
});

const pushNewWorkflowIntoList = (workflow: Workflow) => {
  workflows.value?.push(workflow);
};

const refreshWorkflowOnList = async (workflowId: string) => {
  const credentials = store.getters[GET_CREDENTIALS];
  const workflowFromAPI = await getWorkflowById(workflowId, credentials);
  workflows.value = workflows.value?.map(workflow => workflow.id === workflowId
    ? workflowFromAPI
    : workflow);
  selectedWorkflow.value = workflowFromAPI;
};

router.afterEach((to) => {
  if (to.fullPath === '/dashboard/automacoes') {
    set(selectedWorkflow, undefined);
    set(selectedWorkflowCode, undefined);
  }
});

const selectedWorkflowCode = ref<string | undefined>(undefined);
watch(selectedWorkflowCode, () => {
  const found = workflows.value?.filter(workflow => workflow.workflowCode === selectedWorkflowCode.value);
  selectedWorkflow.value = found?.at(0) ?? undefined;
});

const selectedWorkflow = ref<Workflow | undefined>(undefined);
watch(selectedWorkflow, () => {
  redirectToWorkflowRoute(selectedWorkflow.value?.workflowCode);
});

watch(currentPath, (newPath) => {
  tryToRedirectToExistingWorkflow(newPath);
});

const tryToRedirectToExistingWorkflow = (newPath: string) => {
  if (workflows.value === undefined) return;

  const newPathParts = newPath.split("/");
  if (newPathParts.length !== 4) return;

  const workflowCode = newPathParts.at(-1)?.replace("%23", "#");
  const workflowCodeExists = workflows.value.find(
    workflow => workflow.workflowCode === workflowCode
  ) != undefined;

  if (!workflowCodeExists) {
    toast({
      title: "Este fluxo não existe!",
      variant: "destructive"
    });
    return;
  }
  selectedWorkflowCode.value = workflowCode;
};

const redirectToWorkflowRoute = async (workflowCode: string | undefined) => {
  if (workflowCode === undefined) return;
  const encodedWorkflowCode = encodeURIComponent(workflowCode);
  const uri = `/dashboard/automacoes/${encodedWorkflowCode}`;
  await router.push({ path: uri });
};

const isModalCreationOpen = ref(false);
const isWorkflowDataValid = ref(true);
const validationErrorMessage = ref("");

const tryToSaveWorkflow = async (workflowData: WorkflowCreationRequest, callback: CallableFunction) => {

  if (!validateWorkflowData(workflowData)) {
    isWorkflowDataValid.value = false;
    return;
  }

  isWorkflowDataValid.value = true;

  const partnerId = store.getters[GET_PARTNER_ID];
  const credentials = store.getters[GET_CREDENTIALS];

  workflowData.partnerId = partnerId;

  const createdWorkflow = await createNewWorkflow(workflowData, credentials);

  isModalCreationOpen.value = false;
  selectedWorkflowCode.value = createdWorkflow.workflowCode;

  callback();
  pushNewWorkflowIntoList(createdWorkflow);
  redirectToWorkflowRoute(createdWorkflow.workflowCode);
};

const validateWorkflowData = (workflowData: WorkflowCreationRequest) => {
  if (workflowData.presentationName === '') {
    validationErrorMessage.value = 'O nome não pode ser vazio!';
    return false;
  }

  if (workflows.value === undefined) return;

  const workflowExists = workflows.value.filter(workflow => workflow.presentationName === workflowData.presentationName).length > 0;
  if (workflowExists) {
    validationErrorMessage.value = 'Este nome já existe!';
    return false;
  }

  return true;
};
</script>