<template>
  <header class="h-[25vh] bg-blue-200 w-full p-6">
    <nav class="flex justify-between">
      <img
        @click="goToHome"
        class="h-10 cursor-pointer"
        src="@/assets/logo-converza.png"
        alt="Ir para página principal"
      />

      <div class="flex flex-1 flex-row-reverse justify-start content-center items-center">
        <router-link
          class="pr-4 ml-10 mr-0 text-lg font-medium hover:underline hover:text-blue-400"
          :to="'/login'"
        >Logar</router-link>

        <div
          v-for="(route, index) in routes"
          :key="index"
          class="pr-4 mx-5 first-of-type:mr-0"
        >
          <router-link
            :to="route.path"
            class="text-lg font-medium underline-offset-4"
            :class="{
              'underline text-lg hover:underline font-medium': isActive(route.path),
              'text-blue-950 hover:text-blue-400 hover:underline': !isActive(route.path)
            }"
          >
            {{ route.name }}
          </router-link>
        </div>
      </div>
    </nav>
  </header>
</template>

<script setup lang="ts">
import { ref } from 'vue';
import { useRoute, useRouter } from 'vue-router';

const routes = ref<{ path: string; name: string }[]>([]);

const router = useRouter();

const goToHome = async () => {
  await router.push('/');
};

const route = useRoute();
const isActive = (path: string) => {
  return route.fullPath === path;
};
</script>
