<template>
  <VueFlow
    :nodes="nodes"
    :edges="edges"
    class="bg-gray-100"
    fit-view-on-init
  >
    <template #node-interactive-list="interactiveListNodeProps">
      <ListReplyNode v-bind="interactiveListNodeProps" />
    </template>
    <template #node-list-reply-option="replyListOptionNodeProps">
      <ListReplyOptionNode v-bind="replyListOptionNodeProps" />
    </template>
    <template #node-interactive-button="interactiveButtonProps">
      <ButtonReplyNode v-bind="interactiveButtonProps" />
    </template>
    <template #node-text="textNodeProps">
      <TextNode v-bind="textNodeProps" />
    </template>
    <template #node-link-step="linkStepProps">
      <LinkStep v-bind="linkStepProps" />
    </template>
    <template #node-link-incoming="linkIncomingProps">
      <LinkIncoming v-bind="linkIncomingProps" />
    </template>
  </VueFlow>
</template>

<style scoped>
@import '@vue-flow/core/dist/style.css';
@import '@vue-flow/core/dist/theme-default.css';
</style>

<script setup lang="ts">
import { getWorkflow, Workflow } from '@/http/workflow';
import { getWorkflowTaskById, getWorkflowTasks, InteractiveSubtype, SelectionReply, WorkflowTask } from '@/http/workflowTask';
import { PARTNER_ID, STORAGE_CREDENTIALS } from '@/store';
import { Edge, MarkerType, Node, Position, VueFlow } from '@vue-flow/core';
import { defineModel, onBeforeMount, PropType, ref } from 'vue';
import { MessageType } from '@/http/customerSession';
import { watch } from 'vue';
import ButtonReplyNode from './ButtonReplyNode.vue';
import ListReplyNode from './ListReplyNode.vue';
import ListReplyOptionNode from './ListReplyOptionNode.vue';
import TextNode from './TextNode.vue';
import LinkStep from './LinkStep.vue';
import { predefinedWorkflows } from '@/lib/workflow';
import { get } from '@vueuse/core';
import LinkIncoming from './LinkIncoming.vue';
import { useStore } from 'vuex';

const { getters } = useStore();

const nodes = ref<Node[]>([]);
const edges = ref<Edge[]>([]);

const starterWorkflowTasks = ref<WorkflowTask[] | undefined>(undefined);
const currentWorkflow = defineModel('currentWorkflow', {
  type: Object as PropType<Workflow | undefined>,
  required: true,
  default: undefined
});

watch(currentWorkflow, async () => {
  if (currentWorkflow.value === undefined) return;
  await retrieveWorkflowTasks(currentWorkflow.value.workflowCode);
  nodes.value = [];
  edges.value = [];
  mountNodes();
});

onBeforeMount(async () => {
  if (currentWorkflow.value === undefined) return;
  await retrieveWorkflowTasks(currentWorkflow.value.workflowCode);
  mountNodes();
});

const retrieveWorkflowTasks = async (selectedWorkflowCode: string) => {
  const partnerId = getters[PARTNER_ID];
  const credentials = getters[STORAGE_CREDENTIALS];
  if (partnerId === null) return;
  if (credentials === null) return;
  starterWorkflowTasks.value = await getWorkflowTasks(partnerId, selectedWorkflowCode, credentials);
};

const makeEdge = (from: Node, fromHandle: string | undefined, to?: Node, toHandle?: string, animated?: boolean) => {
  if (to === undefined) return;
  edges.value.push({
    id: `${from.id}_${to.id}`,
    source: from.id,
    sourceHandle: fromHandle,
    targetHandle: toHandle,
    target: to.id,
    animated: animated,
    markerEnd: {
      type: MarkerType.Arrow,
      width: 25,
      height: 25,
    }, 
    zIndex: 1
  });
};

const getNodeType = (type: MessageType, subtype?: InteractiveSubtype) => {
  let nodeType: string = type;
  if (type === 'interactive' && subtype !== undefined) {
    nodeType += '-' + String(subtype).toLowerCase();
  }
  return nodeType;
};

const makeNode = (task: WorkflowTask, x: number, y: number): [Node, number] => {
  const newNode = {
    id: task.id,
    type: getNodeType(task.type, task.interactiveSubtype),
    position: { x, y },
    targetPosition: Position.Right,
    data: task
  };
  nodes.value.push(newNode);
  let offset = 50 +
    (task.title === undefined ? 0 : 50);
  return [newNode, offset];
};

const getLeftHandle = (node: Node) => `${node.id}_L`;
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const getRightHandle = (node: Node) => `${node.id}_R`;
const getBottomHandle = (node: Node) => `${node.id}_B`;
const getTopHandle = (node: Node) => `${node.id}_T`;

const TASK_SIZE = 250;
const TASK_OFFSET = 50;

const mountWorkflowTasksInPosition = async (x: number, y: number, head?: WorkflowTask, tail?: WorkflowTask[]): Promise<Node | undefined> => {
  if (head === undefined) {
    return;
  }
  let isPredefinedButDifferentThanCurrent = predefinedWorkflows?.includes(head?.workflowCode) && get(currentWorkflow)?.workflowCode !== head?.workflowCode;
  if (isPredefinedButDifferentThanCurrent) {
    let stepNode = {
      id: head.workflowCode + `ref_from_${head.id}`,
      type: 'link-step',
      position: { x, y: (y + 50) },
      targetPosition: Position.Right,
      data: {
        id: head.workflowCode + `ref_from_${head.id}`,
        content: head?.workflowCode !== undefined 
          ? (await getWorkflow(getters[PARTNER_ID], head.workflowCode, getters[STORAGE_CREDENTIALS])).presentationName 
          : 'Outro fluxo',
        workflowCode: head.workflowCode
      }
    };
    nodes.value.push(stepNode);
    return stepNode;
  }

  let [newNode, replyOptionListOffset] = makeNode(head, x, y);

  if (head.type === 'interactive') {
    if (head.interactiveSubtype === 'LIST' || head.interactiveSubtype === 'BUTTON') {
      let relatedTasksBegin = newNode.position.y - ((Object.keys(head.availableReplies).length * TASK_SIZE) / 2);
      let yReplyPosition = relatedTasksBegin;
      for (let reply of Object.keys(head.availableReplies)) {
        const listNode = mountNodeListReplyOption(head.availableReplies[reply], newNode, replyOptionListOffset);
        replyOptionListOffset += 45;

        const comparingList = Object.keys(head.availableReplies);
        const availableReplies = await getWorkflowTasks(head.partnerId, reply, getters[STORAGE_CREDENTIALS]);
        availableReplies.sort((replyA, replyB) => comparingList.indexOf(replyA.workflowCode) - comparingList.indexOf(replyB.workflowCode));

        let targetWorkflowNode: Node | undefined;
        let shouldRenderNextMacroWorkflowLinkStep = reply === '#DEFAULT';
        if (shouldRenderNextMacroWorkflowLinkStep) {
          let next = reply === '#DEFAULT' ? '#DEFAULT' : (head.nextTaskId ?? head.nextWorkflowCode);
          targetWorkflowNode = {
            id: next + `ref_from_${head.id}`,
            type: 'link-step',
            position: { x: (x+350), y: (yReplyPosition) },
            targetPosition: Position.Right,
            data: {
              id: next + `ref_from_${head.id}`,
              content: (await getWorkflow(getters[PARTNER_ID], reply, getters[STORAGE_CREDENTIALS])).presentationName,
              workflowCode: next
            }
          };
          nodes.value.push(targetWorkflowNode);
        }
        else {
          targetWorkflowNode = await mountWorkflowTasksInPosition(x + 350, yReplyPosition, availableReplies[0], availableReplies.slice(1));
        }

        if (targetWorkflowNode !== undefined) {
          makeEdge(listNode, undefined, targetWorkflowNode, getLeftHandle(targetWorkflowNode));
        }

        let height = TASK_SIZE;
        let newNodeHeightType = typeof targetWorkflowNode?.height;
        if (newNodeHeightType === 'number' || newNodeHeightType === 'string' || newNodeHeightType === 'undefined') {
          height = Number(newNode.height ?? height);
        } else {
          height = (newNode.height as any)();
        }
        yReplyPosition += (height) + TASK_OFFSET;
      }
    }
    return newNode;
  } 

  let nextWorkflowTask = tail?.at(0);
  let afterNode: Node | undefined;
  if (head.nextWorkflowCode || head.nextTaskId) {
    let presentationName = head.nextTaskId ?? head.nextWorkflowCode;
    if (head.nextTaskId) {
      const nextTaskIdWorkflowCode = (await getWorkflowTaskById(head.nextTaskId, getters[STORAGE_CREDENTIALS])).workflowCode;
      presentationName = (await getWorkflow(getters[PARTNER_ID], nextTaskIdWorkflowCode, getters[STORAGE_CREDENTIALS])).presentationName;
    } else if (head.nextWorkflowCode) {
      presentationName = (await getWorkflow(getters[PARTNER_ID], head.nextWorkflowCode, getters[STORAGE_CREDENTIALS])).presentationName;
    }
    let next = (head.nextTaskId ?? head.nextWorkflowCode);
    afterNode = {
      id: next + `ref_from_${head.id}`,
      type: 'link-step',
      position: { x: (x+340), y: (y + 49) },
      targetPosition: Position.Right,
      data: {
        id: next + `ref_from_${head.id}`,
        content: presentationName,
        workflowCode: next,
      }
    };
    nodes.value.push(afterNode);
  } else {
    afterNode = await mountWorkflowTasksInPosition(x, y + 200, nextWorkflowTask, tail?.slice(1));
  }
  if (afterNode !== undefined){
    if(afterNode.type === 'link-step'){
      makeEdge(newNode, getRightHandle(newNode), afterNode, getLeftHandle(afterNode));
    } else {
      makeEdge(newNode, getBottomHandle(newNode), afterNode, getTopHandle(afterNode));
    }
  }

  return newNode;
};

const mountWorkflowTasks = async (workflowTasks: WorkflowTask[]) => {
  if (workflowTasks.length == 0) return;
  let incomingWorkflows = ['#SUPPORT', '#BACK_TO_MENU', '#END_SESSION'];
  let incomingNode: Node | undefined = undefined;
  if (incomingWorkflows?.includes(workflowTasks.at(0)?.workflowCode ?? '')) {
    incomingNode = {
      id: 'incoming-node',
      type: 'link-incoming',
      data: {id: 'incoming-node'},
      position: { x: 16, y: -130},
      targetPosition: Position.Bottom
    };
    nodes.value.push(incomingNode);
  }
  
  let others = await mountWorkflowTasksInPosition(0, 0, workflowTasks.at(0), workflowTasks.slice(1,));
  if(others != undefined && incomingNode !== undefined){
    makeEdge(incomingNode, `${incomingNode.id}_B`, others, getTopHandle(others), true);
  }
};

const mountNodes = () => {
  if (starterWorkflowTasks.value === undefined) return;
  mountWorkflowTasks(starterWorkflowTasks.value);
};

const mountNodeListReplyOption = (reply: SelectionReply, parentNode: Node, offset: number): Node => {
  const newListNode: Node = {
    id: `node_reply_${parentNode.id}_${reply.id}`,
    type: 'list-reply-option',
    data: reply,
    position: { x: 20, y: offset },
    extent: 'parent',
    parentNode: parentNode.id,
    draggable: false
  };
  nodes.value.push(newListNode);
  return newListNode;
};

</script>
