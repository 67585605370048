<script setup lang="ts">
import type { HTMLAttributes } from 'vue'
import { cn } from '@/lib/utils'

const props = defineProps<{
  class?: HTMLAttributes['class']
}>()
</script>

<template>
  <div :class="cn('flex flex-col text-xs font-semibold gap-y-1.5 p-6 text-left', props.class)">
    <slot />
  </div>
</template>
