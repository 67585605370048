import { createApp } from 'vue';
import App from '@/App.vue';
import router from '@/router';
import store, { CLEAR_AUTH, HAS_ALL_AUTH } from '@/store';

import '@/assets/tailwind.css';
import '@fortawesome/fontawesome-free/css/all.min.css';

router.beforeEach((to, from, next) => {
  if (to.path.startsWith('/dashboard') && !store.getters[HAS_ALL_AUTH]) {
    store.commit(CLEAR_AUTH);
    return next({ path: '/login', });
  }

  if (to.path.startsWith('/login') && store.getters[HAS_ALL_AUTH]) {
    return next({ path: '/dashboard' });
  }

  return next();
});

createApp(App).use(store).use(router).mount('#app');
