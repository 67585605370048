<template>
  <Popover v-model:open="openDialog">
    <PopoverTrigger as-child>
      <slot></slot>
    </PopoverTrigger>
    <PopoverContent class="w-80 mr-2">
      <div class="flex flex-col gap-2">
        <div class="flex flex-row items-center justify-between gap-4">
          <Label for="url">
            <span class="flex flex-row items-center justify-evenly gap-1">
              <Link /><span>URL</span>
            </span>
          </Label>
          <Input
            v-model:model-value="url"
            id="url"
            name="url"
            type="text"
            placeholder="Endereço da imagem"
            class="col-span-2 h-8"
            :class="{
              'border-red-500': hasUrlError,
              'placeholder:text-red-500': hasUrlError
            }"
          />
          <Button
            type="button"
            variant="default"
            size="icon"
            class="shadow-sm"
            @click="() => {
              if (url === undefined || url?.trim() === '' || url?.includes('?')) {
                hasUrlError = true;
                return;
              }
              image = {
                imageContent: url,
                imageSourceChoice: 'URL'
              };
              openDialog = false;
              hasUrlError = false;
            }"
          >
            <Check />
          </Button>
        </div>
        <Separator
          class="my-4"
          label="Ou"
        />
        <div class="flex flex-row items-center justify-center">
          <input
            id="uploadFile"
            name="uploadFile"
            class="border-0"
            type="file"
            accept="image/*"
            @change="uploadFile"
          >
          <label
            for="uploadFile"
            class="btn-1 w-full py-1.5"
            :class="{
              'border-red-500 bg-red-500': hasUploadError
            }"
          >
            Carregar
          </label>
        </div>
        <Progress
          :class="{ 'invisible': progress === undefined }"
          v-model="progress"
          class="w-full"
        />
      </div>
    </PopoverContent>
  </Popover>
</template>

<script lang="ts" setup>
import Popover from '@/components/ui/popover/Popover.vue';
import PopoverTrigger from '@/components/ui/popover/PopoverTrigger.vue';
import PopoverContent from '@/components/ui/popover/PopoverContent.vue';
import Label from '@/components/ui/label/Label.vue';
import Input from '@/components/ui/input/Input.vue';
import { defineModel, onMounted, PropType, ref } from 'vue';
import { Check, Link } from 'lucide-vue-next';
import Separator from './ui/separator/Separator.vue';
import Button from './ui/button/Button.vue';
import { get, set } from '@vueuse/core';
import Progress from './ui/progress/Progress.vue';
import { ImageUpload } from '@/http/customerSession';
import { validateBase64 } from '@/lib/formValidation';

const url = ref<string | undefined>();
const hasUrlError = ref<boolean>(false);
const hasUploadError = ref<boolean>(false);
const openDialog = ref(false);
const progress = ref<number | undefined>(undefined);

const image = defineModel<ImageUpload | undefined>({
  type: Object as PropType<ImageUpload | undefined>,
  required: false,
  default: undefined
});

const uploadFile = (uploadEvent: Event) => {
  uploadEvent.preventDefault();
  const input = uploadEvent.target as HTMLInputElement;
  const file: File | undefined = input.files ? input.files[0] : undefined;

  if (file) {
    const reader = new FileReader();

    reader.onloadstart = () => {
      set(progress, 0);
    };

    reader.onprogress = (event) => {
      if (event.lengthComputable) {
        set(progress, Math.round(event.loaded / event.total) * 100);
      }
    };

    reader.onloadend = function (e: ProgressEvent<FileReader>) {
      const base64EncodedImage = (e.target?.result as string)?.split(',')?.at(-1);
      if (!validateBase64(base64EncodedImage)) {
        set(progress, undefined);
        hasUploadError.value = true;
        return;
      }
      set(image, {
        ...(get(image) ?? {}),
        imageContent: base64EncodedImage,
        imageSourceChoice: 'BASE64',
        imageContentType: file?.type as string
      });
      set(hasUploadError, false);
      set(progress, 100);
      setTimeout(() => {
        set(progress, undefined);
        set(openDialog, false);
      }, 500);
    };

    reader.readAsDataURL(file);
  } else {
    set(progress, undefined);
    hasUploadError.value = true;
  }
};

onMounted(() => {
  set(hasUrlError, false);
  set(hasUploadError, false);
  set(progress, undefined);
  set(image, undefined);
  set(url, undefined);
});
</script>

<style lang="scss">
[type="file"] {
  height: 0;
  overflow: hidden;
  width: 0;
}

[type="file"]+label {
  background: #2E54DC;
  border: none;
  border-radius: 5px;
  color: #fff;
  cursor: pointer;
  display: inline-block;
  font-size: inherit;
  font-weight: 500;
  outline: none;
  position: relative;
  transition: all 0.3s;
  vertical-align: middle;
  text-align: center;

  &:hover {
    background-color: darken(#2E54DC, 10%);
  }

  &.btn-1 {
    background-color: #2E54DC;
    box-shadow: 0 6px darken(#2E54DC, 10%);
    transition: none;

    &:hover {
      box-shadow: 0 4px darken(#2E54DC, 10%);
      top: 2px;
    }
  }
}
</style>