<template>
  <router-link :to="encodeURIComponent(props.data.workflowCode)">
    <div
      class="w-60 h-16 rounded-3xl flex justify-center items-center px-1"
      :class="blueOrOrange"
    >
      <p class="font-semibold text-white">
        {{ props.data.content }}
      </p>
    </div>
  </router-link>
  <Handle
    :id="`${props.data.id}_L`"
    type="target"
    :position="Position.Left"
  />
</template>

<script setup lang="ts">
import { Handle, NodeProps, Position } from '@vue-flow/core';
import { computed, defineProps } from 'vue';

interface LinkStepProps {
  id: string;
  content: string;
  workflowCode: string;
}

const blueOrOrange = computed(() => {
  return props.data.workflowCode === '#DEFAULT' ? 'bg-blue-400' : 'bg-orange-400';
});

const props = defineProps<NodeProps<LinkStepProps>>();
</script>